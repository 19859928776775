import { Button, Chip, Tooltip } from "@nextui-org/react";
import { Check, Contact, Info, Loader, Upload } from "lucide-react";
import React from "react";
import ToolTip from "../../../assets/Icons/ToolTip.svg";

const ServiceHistory = () => {
  const data = [
    // documents for service history
    {
      id: "service",
      status: "success",
      label: "Service History",
      icon: <Contact size={16} />,
      tooltipText: "Service History is required to verify your identity",
    },
    // {
    //   id: "service",
    //   status: "pending",
    //   label: "Service History",
    //   icon: <Contact size={16} />,
    //   tooltipText: "Service History is required to verify your identity",
    // },
    // {
    //   id: "service",
    //   status: "warning",
    //   label: "Service History",
    //   icon: <Contact size={16} />,
    // },
  ];
  return (
    <div className="upload-items">
      <div className="upload-items-table">
        <table>
          <thead>
            <tr>
              <th>
                <p>Document</p>
              </th>
              <th>
                <p>Status</p>
              </th>
              <th>
                <p>Action</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>
                  <div className="label-doc">
                    {item.icon}

                    <p>{item.label}</p>
                    <Tooltip content={item.tooltipText} size="sm">
                      <img src={ToolTip} alt="" />
                    </Tooltip>
                  </div>
                </td>
                <td>
                  {item.status === "pending" ? (
                    <Chip
                      size="sm"
                      color="warning"
                      variant="flat"
                      className="doc-chip"
                    >
                      <Loader size={12} />
                      Verifying
                    </Chip>
                  ) : item.status === "warning" ? (
                    <Chip
                      size="sm"
                      color="danger"
                      variant="flat"
                      className="doc-chip"
                    >
                      <Info size={12} />
                      Pending
                    </Chip>
                  ) : (
                    <Chip
                      size="sm"
                      color="success"
                      variant="flat"
                      className="doc-chip"
                    >
                      <Check size={12} />
                      Uploaded
                    </Chip>
                  )}
                </td>
                <td>
                  <Button
                    color="default"
                    size="sm"
                    style={{
                      border: "1.1px solid rgba(0, 0, 0, 0.4)",
                      transform: "scale(0.85)",
                      transformOrigin: "left",
                    }}
                    variant="bordered"
                  >
                    <Upload size={14} />
                    {item.status === "pending" ? "Upload" : "Re-upload"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServiceHistory;
