import React, { useState } from "react";
import Logo from "../../assets/Icons/logo.svg";
import Banner from "./Banner";

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  //fetch from local storage
  const storedUser = JSON.parse(localStorage.getItem("onewheel_user") || "{}");

  const handleLogout = () => {
    localStorage.removeItem("onewheel_user");
    const baseUrl = window.location.origin;
    window.location.href = `${baseUrl}/auth`;
  };

  return (
    <>
      <div className="navbar flex items-center justify-between px-4 py-2 relative ">
        <div className="nav-logo">
          <img src={Logo} alt="" />
        </div>
        <div className="nav-user relative z-300 " onMouseEnter={() => setIsDropdownOpen(true)} onMouseLeave={() => setIsDropdownOpen(false)}>
          <div className="flex items-center cursor-pointer">
            <div className="icon w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center mr-2">
              {storedUser.user.name.substring(0, 1)}
            </div>
            {storedUser.user.name}
          </div>
          {isDropdownOpen && (
            <div className="dropdown-menu bg-white absolute right-0 top-full mt-0 w-48 bg-red rounded-md shadow-lg ">
              <div className="dropdown-menu-arrow absolute right-3 -top-2 w-0 h-0 border-l-8 border-r-8 border-b-8 border-white"></div>
              {/* <button className="block w-full text-left px-4 py-2 hover:bg-gray-100 cursor-pointer">Profile</button>
              <button className="block w-full text-left px-4 py-2 hover:bg-gray-100 cursor-pointer">Settings</button> */}
              <button className="block w-full text-left px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={handleLogout}>Logout</button>
            </div>
          )}
        </div>
      </div>
      {/* <Banner /> */}
    </>
  );
};

export default Navbar;