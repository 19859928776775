import React from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContent,
} from "@nextui-org/react";

const RequestDealerPriceModal = ({
  isOpen,
  onClose,
  listingId,
  userId,
}: {
  isOpen: boolean;
  onClose: () => void;
  listingId: string;
  userId: string;
}) => {
  const requestDealerPrice = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/requestDealerPrice/${listingId}/${userId}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Dealer price requested successfully:", data);
      onClose();
    } catch (error) {
      console.error("Error requesting dealer price:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} aria-labelledby="modal-title">
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <p className="text-md text-center">
            We understand that situations change or you may no longer be able to
            wait to maximise the market value of your car in the private market.{" "}
            <br /> <br />
            1Wheel has a network of local dealerships that will bid on your car
            and pay cash for it if the bid is successful. <br />
            <br /> 1Wheel does not charge the vehicle owner for this service, we
            charge each dealership a referral fee if they successfully purchase
            your car.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={requestDealerPrice}
            fullWidth
            className="bg-[#ff9800] text-white"
          >
            Request Dealer Price
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RequestDealerPriceModal;