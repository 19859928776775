"use client";

import type { ButtonProps, LinkProps } from "@nextui-org/react";

import { Button } from "@nextui-org/react";
// import {startsWith} from "lodash";
import { Link } from "react-router-dom";

export type ButtonWithBorderGradientProps = ButtonProps &
  LinkProps & {
    background?: string;
  };

export const ButtonWithBorderGradient = ({
  children,
  background = "--nextui-background",
  style: styleProp,
  ...props
}: ButtonWithBorderGradientProps) => {
  // const linearGradientBg = startsWith(background, "--") ? `hsl(var(${background}))` : background;

  const style = {
    // border: "solid 2px transparent",
    background: `#ff8800`,
    width: "100%",
    backgroundClip: "padding-box, border-box",
  };

  return (
    <Button
      as={Link}
      href="#"
      {...props}
      style={{
        ...style,
        ...styleProp,
      }}
      type="submit"
    >
      {children}
    </Button>
  );
};
