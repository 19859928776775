import React from "react";
import { RiLinksLine } from "react-icons/ri";
import { PiClockCounterClockwise } from "react-icons/pi";
import { CiLocationOn } from "react-icons/ci";
import { Button } from "@nextui-org/react";
import { IoArrowBack } from "react-icons/io5";

interface CarInfoProps {
  changeFormVisibility: () => void;
  isButtonVisible: boolean;
  vehicleData: any;
  location: any;
}
const CarInfo: React.FC<CarInfoProps> = ({
  changeFormVisibility,
  isButtonVisible,
  vehicleData,
  location,
}) => {
  const handleBackClick = () => {
    changeFormVisibility();
  };
  return (
    <div className="car-info lg:overflow-y-scroll lg:scrollbar-hide w-[100%]  px-5 py-10 lg:w-[400px] flex flex-col items-center gap-[20px]">
      <div className="w-full flex items-center">
        {isButtonVisible && (
          <Button
            isIconOnly
            className=" border-gray-400 border-1 rounded-full bg-white mr-2"
            onClick={handleBackClick}
          >
            <IoArrowBack
              style={{
                width: "20px",
                height: "20px",
                color: "#FF9800",
                fontWeight: "600",
              }}
            />
          </Button>
        )}
        <h1 className="text-2xl mx-auto text-center font-medium text-[#1A1D1F] tracking-wide">
          {vehicleData?.vehicle_name}
        </h1>
      </div>
      <div className="image-car w-full h-[283px] items-center ">
        <img
          src={vehicleData?.vehicle_image}
          alt="main-car-image"
          className="w-full h-full object-contain"
        />
      </div>
      <ul className="w-full flex flex-col items-start justify-start px-2 text-[#000]">
        <li className="flex items-center mb-2">
          <RiLinksLine
            style={{
              marginRight: "10px",
            }}
          />
          <a
            href={vehicleData?.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#007CC2]"
          >
            {vehicleData?.url
              ? vehicleData?.url.length > 40
                ? vehicleData?.url.slice(0, 40) + "..."
                : vehicleData?.url
              : "No Url for this vehicle"}
          </a>
        </li>
        <li className="flex items-center mb-2">
          <PiClockCounterClockwise
            style={{
              marginRight: "10px",
            }}
          />
          <p className="items-center">30&nbsp;-&nbsp;60 minutes</p>
        </li>
        <li className="flex items-center ">
          <CiLocationOn
            style={{
              marginRight: "10px",
            }}
          />
          <p className="flex flex-wrap">{vehicleData?.carInfoLocation}</p>
        </li>
      </ul>
      <div className="expectations w-full flex flex-col items-start justify-start px-2">
        <p className="text-[#191919] font-bold mb-2">
          What to Expect During the Viewing:
        </p>
        <ol className="flex flex-col list-disc font-light pl-[20px] text-[#000000] gap-2">
          <li>Meet & greet with seller</li>
          <li>Inspect vehicle and service history</li>
          <li>Test drive</li>
          <li>Confirm final price</li>
        </ol>
      </div>
    </div>
  );
};

export default CarInfo;
