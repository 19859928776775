import React, { useEffect, useRef } from "react";

interface AddressComponentProps {
  label: string;
  value: string;
  setValue: (val: string) => void;
}

const AddressComponent: React.FC<AddressComponentProps> = ({
  label,
  value,
  setValue,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (label === "Viewing Location" && inputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"], // 'geocode' to retrieve full addresses
          componentRestrictions: { country: "AU" }, // Optional: Restrict to a specific country
        }
      );

      // Listen to the place_changed event to get the selected address
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place && place.address_components) {
          const addressComponents = place.address_components;
          const address = {
            streetNumber: getComponent(addressComponents, "street_number"),
            street: getComponent(addressComponents, "route"),
            city: getComponent(addressComponents, "locality"),
            state: getComponent(
              addressComponents,
              "administrative_area_level_1"
            ),
            country: getComponent(addressComponents, "country"),
            postalCode: getComponent(addressComponents, "postal_code"),
          };

          const formattedAddress = [
            address.streetNumber.trim(),
            address.street.trim(),
            address.city.trim(),
            address.state.trim(),
            address.postalCode.trim(),
            address.country.trim(),
          ]
            .filter(Boolean)
            .join(" ");

          setValue(formattedAddress);
        }
      });
    }
  }, [label, setValue]);

  const getComponent = (
    components: google.maps.GeocoderAddressComponent[],
    type: string
  ) => {
    const component = components.find((comp) => comp.types.includes(type));
    return component ? component.long_name : "";
  };

  return (
    <div className="mb-4 w-full">
      <div className="flex flex-row lg:flex-row items-center lg:items-center text-[12px] md:text-[16px]">
        <label className="block text-gray-700 font-semibold mr-2 lg:mb-0 lg:w-1/5">
          {label}:
        </label>
        {label === "Note" ? (
          <p className="ml-10 sm:ml-28">This is for notes</p>
        ) : (
          <input
            ref={inputRef}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={`Enter ${label.toLowerCase()}...`}
            className="md:w-[43%] text-gray-700 text-[12px] lg:text-[14px]  mr-[11rem] sm:ml-[58px] px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200 transition duration-300 ease-in-out"
          />
        )}
      </div>
      {label === "Viewing Location" && (
        <p className="text-[10px] lg:text-[12px] text-gray-500 ml-1 mt-3 w-[90%]">
          Input the exact address you would like to meet the buyer. Eg: your
          home address. Your full address will remain private until a buyer has
          confirmed a viewing appointment
        </p>
      )}
    </div>
  );
};

export default AddressComponent;
