import React, { useState } from 'react';
import Modal from "./Modal"; // Import the new Modal component

interface Event {
  id: number;
  time: string;
  title: string;
  buyer: string;
  category: string;
}
interface ScheduledEventProps {
  scheduledEventsData: any;
}

const ScheduledEvent: React.FC<ScheduledEventProps> = ({ scheduledEventsData }) => {
  // Dummy events data stored in state
  const [isOpen, setIsOpen] = useState(false);
  const [meetingDetails, setMeetingDetails] = useState<any>(null);

  console.log("scheduled events data", scheduledEventsData);

  const handleViewDetails = (data: any) => {
    setIsOpen(!isOpen);
    setMeetingDetails(data);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString); // Convert string to Date object
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options); // Format the date
  };

  const handleCloseModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="p-4 md:p-6 rounded-lg w-full">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-lg md:text-xl font-semibold">Scheduled Events</h2>
        <div className="flex items-center space-x-4 mt-4 md:mt-0"></div>
      </div>

      {/* Check if there are scheduled events */}
      {scheduledEventsData.length === 0 ? (
        <div className="flex justify-center items-center h-64">
          <h3 className="text-3xl font-semibold text-gray-500">No Scheduled Events</h3>
        </div>
      ) : (
        // Render events by category
        ['Upcoming'].map((category) => (
          <div key={category} className="mb-6">
            <h3 className="text-gray-400 uppercase text-xs mb-2">{category}</h3>

            {/* Loop through all scheduledEventsData */}
            {scheduledEventsData.map((scheduleEvent: any) => (
              <div key={scheduleEvent.availability_id}>
                {/* Loop through time_slots inside each scheduleEvent */}
                {scheduleEvent.time_slots?.map((event: any) => (
                  <div
                    key={event.slot_id}
                    className="flex flex-col md:flex-row justify-between items-start p-4 bg-gray-50 rounded-lg shadow-sm mb-4"
                  >
                    <div>
                      <span className="inline-block bg-[#ff991440] border-2 border-[#FF880080] text-xs px-2 py-1 rounded-md">
                        {event.start_time?.split(":")[0]}:{event?.start_time?.split(":")?.[1]} - {formatDate(event?.date)}
                      </span>
                      <h4 className="mt-2 text-lg font-semibold">Viewing Request</h4>
                      <p className="text-sm text-gray-500">Buyer: {event?.name}</p>
                    </div>
                    <div className="mt-4 md:mt-0 space-y-2">
                      <button
                        onClick={() => handleViewDetails(event)}
                        className="border border-gray-300 px-4 py-2 rounded-md text-sm text-gray-700 hover:bg-gray-100"
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))
      )}

      {/* Modal to view details */}
      <Modal isOpen={isOpen} toggleModal={handleCloseModal} meetingDetails={meetingDetails} />
    </div>
  );
};

export default ScheduledEvent;
