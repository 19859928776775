import React, { Dispatch, SetStateAction, useEffect } from "react"; // Add Dispatch and SetStateAction imports
import AddressComponent from "./AddressComponent";
import { Button } from "@nextui-org/react";
import { format } from "path";

interface TimeSlot {
  startTime: string;
  endTime: string;
}

interface DateAvailability {
  date: string;
  available: boolean;
  timeSlots: TimeSlot[];
}

interface DayAvailabilityProps {
  dates: DateAvailability[]; // Ensure this matches the prop you're passing
  setDates: Dispatch<SetStateAction<DateAvailability[]>>;
  note: string;
  setNote: Dispatch<SetStateAction<string>>;
}

const DayAvailability: React.FC<DayAvailabilityProps> = ({
  dates,
  setDates,
  note,
  setNote,
}) => {
  console.log("dates", dates);

  // Check for any available dates with no time slots and add a default time slot
  useEffect(() => {
    const updatedDates = dates.map((date) => {
      if (date.available && date.timeSlots.length === 0) {
        return {
          ...date,
          timeSlots: [{ startTime: "01:00 PM", endTime: "08:00 PM" }],
        };
      }
      return date;
    });

    // Only update the state if there are changes
    const hasChanged = !dates.every(
      (date, index) =>
        JSON.stringify(date) === JSON.stringify(updatedDates[index])
    );

    if (hasChanged) {
      setDates(updatedDates);
    }
  }, [dates, setDates]);

  // Generate time slots at 30-minute intervals
  const generateTimeSlots = (interval: number) => {
    const times = [];

    const current = new Date();
    current.setHours(9, 0, 0, 0); // Start at 9:00 AM
    const end = new Date();
    end.setHours(21, 0, 0, 0); // End at 9:00 PM

    while (current <= end) {
      let hours = current.getHours();
      const minutes = String(current.getMinutes()).padStart(2, "0");

      // Convert 24-hour format to 12-hour format and append AM/PM
      const period = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12; // Convert hour to 12-hour format

      const formattedTime = `${String(hours).padStart(
        2,
        "0"
      )}:${minutes} ${period}`;
      times.push(formattedTime);
      current.setMinutes(current.getMinutes() + interval); // Increment by the interval
    }

    return times;
  };

  const timeOptions = generateTimeSlots(30);

  const toggleAvailability = (index: number) => {
    const newDates = [...dates];
    newDates[index].available = !newDates[index].available;

    // Add a default time slot if the date is set to available and no time slots exist
    if (newDates[index].available && newDates[index].timeSlots.length === 0) {
      newDates[index].timeSlots.push({
        startTime: "01:00 PM", // Default start time
        endTime: "08:00 PM", // Default end time
      });
    }

    setDates(newDates);
  };

  const addTimeSlot = (index: number) => {
    const newDates = [...dates];
    newDates[index].timeSlots.push({
      startTime: "01:00 PM", // Default start time
      endTime: "08:00 PM", // Default end time
    });
    setDates(newDates);
  };

  const removeTimeSlot = (dateIndex: number, slotIndex: number) => {
    const newDates = [...dates];
    newDates[dateIndex].timeSlots.splice(slotIndex, 1);

    if (newDates[dateIndex].timeSlots.length === 0) {
      newDates[dateIndex].available = false;
    }

    setDates(newDates);
  };

  const handleTimeChange = (
    dateIndex: number,
    slotIndex: number,
    type: "start" | "end",
    value: string
  ) => {
    const newDates = [...dates];
    if (type === "start") {
      newDates[dateIndex].timeSlots[slotIndex].startTime = value;
      // Reset endTime when startTime is changed
      newDates[dateIndex].timeSlots[slotIndex].endTime = "";
    } else {
      newDates[dateIndex].timeSlots[slotIndex].endTime = value;
    }
    setDates(newDates);
  };

  // Filter end time options based on the selected start time
  const getFilteredEndTimes = (startTime: string) => {
    if (!startTime) return timeOptions;

    const startIndex = timeOptions.indexOf(startTime);
    if (startIndex === -1) return []; // If start time is not found

    const endIndex = startIndex + 2; // 2 slots ahead (1 hour, assuming 30-minute intervals)
    return timeOptions.slice(endIndex); // Return all options starting 1 hour later
  };
  function formatDate(dateStr:any) {
    // Split the input date string assuming it's in DD/MM/YYYY format
    const [day, month, year] = dateStr.split("/").map(Number);
    
    // Create a new date object using the parsed values
    const date = new Date(year, month - 1, day); // Subtract 1 from month since it's 0-indexed
  
    // Format the date to your desired format
    return date.toLocaleDateString("en-GB", {
      month: "long",
      year: "numeric",
      weekday: "long",
      day: "numeric",
    });
  }


  return (
    <div className="w-full max-w-4xl">
      {dates.map((date, index) => (
        <div key={date.date} className="mb-4">
          <div className="flex flex-row items-center justify-between mb-4">
            <div className="flex items-center w-full">
              <input
                type="checkbox"
                checked={date.available}
                onChange={() => toggleAvailability(index)}
                className="mr-3 h-5 w-5 cursor-pointe r accent-[#ff9800] "
              />
              <span
                className={`font-medium ${
                  date.available ? "text-black" : "text-gray-500"
                }`}
              >
                {formatDate(date.date)}
              </span>
              {!date.available && (
                <div className="text-gray-500 ml-10 sm:ml-28">Unavailable</div>
              )}
            </div>
          </div>

          {date.available &&
            date.timeSlots.map((slot, slotIndex) => (
              <div
                key={slotIndex}
                className="flex flex-col sm:flex-row items-start sm:items-center justify-between mt-2 mb-4 ml-4 sm:ml-40 space-y-3 sm:space-y-0 sm:space-x-3"
              >
                <div className="flex flex-row items-center sm:items-center sm:space-y-0 sm:space-x-2 w-full">
                  <div className="flex w-full sm:w-auto space-x-2 justify-center items-center">
                    <select
                      value={slot.startTime}
                      onChange={(e) =>
                        handleTimeChange(
                          index,
                          slotIndex,
                          "start",
                          e.target.value
                        )
                      }
                      className="border rounded-lg p-2 w-[40%] md:w-full text-xs md:text-[14px] sm:w-28 shadow-sm focus:ring-2 focus:ring-[#FF9800]"
                    >
                      <option value="" disabled>
                        Start Time
                      </option>
                      {timeOptions.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                    <span className="font-semibold">-</span>

                    <select
                      value={slot.endTime}
                      onChange={(e) =>
                        handleTimeChange(
                          index,
                          slotIndex,
                          "end",
                          e.target.value
                        )
                      }
                      className="border rounded-lg p-2 w-[40%] md:w-full text-xs md:text-[14px] sm:w-28 shadow-sm focus:ring-2 focus:ring-[#FF9800]"
                      disabled={!slot.startTime}
                    >
                      <option value="" disabled>
                        End Time
                      </option>
                      {getFilteredEndTimes(slot.startTime).map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                  </div>
                  {date.available && (
                    <Button
                      onClick={() => addTimeSlot(index)}
                      className="p-0 bg-orange-100 text-[#ff9800] ml-5  hover:bg-[#ff9800] hover:text-white transition duration-300 mr-3"
                    >
                      Add Time
                    </Button>
                  )}
                  <button
                    onClick={() => removeTimeSlot(index, slotIndex)}
                    className="ml-0 text-gray-400 hover:text-gray-500 focus:outline-none text-xl"
                  >
                    &times;
                  </button>
                </div>
              </div>
            ))}
        </div>
      ))}
      {/* <AddressComponent label="Note" value={note} setValue={setNote} /> */}
    </div>
  );
};

export default DayAvailability;
