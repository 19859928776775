// import React, { useEffect, useState } from "react";
// import {
//   format,
//   getDaysInMonth,
//   startOfMonth,
//   addMonths,
//   isSameDay,
// } from "date-fns";
// import { Modal, Button } from "antd";
// import Calender from "./SetAvailabilityComponents/Calender";

// interface ShowCalendarProps {
//   availableDates: Date[]; // Receive available dates as a prop
// }

// const ShowCalendar: React.FC<ShowCalendarProps> = ({ availableDates }) => {
//   const [currentDate, setCurrentDate] = useState(new Date());
//   const [selectedDates, setSelectedDates] = useState<Date[]>([]); // Array of selected dates
//   const [isModalVisible, setIsModalVisible] = useState(false);

//   // Function to show the modal
//   const showModal = () => {
//     setIsModalVisible(true);
//   };

//   // Function to hide the modal
//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   useEffect(() => {
//     // Load available dates into selectedDates
//     setSelectedDates(availableDates);
//   }, [availableDates]); // Depend on availableDates prop

//   const daysInMonth = getDaysInMonth(currentDate);
//   const startDay = (startOfMonth(currentDate).getDay() + 6) % 7;

//   const handlePrevMonth = () => setCurrentDate(addMonths(currentDate, -1));
//   const handleNextMonth = () => setCurrentDate(addMonths(currentDate, 1));

//   const isSelected = (day: number) => {
//     return selectedDates.some((date) =>
//       isSameDay(
//         date,
//         new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
//       )
//     );
//   };

//   const isUnavailable = (day: number) => {
//     const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
//     return !availableDates.some((availableDate) => isSameDay(availableDate, date));
//   };

//   const isPastDate = (day: number) => {
//     const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
//     const today = new Date();
//     today.setHours(0, 0, 0, 0); // Set current time to midnight to compare only date

//     return date < today; // Disable dates before today
//   };

//   return (
//     <div className="p-4 rounded-lg max-w-md mx-auto md:h-[65vh]">
//       <div className="flex justify-between items-center mb-4">
//         <button onClick={handlePrevMonth}>←</button>
//         <h2 className="text-xl font-bold">
//           {format(currentDate, "MMMM, yyyy")}
//         </h2>
//         <button onClick={handleNextMonth}>→</button>
//       </div>

//       <div className="grid grid-cols-7 gap-[1.5rem] text-center">
//         {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day, index) => (
//           <div key={index} className="text-gray-500">
//             {day}
//           </div>
//         ))}

//         {/* Empty cells for starting days */}
//         {Array.from({ length: startDay }).map((_, index) => (
//           <div key={index}></div>
//         ))}

//         {/* Days of the month */}
//         {Array.from({ length: daysInMonth }).map((_, index) => {
//           const day = index + 1;
//           const isUnavailableDate = isUnavailable(day);
//           const isPast = isPastDate(day);
          
//           return (
//             <div key={day} className="relative">
//               <button
//                 className={`py-2 w-full rounded-[30%] flex justify-center items-center ${
//                   isPast ? "text-gray-400 cursor-not-allowed" : isUnavailableDate ? "text-gray-400" : isSelected(day) ? "text-black" : "text-black"
//                 } ${
//                   isPast ? "" : isUnavailableDate ? "" : "hover:bg-[#ff9800] hover:text-white"
//                 }`}
//                 disabled={isPast || isUnavailableDate} // Disable past and unavailable dates
//               >
//                 {day}
//               </button>

//               {/* Show spot for selected dates */}
//               {isSelected(day) && (
//                 <div className="absolute inset-x-0 bottom-1 flex justify-center items-center">
//                   <div className="w-1.5 h-1.5 bg-green-500 rounded-full"></div>
//                 </div>
//               )}
//             </div>
//           );
//         })}
//       </div>

//       <Button
//         onClick={showModal}
//         className="mt-4 py-2 h-10 px-6 w-full rounded-lg border border-[#ff9800] bg-[#ff9800] text-white hover:bg-[#ff9800] hover:border-[#ff9800] focus:border-[#ff9800] focus:bg-[#ff9800] active:bg-[#ff9800] active:border-[#ff9800]"
//       >
//         <span className="mr-2">📅</span> Update Availability
//       </Button>

//       {/* Modal for displaying the Calendar */}
//       <Modal
//         open={isModalVisible}
//         onCancel={handleCancel}
//         footer={null} // Hides the default footer buttons
//         width={800} // Adjust the width of the modal if necessary
//         bodyStyle={{ maxHeight: "100vh", overflowY: "scroll" }} // Allow scrolling if content overflows
//         className="overflow-y-auto scrollbar-hide"
//       >
//         {/* Calendar component inside the modal */}
//         <div className="calendar-container w-full md:p-4 p-1">
//           <Calender
//             isModalVisible={isModalVisible}
//             handleCancel={handleCancel}
//           />
//         </div>
//       </Modal>
//     </div>
//   );
// };

// export default ShowCalendar;
import React, { useEffect, useState } from "react";
import {
  format,
  getDaysInMonth,
  startOfMonth,
  addMonths,
  isSameDay,
} from "date-fns";
import { Modal, Button } from "antd";
import Calender from "./SetAvailabilityComponents/Calender";

interface ShowCalendarProps {
  availableDates: Date[]; // Receive available dates as a prop
}

const ShowCalendar: React.FC<ShowCalendarProps> = ({ availableDates }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState<Date[]>([]); // Array of selected dates
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Function to show the modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Function to hide the modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    // Load available dates into selectedDates
    setSelectedDates(availableDates);
  }, [availableDates]); // Depend on availableDates prop

  const daysInMonth = getDaysInMonth(currentDate);
  const startDay = (startOfMonth(currentDate).getDay() + 6) % 7;

  const handlePrevMonth = () => setCurrentDate(addMonths(currentDate, -1));
  const handleNextMonth = () => setCurrentDate(addMonths(currentDate, 1));

  const isSelected = (day: number) => {
    return selectedDates.some((date) =>
      isSameDay(
        date,
        new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
      )
    );
  };

  const isUnavailable = (day: number) => {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    return !availableDates.some((availableDate) => isSameDay(availableDate, date));
  };

  const isPastDate = (day: number) => {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set current time to midnight to compare only date

    return date < today; // Disable dates before today
  };

  return (
    <div className="p-4 rounded-lg max-w-md mx-auto md:h-[65vh]">
      <div className="flex justify-between items-center mb-4">
        <button onClick={handlePrevMonth}>←</button>
        <h2 className="text-xl font-bold">
          {format(currentDate, "MMMM, yyyy")}
        </h2>
        <button onClick={handleNextMonth}>→</button>
      </div>

      <div className="grid grid-cols-7 gap-[1.5rem] text-center">
        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day, index) => (
          <div key={index} className="text-gray-500">
            {day}
          </div>
        ))}

        {/* Empty cells for starting days */}
        {Array.from({ length: startDay }).map((_, index) => (
          <div key={index}></div>
        ))}

        {/* Days of the month */}
        {Array.from({ length: daysInMonth }).map((_, index) => {
          const day = index + 1;
          const isUnavailableDate = isUnavailable(day);
          const isPast = isPastDate(day);
          const isAvailableDate = !isUnavailableDate && !isPast;

          return (
            <div key={day} className="relative">
              <button
                className={`py-2 w-full rounded-[30%] flex justify-center items-center ${
                  isPast ? "text-gray-400 cursor-not-allowed" : 
                  isAvailableDate ? "bg-[#FDEDD3] text-[#ff9800] font-semibold" : 
                  isUnavailableDate ? "text-gray-400" : "text-black"
                } ${
                  isPast ? "" : isUnavailableDate ? "" : "hover:bg-[#ff9800] hover:text-white"
                }`}
                disabled={isPast || isUnavailableDate} // Disable past and unavailable dates
              >
                {day}
              </button>

              {/* Show spot for selected dates */}
              {/* {isSelected(day) && (
                <div className="absolute inset-x-0 bottom-1 flex justify-center items-center">
                  <div className="w-1.5 h-1.5 bg-green-500 rounded-full"></div>
                </div>
              )} */}
            </div>
          );
        })}
      </div>

      <Button
        onClick={showModal}
        className="mt-4 py-2 h-10 px-6 w-full rounded-lg border border-[#ff9800] bg-[#ff9800] text-white hover:bg-[#ff9800] hover:border-[#ff9800] focus:border-[#ff9800] focus:bg-[#ff9800] active:bg-[#ff9800] active:border-[#ff9800]"
      >
        <span className="mr-2">📅</span> Update Availability
      </Button>

      {/* Modal for displaying the Calendar */}
      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null} // Hides the default footer buttons
        width={800} // Adjust the width of the modal if necessary
        bodyStyle={{ maxHeight: "100vh", overflowY: "scroll" }} // Allow scrolling if content overflows
        className="overflow-y-auto scrollbar-hide"
      >
        {/* Calendar component inside the modal */}
        <div className="calendar-container w-full md:p-4 p-1">
          <Calender
            isModalVisible={isModalVisible}
            handleCancel={handleCancel}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ShowCalendar;

