import React from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContent,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

const AlreadyListed = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <Modal isOpen={isOpen} onClose={onClose} aria-labelledby="modal-title">
      <ModalContent>
        <ModalHeader>
          <h1>You have already listed a car with us</h1>
        </ModalHeader>
        <ModalBody>
          <p className="text-lg">
            You have already listed a car with us. If you wish to list a
            different car, please contact us.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            fullWidth
            className="bg-[#ff9800] text-white"
            onClick={() => {
              navigate("/");
            }}
          >
            View Listing
          </Button>
          <Button
            fullWidth
            className="border-[#ff9800] "
            onClick={() => {
                window.location.href = "mailto:admin@1wheel.com.au";
              }}
            
          >
            Contact Us
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AlreadyListed;
