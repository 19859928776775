import React, { useState } from 'react';
import axios from 'axios';

const FileUpload = () => {
  const [files, setFiles] = useState([]);
  const [userId, setUserId] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [listingId, setListingId] = useState('');
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleDocumentUpload = async (e) => {
    e.preventDefault();

    if (!files.length || !userId || !documentType || !documentName) {
      setMessage('All fields are required');
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('userId', userId);
    formData.append('documentType', documentType);
    formData.append('documentName', documentName);

    try {
      const response = await axios.post('http://localhost:3001/api/vehicle/upload-document', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Failed to upload document');
      console.error('Error uploading document:', error);
    }
  };

  const handleVehicleImageUpload = async (e) => {
    e.preventDefault();

    if (!files.length || !listingId) {
      setMessage('Files and Listing ID are required');
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('listingId', listingId);

    try {
      const response = await axios.post('http://loalhost:3001/api/vehicle/upload-vehicle-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Failed to upload vehicle images');
      console.error('Error uploading vehicle images:', error);
    }
  };

  return (
    <div>
      <h1>Upload File</h1>
      <form onSubmit={handleDocumentUpload}>
        <h2>Upload Document</h2>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          required
        />
        <input
          type="text"
          placeholder="User ID"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Document Type"
          value={documentType}
          onChange={(e) => setDocumentType(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Document Name"
          value={documentName}
          onChange={(e) => setDocumentName(e.target.value)}
          required
        />
        <button type="submit">Upload Document</button>
      </form>

      <form onSubmit={handleVehicleImageUpload}>
        <h2>Upload Vehicle Images</h2>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          required
        />
        <input
          type="text"
          placeholder="Listing ID"
          value={listingId}
          onChange={(e) => setListingId(e.target.value)}
          required
        />
        <button type="submit">Upload Vehicle Images</button>
      </form>

      {message && <p>{message}</p>}
    </div>
  );
};

export default FileUpload;
