"use client";

import React from "react";
import { Button } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import VerticalSteps from "./vertical-steps";

import RowSteps from "./row-steps";
import MultistepNavigationButtons from "./multistep-navigation-buttons";
import { cn } from "../../../utils/cn";

export type MultiStepSidebarProps = React.HTMLAttributes<HTMLDivElement> & {
  currentPage: number;
  onBack: () => void;
  onNext: () => void;
  formData: any;
  vehicleDetails: any;
  onChangePage: (page: number) => void;
};

const stepperClasses = cn(
  // light
  "[--step-color:hsl(var(--nextui-secondary-400))]",
  "[--active-color:hsl(var(--nextui-secondary-400))]",
  "[--inactive-border-color:hsl(var(--nextui-secondary-200))]",
  "[--inactive-bar-color:hsl(var(--nextui-secondary-200))]",
  "[--inactive-color:hsl(var(--nextui-secondary-300))]",
  // dark
  "dark:[--step-color:rgba(255,255,255,0.1)]",
  "dark:[--active-color:hsl(var(--nextui-foreground-600))]",
  "dark:[--active-border-color:rgba(255,255,255,0.5)]",
  "dark:[--inactive-border-color:rgba(255,255,255,0.1)]",
  "dark:[--inactive-bar-color:rgba(255,255,255,0.1)]",
  "dark:[--inactive-color:rgba(255,255,255,0.2)]"
);

const MultiStepSidebar = React.forwardRef<
  HTMLDivElement,
  MultiStepSidebarProps
>(
  (
    {
      children,
      className,
      currentPage,
      onBack,
      onNext,
      onChangePage,
      vehicleDetails,
      formData,
      ...props
    },
    ref
  ) => {
    // console.log("vehicleDetails", vehicleDetails);

    return (
      <div
        ref={ref}
        className={cn("flex h-[94.5vh] mt-[20px]   w-full gap-x-2", className)}
        {...props}
      >
        <div className="flex hidden h-full w-[344px] ml-[20px] flex-shrink-0 flex-col items-start gap-y-8 rounded-large bg-[#ff8800] px-8 py-6 shadow-small lg:flex overflow-auto scrollbar-hide">
          <Button
            className="bg-default-50 text-small font-medium text-default-500 shadow-lg"
            isDisabled={currentPage === 0}
            radius="full"
            variant="flat"
            onPress={onBack}
          >
            <Icon icon="solar:arrow-left-outline" width={18} />
            Back
          </Button>
          {/* {form} */}
          {(vehicleDetails || formData?.vehicleName) && (
            <div className="snapshot">
              <h1>{formData?.vehicleName}</h1>
              <h2>
                {formData?.location} | {formData?.numberPlate} | {formData.km}{" "}
                km
              </h2>
            </div>
          )}
          <div>
            <div className="text-xl font-medium leading-7 text-white">
              Provide your vehicles history
            </div>
            <div className="mt-1 text-base font-medium leading-6 text-white opacity-80">
              The more details you provide, the better chance we have of selling your car.
            </div>
          </div>
          {/* Desktop Steps */}

          <VerticalSteps
            className={stepperClasses}
            color="primary"
            currentStep={currentPage}
            steps={[
              {
                title: "Value My Car",
                // description: "Setting up your foundation",
              },
              {
                title: "Vehicle History",
                // description: "Fill up Car usage details",
              },
              {
                title: "Tell us more",
                // description: "Tell us more",
              },
            ]}
            onStepChange={onChangePage}
          />
        </div>
        <div className="flex h-full w-full flex-col items-center gap-4 md:p-4 overflow-scroll scrollbar-hide">
          <div className="sticky top-0 z-10 w-[95%] rounded-large bg-[#ff8800] py-1 shadow-small md:max-w-xl lg:hidden ">
            <div className="flex justify-center">
              {/* Mobile Steps */}
              <RowSteps
                className={cn("pl-6", stepperClasses)}
                currentStep={currentPage}
                steps={[
                  {
                    title: "Value My Car",
                  },
                  {
                    title: "Car usage details",
                  },
                  {
                    title: "Tell us more",
                  },
                ]}
                onStepChange={onChangePage}
              />
            </div>
          </div>
          <div className=" w-full p-4 sm:max-w-md md:max-w-[40rem]">
            {children}
            <MultistepNavigationButtons
              backButtonProps={{ isDisabled: currentPage === 0 }}
              className="lg:hidden"
              nextButtonProps={{
                children:
                  currentPage === 0
                    ? "NEXT"
                    : currentPage === 1
                    ? "NEXT"
                    : "SUBMIT",
              }}
              onBack={onBack}
              onNext={onNext}
            />
          </div>
        </div>
      </div>
    );
  }
);

MultiStepSidebar.displayName = "MultiStepSidebar";

export default MultiStepSidebar;
