export const saveToLocalStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error("Error saving to localStorage", error);
  }
};

export const fetchFromLocalStorage = (key: string): string | null => {
  try {
    const value = localStorage.getItem(key);
    const user=JSON.parse(value as string);
    return user.user.id;
  } catch (error) {
    console.error("Error fetching from localStorage", error);
    return null;
  }
};


  