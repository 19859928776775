import React, { useEffect, useState } from "react";
import {
  format,
  getDaysInMonth,
  startOfMonth,
  addMonths,
  isSameDay,
} from "date-fns";

interface MainCalendarProps {
  triggerParentUpdate: (date: string) => void; // Change parameter to string
  availableDates: any;
}

const MainCalendar: React.FC<MainCalendarProps> = ({ triggerParentUpdate,availableDates }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<number | null>(null);

 

  useEffect(() => {
    const selected: Date[] = [];
    const unavailable: Date[] = [];

    // Convert availableDates prop into arrays of available and unavailable dates
    availableDates.forEach((item:any) => {
      const date = new Date(item); // Assuming date is in YYYY-MM-DD format
      if (item.isUnavailable) {
        unavailable.push(date);
      } else {
        selected.push(date);
      }
    });
    console.log("dates",selected);
    setSelectedDates(selected);
  }, [availableDates]);

  const daysInMonth = getDaysInMonth(currentDate);
  const startDay = startOfMonth(currentDate).getDay();

  const handlePrevMonth = () => setCurrentDate(addMonths(currentDate, -1));
  const handleNextMonth = () => setCurrentDate(addMonths(currentDate, 1));

  const isSelected = (day: number) =>
    selectedDates.some((date) =>
      isSameDay(
        date,
        new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
      )
    );


    const isPastDate = (day: number) => {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      ).setHours(0, 0, 0, 0); // Convert date to a timestamp
      return date < new Date().setHours(0, 0, 0, 0); // Compare both as timestamps
    };

  return (
    <div className="p-4 rounded-lg max-w-md mx-auto bg-white shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <button onClick={handlePrevMonth}>←</button>
        <h2 className="text-xl font-bold">
          {format(currentDate, "MMMM, yyyy")}
        </h2>
        <button onClick={handleNextMonth}>→</button>
      </div>

      <div className="grid grid-cols-7 gap-2 text-center">
        {["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map((day, index) => (
          <div key={index} className="text-[#B0B7C3] text-sm p-2">
            {day}
          </div>
        ))}

        {/* Correctly fill in empty spaces before the first day of the month */}
        {Array.from({ length: (startDay + 6) % 7 }).map((_, index) => (
          <div key={index}></div>
        ))}

        {Array.from({ length: daysInMonth }).map((_, index) => {
          const day = index + 1;
          const isAvailable =
            isSelected(day) && !isPastDate(day);

          return (
            <div
              key={day}
              className="relative p-1"
              onClick={() => {
                if (isAvailable) {
                  const selectedFullDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    day
                  );
                  const formattedDate = format(selectedFullDate, "EEEE d MMMM"); // Format as "Tuesday 16 October"
                  setSelectedDate(day);
                  triggerParentUpdate(formattedDate); // Pass the formatted date to the callback
                }
              }}
            >
              <button
                className={`py-2 rounded-[30%] w-full ${
                  selectedDate === day
                    ? "bg-[#FF9800] text-white font-semibold"
                    : isAvailable
                    ? "bg-[#FDEDD3] text-[#ff9800] font-semibold"
                    : "bg-transparent text-gray-400 cursor-not-allowed"
                }`}
                disabled={!isAvailable}
              >
                {day}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MainCalendar;
