import React, { useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  User,
  Pagination,
  Selection,
  ChipProps,
  SortDescriptor,
} from "@nextui-org/react";

import { columns, statusOptions } from "../adminComponents/tabledata";
import { VerticalDotsIcon } from "../adminComponents/VerticalDotsIcon";
import { SearchIcon } from "lucide-react";
import { ChevronDownIcon } from "../adminComponents/ChevronDownIcon";
import { capitalize } from "../adminComponents/utils";
import { PlusIcon } from "../adminComponents/PlusIcon";

import { useNavigate } from "react-router-dom";
import ConfirmDelete from "../adminComponents/confirmDelete";

const typeColorMap: Record<string, ChipProps["color"]> = {
  seller: "success",
  buyer: "primary",
  admin: "warning",
};

const INITIAL_VISIBLE_COLUMNS = [
  "name",
  "availabilityLink",
  "phoneNumber",
  "isListingAvailable",
  "type",
  "createdAt",
  "actions",
];

// type User = (typeof users)[0];

interface UserType {
  id: number;
  name: string;
  email: string;
  unique_link:string;
  phone_number: string;
  isListingAvailable: boolean;
  firstVehicleId: number;
  firstListingId: number;
  type: "seller" | "buyer" | "admin";
  created_at: string;
}
interface FetchUsersResponse {
  users: UserType[];
}

export default function App() {
  const navigate = useNavigate();
  const [fetchedUser, setFetchedUser] = useState<UserType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    useState<boolean>(false);
    const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null); 

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = JSON.parse(
          localStorage.getItem("onewheel_user") || "{}"
        )?.token; // Assuming you store the token in localStorage

        if (!token) {
          throw new Error("No token found");
        }

        // Fetch users
        const userResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!userResponse.ok) {
          throw new Error("Failed to fetch users");
        }

        const userData: FetchUsersResponse = await userResponse.json();
        console.log(userData.users);

        setFetchedUser(userData.users);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);
  const [filterValue, setFilterValue] = React.useState("");
  const [selectedKeys, setSelectedKeys] = React.useState<Selection>(
    new Set([])
  );
  const [visibleColumns, setVisibleColumns] = React.useState<Selection>(
    new Set(INITIAL_VISIBLE_COLUMNS)
  );
  const [statusFilter, setStatusFilter] = React.useState<Selection>("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: "age",
    direction: "ascending",
  });

  // Open modal
  const handleOpenModal = (userId: number) => {
    setUserIdToDelete(userId); // Set the user ID
    setShowConfirmDeleteModal(true); // Show confirmation modal
  };

  const handleCloseModal = () => {
    setShowConfirmDeleteModal(false);
    setUserIdToDelete(null); // Clear user ID when closing modal
  };

  // Handle delete action
  const handleDelete = async () => {
    if (userIdToDelete !== null) {
      await deleteUser(userIdToDelete);
      setShowConfirmDeleteModal(false); // Close the modal after deletion
    }
  };
  const [page, setPage] = React.useState(1);

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return columns;

    return columns.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...fetchedUser];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((user) =>
        user.name.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== statusOptions.length
    ) {
      filteredUsers = filteredUsers.filter((user) =>
        Array.from(statusFilter).includes(user.type)
      );
    }

    return filteredUsers;
  }, [fetchedUser, filterValue, statusFilter]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof UserType] as
        | number
        | string;
      const second = b[sortDescriptor.column as keyof UserType] as
        | number
        | string;
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const renderCell = React.useCallback(
    (user: UserType, columnKey: React.Key) => {
      const cellValue = user[columnKey as keyof UserType];

      switch (columnKey) {
        case "name":
          return (
            <User
              avatarProps={{ radius: "lg" }}
              description={user.email}
              name={
                cellValue && typeof cellValue === "string"
                  ? cellValue.substring(0, 20)
                  : cellValue
              }
            >
              {user.email}
            </User>
          );
          case "availabilityLink":
            const baseUrl = window.location.origin; // Replace with your actual base URL
            const fullLink = user.unique_link ? `${baseUrl}/schedule-meeting/${user.unique_link}` : null;
          
            return (
              <div className="flex flex-col">
                {fullLink ? (
                  <a
                    href={fullLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-bold text-md items-center no-underline text-blue-500 underline"
                  >
                    View Availability
                  </a>
                ) : (
                  <p className="text-bold text-small">N/A</p>
                )}
              </div>
            );
        case "phoneNumber":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-small">
                {user.phone_number !== "" ? user.phone_number : "N/A"}
              </p>
            </div>
          );

        case "createdAt":
          return (
            <p className="text-small">
              {new Date(user.created_at).toLocaleDateString()}
            </p>
          );
        case "type":
          return (
            <Chip
              className="capitalize"
              color={typeColorMap[user.type]}
              size="sm"
              variant="flat"
            >
              {capitalize(user.type)}
            </Chip>
          );
        case "isListingAvailable":
          return (
            <div>
              {user.isListingAvailable === true ? (
                <>
                  <Button
                    color="primary"
                    size="sm"
                    variant="flat"
                    onPress={() => {
                      // window.location.href = `/admin/listing/${user.firstListingId}`;
                      navigate(`/admin/vehicles/${user.firstVehicleId}`);
                    }}
                  >
                    View Listing
                  </Button>
                </>
              ) : (
                "Listing not available"
              )}
            </div>
          );
        case "actions":
          return (
            <div className="relative flex justify-end items-center gap-2">
              <Dropdown>
                <DropdownTrigger>
                  <Button isIconOnly size="sm" variant="light">
                    <VerticalDotsIcon className="text-default-300" />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem>View</DropdownItem>
                  <DropdownItem>Edit</DropdownItem>
                  <DropdownItem
                    onPress={() => handleOpenModal(user.id)}
                    // deleteUser(user.id);
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          );
        default:
          return cellValue;
      }
    },
    []
  );

  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onRowsPerPageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
    },
    []
  );

  const onSearchChange = React.useCallback((value?: string) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            className="w-full sm:max-w-[44%]"
            placeholder="Search..."
            startContent={<SearchIcon />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          />
          <div className="flex gap-3">
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button
                  endContent={<ChevronDownIcon className="text-small" />}
                  variant="flat"
                >
                  Type
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={statusFilter}
                selectionMode="multiple"
                onSelectionChange={setStatusFilter}
              >
                {statusOptions.map((status) => (
                  <DropdownItem key={status.uid} className="capitalize">
                    {capitalize(status.name)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button
                  endContent={<ChevronDownIcon className="text-small" />}
                  variant="flat"
                >
                  Columns
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={visibleColumns}
                selectionMode="multiple"
                onSelectionChange={setVisibleColumns}
              >
                {columns.map((column) => (
                  <DropdownItem key={column.uid} className="capitalize">
                    {capitalize(column.name)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Button
              color="primary"
              endContent={<PlusIcon />}
              disableAnimation={true}
              disabled={true}
              // cursor="not-allowed"
              style={{ cursor: "not-allowed" }}
            >
              Add New
            </Button>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-default-400 text-small">
            Total users: {fetchedUser.length}
          </span>
          <label className="flex items-center text-default-400 text-small">
            Rows per page:
            <select
              className="bg-transparent outline-none text-default-400 text-small"
              onChange={onRowsPerPageChange}
            >
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [
    filterValue,
    statusFilter,
    visibleColumns,
    onSearchChange,
    onRowsPerPageChange,
    fetchedUser.length,
    hasSearchFilter,
  ]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <span className="w-[30%] text-small text-default-400">
          {selectedKeys === "all"
            ? "All items selected"
            : `${selectedKeys.size} of ${filteredItems.length} selected`}
        </span>
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={pages}
          onChange={setPage}
        />
        <div className="hidden sm:flex w-[30%] justify-end gap-2">
          <Button
            isDisabled={pages === 1}
            size="sm"
            variant="flat"
            onPress={onPreviousPage}
          >
            Previous
          </Button>
          <Button
            isDisabled={pages === 1}
            size="sm"
            variant="flat"
            onPress={onNextPage}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }, [selectedKeys, items.length, page, pages, hasSearchFilter]);

  const deleteUser = async (userId: number) => {
    try {
      const token = JSON.parse(
        localStorage.getItem("onewheel_user") || "{}"
      )?.token;

      if (!token) {
        throw new Error("No token found");
      }

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/users/${userId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete user");
      }

      // Remove the deleted user from the state
      setFetchedUser((prevUsers) =>
        prevUsers.filter((user) => user.id !== userId)
      );
    } catch (err) {
      setError((err as Error).message);
    }
  };

  return (
    <>
      <Table
        isStriped
        aria-label="Example table with custom cells, pagination and sorting"
        isHeaderSticky
        bottomContent={bottomContent}
        bottomContentPlacement="outside"
        classNames={{
          wrapper: "max-h-[582px]",
        }}
        selectedKeys={selectedKeys}
        selectionMode="multiple"
        sortDescriptor={sortDescriptor}
        topContent={topContent}
        topContentPlacement="outside"
        onSelectionChange={setSelectedKeys}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={headerColumns}>
          {(column) => (
            <TableColumn
              key={column.uid}
              align={column.uid === "actions" ? "center" : "start"}
              allowsSorting={column.sortable}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody emptyContent={"No users found"} items={sortedItems}>
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
      {showConfirmDeleteModal && (
        <ConfirmDelete
          isOpen={showConfirmDeleteModal}
          onClose={handleCloseModal}
          onConfirm={handleDelete}
          title="Confirm Delete"
          bodyContent={[
            "Are you sure you want to delete this user?",
          ]}
        />
      )}
    </>
  );
}
