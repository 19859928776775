import React from 'react';
import { FaFacebook, FaInstagramSquare  } from 'react-icons/fa';
import Logo from "../../../assets/Icons/white-logo.webp"

const Footer: React.FC = () => {
  return (
    <footer className="bg-[#FF9800] text-white py-8 px-4 mb-[60px] md:mb-0 mt-[2rem]"
    >
      <div className="container mx-auto flex flex-wrap justify-between items-start space-y-6 md:space-y-0">
        <div className="w-full md:w-1/3 pr-4">
          {/* <h2 className="text-3xl font-bold mb-2">1Wheel</h2> */}
          <img src={Logo} alt="onw wheel logo " 
          className="w-150"
          />
          <p className="text-sm mb-4 mt-2">The NEW way to sell your car privately in Australia.</p>
        </div>
        
        <div className="hidden md:block w-px bg-white h-32 mx-4"></div>
        
        <div className="w-full md:w-1/5">
          <h3 className="font-bold text-lg mb-3">Follow Us</h3>
          <div className="flex space-x-4">
            <a href="https://www.facebook.com/people/1wheel/61556873021877/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-200">
              <FaFacebook size={24} color='white'/>
            </a>
            <a href="https://www.instagram.com/1wheel_aus/?igsh=bHVwYWgwbmx2cG9m" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-200">
              <FaInstagramSquare size={24} color='white'/>
            </a>
          </div>
        </div>
        
        <div className="w-full md:w-1/5">
          <h3 className="font-bold text-lg mb-3">Contact</h3>
          <p className="mb-1">0400 481 110</p>
          <p>info@1Wheel.com.au</p>
        </div>
        
        <div className="w-full md:w-1/5">
          <h3 className="font-bold text-lg mb-3">Resources</h3>
          <p className="mb-2">
            <a href="https://1wheel.com.au/privacy-policy/" target="_blank" rel="noopener noreferrer" className="hover:underline">
              Privacy Policy
            </a>
          </p>
          <p>
            <a href="https://1wheel.com.au/1wheel-terms-of-service/" target="_blank" rel="noopener noreferrer" className="hover:underline">
              Terms & Conditions
            </a>
          </p>
        </div>
      </div>
      
      <div className="mt-8 pt-4 border-t border-white text-center text-sm">
        <p>2024 © 1Wheel Pvt Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;