import React, { useEffect } from 'react';
import { Intercom } from '@intercom/messenger-js-sdk';

interface User {
  id?: string;
  name?: string;
  email?: string;
  createdAt?: number; // Assuming createdAt is stored as a Unix timestamp in seconds
}

const IntercomProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useEffect(() => {
    // Retrieve and parse the stored user data
    const storedUserJson = localStorage.getItem("onewheel_user");
    let storedUser: { user?: User } = {};

    if (storedUserJson) {
      try {
        storedUser = JSON.parse(storedUserJson);
      } catch (error) {
        console.error("Error parsing stored user data", error);
      }
    }
  console.log("intercom",storedUser);
    // Prepare Intercom settings
    const intercomSettings = {
      app_id: 'mlmcipaa', // Your Intercom App ID
      user_id: storedUser?.user?.id || undefined, // User ID if available
      name: storedUser?.user?.name || 'Guest', // User name or default to 'Guest'
      email: storedUser?.user?.email || undefined, // User email if available
      created_at: typeof storedUser?.user?.createdAt === 'number' ? storedUser.user.createdAt : undefined, // User sign-up date if valid
    };

    // Initialize Intercom with the settings
    Intercom(intercomSettings);

    return () => {
      // Correctly shutdown Intercom when the component unmounts
      Intercom({ app_id: 'mlmcipaa', action: 'shutdown' });
    };
  }, []);

  return <>{children}</>;
};

export default IntercomProvider;
