import React, { useState, useEffect, useRef } from "react";
import { cn } from "../../../utils/cn";
import { Input, Checkbox } from "@nextui-org/react";
import Logo from "../../../assets/Icons/logo.svg";

export type CompanyInformationFormProps =
  React.HTMLAttributes<HTMLFormElement> & {
    setFormData: React.Dispatch<React.SetStateAction<any>>;
    formData: any;
    validationErrors: any;
  };

interface Field {
  label: string;
  name: string;
  type: string;
  placeholder?: string;
  options?: string[];
}

interface Suggestion {
  place_id: string;
  description: string;
}

declare global {
  interface Window {
    google: {
      maps: {
        places: {
          AutocompleteService: new () => any;
          PlacesServiceStatus: {
            OK: string;
          };
        };
      };
    };
  }
}

const BasicDetails = React.forwardRef<
  HTMLFormElement,
  CompanyInformationFormProps
>(({ className, setFormData, formData, validationErrors, ...props }, ref) => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const autocompleteRef = useRef<any>(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1025);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1025);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC6cjvrTax5YIu7vGWKCcTA94y0VKnQw6E&libraries=places`;
    script.async = true;
    script.onload = initAutocomplete;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initAutocomplete = () => {
    autocompleteRef.current =
      new window.google.maps.places.AutocompleteService();
  };

  const handlePostcodeSuburbChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    handleChange(e, "postcode_suburb");

    if (value.length > 0 && autocompleteRef.current) {
      autocompleteRef.current.getPlacePredictions(
        {
          input: value,
          types: ["(regions)"],
          componentRestrictions: { country: "AU" }, // Adjust this for your country
        },
        handleAutocompleteResults
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleAutocompleteResults = (
    predictions: Suggestion[] | null,
    status: string
  ) => {
    if (
      status === window.google.maps.places.PlacesServiceStatus.OK &&
      predictions
    ) {
      setSuggestions(predictions);
    }
  };

  const handleSuggestionClick = (suggestion: Suggestion) => {
    setFormData({ ...formData, postcode_suburb: suggestion.description });
    setSuggestions([]);
  };

  const fields: Field[] = [
    {
      label:
        "Where can we tell people your car is located? (Enter Suburb / Postcode)",
      name: "postcode_suburb",
      type: "text",
      placeholder: "Enter Suburb / Postcode",
    },
    {
      label: "Have you owned this vehicle since new?",
      name: "ownership",
      type: "radio",
      options: ["Yes", "No, I purchased the car second hand"],
    },
    {
      label:
        "Which option would best describe the majority of the driving you do?",
      name: "drivingType",
      type: "radio",
      options: [
        "City/Town driving",
        "Four Wheel Driving",
        "Highway driving",
        "Towing",
        "Farm Use",
      ],
    },
    {
      label:
        "Does your car have provable service history (Service stamps or receipts)?",
      name: "serviceHistory",
      type: "radio",
      options: ["Full","Partial","None"],
    },
    {
      label: "Does your car have 2 keys?",
      name: "keys",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      label: "Do you owe money on your car?",
      name: "moneyOwed",
      type: "radio",
      options: ["Yes", "No"],
    },
  ];

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    setFormData({ ...formData, [name]: e.target.value });
  };

  return (
    <>
      <div className="form-logo">
        <img src={Logo} alt="" />
      </div>
      {isMobileView && (formData?.vehicleName) && (
          <div className="bg-[#ff8800] rounded-md mt-4">
            <div className="snapshot">
              <h1>{formData?.vehicleName}</h1>
              <h2>
                {formData?.location} | {formData?.numberPlate} | {formData?.km}{" "}
                km
              </h2>
            </div>
          </div>
        )}
      <div className={cn("grid grid-cols-2 flex-col gap-4 py-8", className)}>
        {fields.map((field) => (
          <div
            className="onboarding-input-item col-span-2 sm:col-span-1"
            key={field.name}
          >
            <label>{field.label} *</label>
            {field.type === "radio" ? (
              <div className="onboarding-input-radio">
                {field.options?.map((option) => (
                  <div className="onboarding-input-radio-item" key={option}>
                    <Checkbox
                      checked={formData[field.name] === option}
                      onChange={() => {
                        setFormData({
                          ...formData,
                          [field.name]: option,
                        });
                      }}
                      data-selected={
                        formData[field.name] === option ? "true" : "false"
                      }
                      aria-label="Close"
                    >
                      <span className="checkbox">{option}</span>
                    </Checkbox>
                  </div>
                ))}
              </div>
            ) : field.name === "postcode_suburb" ? (
              <div className="relative">
                <Input
                  type={field.type}
                  label={field.placeholder}
                  value={formData[field.name] || ""}
                  onChange={handlePostcodeSuburbChange}
                  required
                />
                {suggestions.length > 0 && (
                  <ul className="google-list">
                    {suggestions.map((suggestion) => (
                      <li
                        key={suggestion.place_id}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="google-list-item p-2 hover:bg-gray-100 cursor-pointer"
                      >
                        {suggestion.description}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ) : (
              <Input
                type={field.type}
                label={field.placeholder}
                value={formData[field.name] || ""}
                onChange={(e) => handleChange(e, field.name)}
                required
              />
            )}
            {validationErrors[field.name] && (
              <span className="text-red-500">
                {validationErrors[field.name]}
              </span>
            )}
          </div>
        ))}
      </div>
    </>
  );
});

BasicDetails.displayName = "BasicDetails";

export default BasicDetails;
