import React, { useEffect, useState } from "react";
import CarsGuide from "../../../assets/Images/CarCompanyLogos/carsguide.png";
import CarSales from "../../../assets/Images/CarCompanyLogos/carsales.png";
import Autotrader from "../../../assets/Images/CarCompanyLogos/autotrader.png";
import GumTree from "../../../assets/Images/CarCompanyLogos/gumtree.png";
import OneWheel from "../../../assets/Images/CarCompanyLogos/oneweheel.png";


interface Logo {
  name: string;
  imageUrl: string; // Local image asset
  url?: string; // Added url field
}

interface LinkData {
  link_id: number;
  listing_id: number;
  url: string;
  url_source: string;
  description: string | null;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;
}

// Define the logo mapping based on the URL source
const logoMapping: { [key: string]: string } = {
  oneWheel: CarsGuide,
  carsGuide: CarSales,
  carSales: Autotrader,
  autoTrader: CarsGuide, // Adjust this if needed
  gumTree: CarSales, // Adjust this if needed
};

interface Vehicle {
  id: number;
  dealership: number;
  private_sale: number;
  potential_saving: number;
}

interface ListingDetails {
  id: number;
  agree_market_your_car: number;
  vehicle: Vehicle[];
  valueAnalyser?: {
    dealership: number;
    private_sale: number;
    potential_saving: number;
  }[];
}

interface ListingLogosProps {
  listingDetails: ListingDetails | null;
}

const availableLogos: Logo[] = [
  { name: "oneWheel", imageUrl: OneWheel },
  { name: "carsGuide", imageUrl: CarsGuide },
  { name: "carSales", imageUrl: CarSales },
  { name: "autoTrader", imageUrl: Autotrader },
  { name: "gumTree", imageUrl: GumTree },
  // Add more logos as needed
];
const ListingLogos: React.FC<ListingLogosProps> = ({ listingDetails }) => {
  const [logosWithUrls, setLogosWithUrls] = useState<Logo[]>([]);

  // Function to fetch URLs for logos
  const getImageUrls = async (): Promise<LinkData[] | undefined> => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/links/${listingDetails?.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      if (data && Array.isArray(data.links)) {
        return data.links;
      } else {
        throw new Error("Unexpected response structure");
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    const fetchLogosWithUrls = async () => {
      const data = await getImageUrls(); // Fetch the URL data

      if (Array.isArray(data)) {
        // Create a mapping from url_source to URLs
        const urlMapping: { [key: string]: string } = {};
        data.forEach((item: LinkData) => {
          if (item.url_source && item.url) {
            urlMapping[item.url_source] = item.url;
          }
        });

        // Filter and match logos based on URL source
        const filteredLogos = availableLogos
          .filter((logo) => urlMapping[logo.name]) // Only include logos where the url_source matches
          .map((logo) => ({
            ...logo,
            url: urlMapping[logo.name], // Add the URL from the API data
          }));

        // Set the filtered logos with URLs as the state
        console.log("filtered logos", filteredLogos);
        setLogosWithUrls(filteredLogos);
      } else {
        console.error("No data or data is not an array");
      }
    };

    fetchLogosWithUrls();
  }, []);

  return (
    <div className="bg-white p-4 md:p-6 rounded-xl w-full">
      <div className="flex items-center justify-between mb-4 md:mb-6">
        <p className="text-lg md:text-xl font-semibold text-[#3A3A3A]">
          View Active Listings
        </p>
      </div>

      {/* <div className="flex flex-wrap items-center justify-between mb-4 md:mb-6 space-x-2 md:space-x-4">
        {logosWithUrls.length > 0 ? (
          logosWithUrls.map((logo, index) => (
            <div key={index} className="flex-1 mb-2 md:mb-0">
              <img
                src={logo.imageUrl}
                alt={`${logo.name} Logo`}
                onClick={() => {
                  window.open(logo.url, "_blank"); // Open URL in a new tab
                }}
                className="h-16 w-32 hmd:h-12 object-contain  cursor-pointer"
              />
            </div>
          ))
        ) : (
          <p>No logos available</p>
        )}
      </div> */}
      <div className="flex flex-wrap items-center justify-between mb-4 md:mb-6 space-x-2 md:space-x-4">
  {availableLogos.length > 0 ? (
    availableLogos.map((logo, index) => {
      // Find if this logo has a matching URL in logosWithUrls
      const logoWithUrl = logosWithUrls.find(item => item.name === logo.name);

      return (
        <div key={index} className="flex-1 mb-2 md:mb-0">
          <img
            src={logo?.imageUrl}  // Always from hardcoded logos
            alt={`${logo?.name} Logo`}
            onClick={() => {
              if (logoWithUrl?.url) {
                window.open(logoWithUrl?.url, "_blank"); // Open URL if it exists
              }
            }}
            className={`w-32 h-16 object-contain cursor-pointer ${
              !logoWithUrl?.url ? "filter grayscale" : ""
            }`}  // Apply greyscale if no URL is present
          />
        </div>
      );
    })
  ) : (
    <p>No logos available</p>
  )}
</div>

      <div className="mb-4 md:mb-6">
        <h3 className="text-yellow-500 font-bold text-lg md:text-base">
          Where we list
        </h3>
        <p className="text-gray-600 text-sm md:text-base leading-relaxed">
          We list our cars across all major automotive marketplaces, including
          exclusive dealer-only platforms, giving your car maximum exposure. The
          listing performance data provides insights into how your listing is
          performing and how well your car is priced.
        </p>
      </div>
    </div>
  );
};

export default ListingLogos;
