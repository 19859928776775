import React, { useState, useEffect } from "react";
import {
  requestOTP,
  verifyOTP,
} from "../../../services/authServies/authService";
import { saveToLocalStorage } from "../../../utils/localStorageUtil.ts";
import { useNavigate } from "react-router-dom";
import { Button, Input, Link } from "@nextui-org/react";
import { toast } from "sonner";
import { updateIntercomUser } from "../../../utils/intercomUtils";

const SignupLeft = ({
  isSignIn,
  setIsSignIn,
  vehicleId,
}: {
  isSignIn: boolean;
  setIsSignIn: React.Dispatch<React.SetStateAction<boolean>>;
  vehicleId: string | null;
}) => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>(""); // New email state
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [countryCode] = useState<string>("+61"); // Default country code set to +61
  const [otp, setOtp] = useState<string>("");
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [timer, setTimer] = useState<number>(0);
  const [isRequestingOTP, setIsRequestingOTP] = useState<boolean>(false);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const validateInputs = () => {
    const newErrors: { [key: string]: string } = {};
    if (!firstName) newErrors.name = "First Name is required";
    if (!email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Email is invalid";
    if (!phoneNumber) newErrors.phoneNumber = "Phone number is required";
    else if (!/^\d+$/.test(phoneNumber))
      newErrors.phoneNumber = "Phone number must be numeric";
    else if (phoneNumber.length !== 10)
      newErrors.phoneNumber = "Phone number must be of 10 digits";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRequestOTP = async () => {
    if (timer > 0) {
      toast.error(
        `Please wait ${timer} seconds before requesting another OTP.`
      );
      return;
    }

    if (!validateInputs()) return;

    setIsRequestingOTP(true);

    try {
      let name = firstName + " " + lastName;
      await requestOTP(phoneNumber, countryCode, name, email); // Include email
      setOtpSent(true);
      toast.success("OTP sent successfully, it will expire in 5 minutes.");
      setTimer(300); // Set timer for 2 minutes (120 seconds)
    } catch (error: any) {
      setIsRequestingOTP(false);
      if (error.response && error.response.status === 400) {
        if (error.response.data.error === "Email already exists") {
          setError("Email already exists");
          toast.error("Email already exists, please login");
          // setIsSignIn(true);
        } else if (error.response.data.error === "User already exists") {
          setError("User already exists");
          toast.error("Phone Number already exists, please login");
          // setIsSignIn(true);
        } else {
          setError(error.response.data.error);
        }
      } else {
        setError("Failed to send OTP");
      }
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const response = await verifyOTP(phoneNumber, otp, navigate);
      await saveToLocalStorage("onewheel_user", JSON.stringify(response));

      // Update Intercom user data
      updateIntercomUser({
        id: response.user.id,
        name: response.user.name,
        email: response.user.email,
      });
      if (vehicleId) {
        navigate(`/vehicle-onboarding?vehicleId=${vehicleId}`);
      } else {
        navigate("/vehicle-onboarding");
      }
    } catch (error: any) {
      setError((error as Error).message);
    }
  };

  return (
    <>
      <h1>Create your account</h1>
      <p>Please sign up to continue.</p>
      <div className="form-div-options">
        <div className="form-div-options-item">
          <div className="input-item">
            <p>Enter Your Name</p>
            <div className="w-full flex items-center">
            <Input
              type="text"
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={errors.name ? "input-error" : ""}
            />
            <Input
              type="text"
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="pt-0 pb-0 pl-1 pr-1"
            />
            </div>
            {errors.name && <p className="error-text">{errors.name}.</p>}
          </div>
          <div className="input-item">
            <p>Enter your email</p>
            <Input
              type="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={errors.email ? "input-error" : ""}
            />
            {errors.email && <p className="error-text">{errors.email}.</p>}
          </div>
          <div className="input-item">
            <p>Enter your phone number</p>
            <Input
              type="text"
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className={errors.phoneNumber ? "input-error" : ""}
            />
            {errors.phoneNumber && (
              <p className="error-text">{errors.phoneNumber}.</p>
            )}
          </div>

          {otpSent && (
            <div className="input-item">
              <p>Enter the OTP sent to your phone number</p>
              <Input
                type="text"
                label="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className={errors.otp ? "input-error" : ""}
              />
              {errors.otp && <p className="error-text">{errors.otp}.</p>}
              <p className="otp-expire mt-3 text-sm">
                OTP will expire in: {timer} seconds
              </p>
            </div>
          )}
          {error && <p className="error">{error}</p>}
          <div className="button form-div-submit">
            {!otpSent ? (
              <Button
                className="bg-[#FFD700]"
                onClick={handleRequestOTP}
                disabled={isRequestingOTP}
              >
                {isRequestingOTP ? "Requesting..." : "Sign up"}
              </Button>
            ) : (
              <Button className="bg-[#FFD700]" onClick={handleVerifyOTP}>
                Verify OTP
              </Button>
            )}
          </div>
          <p className="text-center text-small mt-1">
            Already have an account?&nbsp;
            <Link
              onClick={() => setIsSignIn(!isSignIn)}
              className="cursor-link text-[#ff8802]"
            >
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default SignupLeft;
