import React, { useEffect, useState } from "react";
import { Input, Select, SelectItem } from "@nextui-org/react";
import { cn } from "../../../utils/cn";
import Logo from "../../../assets/Icons/logo.svg";

export type CompanyInformationFormProps =
  React.HTMLAttributes<HTMLFormElement> & {
    setFormData: React.Dispatch<React.SetStateAction<any>>;
    formData: any;
    validationErrors: any;
    vehicleDetails: any;
    apiError: string | null;
  };

const ValueDetails = React.forwardRef<
  HTMLFormElement,
  CompanyInformationFormProps
>(
  (
    {
      className,
      setFormData,
      formData,
      validationErrors,
      vehicleDetails,
      apiError,
      ...props
    },
    ref
  ) => {
    const fields = [
      {
        label: "Eg. 1ABC123",
        name: "numberPlate",
        type: "text",
        placeholder: "Enter Rego Number",
        value: formData.numberPlate,
      },
      {
        label: "State",
        name: "location",
        type: "select",
        options: ["ACT", "NT", "NSW", "QLD", "SA", "TAS", "VIC", "WA"],
        placeholder: "Select your vehicle registration state",
        value: formData.location,
      },
      {
        label: "Eg. 120,000",
        name: "km",
        type: "text",
        placeholder: "Enter kms driven",
        value: formData.km,
      },
    ];

    console.log("formData", formData);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1025);

    useEffect(() => {
      const handleResize = () => {
        setIsMobileView(window.innerWidth < 1025);
      };

      window.addEventListener("resize", handleResize);

      // Clean up the event listener
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
      <>
        <div className="form-logo">
          <img src={Logo} alt="Logo" />
        </div>
        {isMobileView && (vehicleDetails || formData?.vehicleName) && (
          <div className="bg-[#ff8800] rounded-md mt-4">
            <div className="snapshot">
              <h1>{formData?.vehicleName}</h1>
              <h2>
                {formData?.location} | {formData?.numberPlate} | {formData?.km}{" "}
                km
              </h2>
            </div>
          </div>
        )}

        <div className={cn("grid grid-cols-2 flex-col gap-4 py-8", className)}>
          {fields.map((field: any) => (
            <div
              key={field.name}
              className="step1-input col-span-2 sm:col-span-1"
            >
              <label className="block text-sm font-medium">
                {field.placeholder}
              </label>
              {field.type === "select" ? (
                <Select
                  label={field.label}
                  size="sm"
                  style={{
                    maxHeight: "1.1rem",
                  }}
                  value={formData[field.name] || field.options[0]}
                  onChange={(value) => {
                    console.log(value.target.value);

                    setFormData({
                      ...formData,
                      [field.name]: value.target.value,
                    });
                  }}
                  className="mt-1 block w-full"
                >
                  {field.options?.map((option: any) => (
                    <SelectItem key={option} value={option}>
                      {option}
                    </SelectItem>
                  ))}
                </Select>
              ) : (
                <Input
                  type={field.type}
                  label={field.label}
                  value={formData[field.name] || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, [field.name]: e.target.value })
                  }
                  required
                  className="mt-1 block w-full"
                />
              )}
              {validationErrors[field.name] && (
                <span className="text-red-500">
                  {validationErrors[field.name]}
                </span>
              )}
            </div>
          ))}
          {apiError && (
            <div className="col-span-2">
              <span className="text-red-500">{apiError}</span>
            </div>
          )}
        </div>
      </>
    );
  }
);

ValueDetails.displayName = "ValueDetails";

export default ValueDetails;
