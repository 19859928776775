import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "@nextui-org/react";
import { InlineShareButtons } from "sharethis-reactjs";
import { Copy } from "lucide-react";
import { toast } from "sonner";


interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  listingID: number;
}

const ShareModal: React.FC<ShareModalProps> = ({
  isOpen,
  onClose,
  listingID,
}) => {
  const [actualUrl,setActualUrl] = useState("");
  const [url, setUrl] = useState("");
  const [copied, setCopied] = useState(false);
  const defaultUrl = "https://1wheel.com.au/";

  const handleCopy = () => {
    if (actualUrl) {
      navigator.clipboard.writeText(actualUrl);
      toast.success("Link copied successfully.");
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
    }
  };
  const getOneWheelListingUrl = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/links/${listingID}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      // console.log("what is data here",data);
      const newUrlObject = data?.links?.filter((item: any) => {
        return item?.url_source === "oneWheel";
      });
      console.log("new uri object",newUrlObject[0]?.url);
      let urlRaw = newUrlObject?.[0]?.url;
      // let urlRaw = "https://www.figma.com/design/voRQGg6uX2cm5VsAYjmOoD/1Wheel-Website-Branding?node-id=0-1&node-type=canvas&t=bz3nt5onSji7D8dm-0";
      setActualUrl(urlRaw);
      if(urlRaw.length > 28)
      {
        urlRaw = urlRaw.slice(0,28) + "...";
      }
      setUrl(urlRaw);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    const fetchUrl = async () => {
      await getOneWheelListingUrl();
    };
    fetchUrl();
  }, []);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent
        style={{
          width: "280px",
          maxWidth: "90%",
          paddingBottom: "10px",
          position: "absolute",
          top: "40%",
        }}
      >
        <ModalHeader>
          <h2>Share this Listing</h2>
        </ModalHeader>
        <ModalBody>
          <InlineShareButtons
            config={{
              alignment: "center",
              color: "social",
              enabled: true,
              font_size: 16,
              labels: "cta",
              language: "en",
              networks: [
                "whatsapp",
                "linkedin",
                "messenger",
                "facebook",
                "twitter",
              ],
              padding: 12,
              radius: 4,
              show_total: false,
              size: 40,
              url: url,
              image: "https://app.1wheel.com.au/thumbnail.png",
              description:
                "Discover the best deals on used cars in Australia with 1Wheel. Browse our listings, compare prices, and find your next car today!",
              title: "Sell Your Car with 1Wheel",
              message:
                "Check 1wheel for the best deals on used cars in Australia",
              subject: "Exciting Deals on Used Cars at 1Wheel",
              username: "1Wheel",
            }}
          />
          <div style={{ marginTop: "10px", textAlign: "left" }}>
        <p style={{ marginBottom: "5px" }}>Copy link</p>
        <div className="copy-container">
          <span
            style={{
              color: url ? "black" : "gray", // Gray out when there's no URL
              textDecoration: url ? "none" : "line-through", // Optional: strike through if no URL
            }}
          >
            {url || defaultUrl}
          </span>
          <Copy
            size={14}
            style={{
              cursor: url ? "pointer" : "not-allowed", // Disable clicking when no valid URL
              marginLeft: "10px",
            }}
            onClick={url ? handleCopy : undefined} // Only allow copy if URL exists
          />
        </div>
      </div>
              {/* <Input
              readOnly
              value={url}
              style={{ width: "100%", marginBottom: "10px" }}
              aria-label="Listing URL"
            />
            <Button onClick={handleCopy} >
              {copied ? "Copied!" : "Copy URL"}
            </Button> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
