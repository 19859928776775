import React from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";

interface LowerPriceModalProps {
  isOpen: boolean;
  onClose: () => void;
  newPrice: string | number;
  setNewPrice: React.Dispatch<React.SetStateAction<string | number>>;
  handleEditPrice: () => void;
  isLoading: boolean;
}

const LowerListingPriceModal: React.FC<LowerPriceModalProps> = ({
  isOpen,
  onClose,
  newPrice,
  setNewPrice,
  handleEditPrice,
  isLoading,
}) => {
  // Convert newPrice to an absolute integer for display
  const displayValue = Math.abs(parseInt(newPrice.toString(), 10));

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      style={{
        position: "absolute",
        top: "20%",
        width: "90%",
      }}
    >
      <ModalContent>
        <ModalHeader>Lower Listing Price</ModalHeader>
        <ModalBody>
          <div className="mb-4">
            <label
              htmlFor="newPrice"
              className="block text-sm font-medium text-gray-700"
            >
              New Listing Price
            </label>
            <input
              type="number"
              id="newPrice"
              value={displayValue}
              onChange={(e) => {
                // Ensure the input value is a non-negative integer
                const value = parseInt(e.target.value, 10);
                setNewPrice(isNaN(value) ? 0 : Math.abs(value));
              }}
              min="0" // Prevents negative numbers
              step="1" // Ensures only whole numbers can be entered
              style={{
                borderColor: "#eee",
                borderWidth: "1.1px",
                borderRadius: "5px",
                padding: "0.5rem",
                width: "100%",
              }}
              required
            />
          </div>
        </ModalBody>
        <ModalFooter>
          {isLoading ? (
            <Button fullWidth isLoading className="bg-[#ff9800] text-white">
              Loading
            </Button>
          ) : (
            <Button
              fullWidth
              onClick={() => {
                handleEditPrice();
              }}
              className="bg-[#ff9800] text-white"
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LowerListingPriceModal;
