import axios from "axios";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Tooltip,
} from "@nextui-org/react";
import ToolTip from "../../../assets/Icons/ToolTip.svg";
import { MdOutlineDelete } from "react-icons/md";
import CarPlaceholder from "../../../assets/Images/CarPlaceholder/frontImage.png";
import {
  BadgeDollarSign,
  ChevronLeft,
  ChevronRight,
  ChevronsDown,
  ClockArrowUp,
  Edit,
  Rocket,
  Upload,
} from "lucide-react";
import RequestDealerPriceModal from "../../../modals/RequestDealerPriceModal";
import ShareModal from "./ShareModal";
// import LowerPriceModal from "./LowerListingPriceModal";
import LowerListingPriceModal from "./LowerListingPriceModal";
import LowerReservePriceModal from "./LowerReservePriceModal";

const CarImage3 = ({
  listingDetails,
  setListingDetails,
  profilecompleteUpload15,
}: {
  listingDetails: any;
  setListingDetails: any;
  profilecompleteUpload15: boolean;
}) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [viewAllOpen, setViewAllOpen] = useState(false);

  useEffect(() => {
    if (profilecompleteUpload15) {
      // openModa
      onOpen();
    } else {
    }
  }, [profilecompleteUpload15, onOpen]);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isDeletedImage, setIsDeletedImage] = useState(false);
  const [previews, setPreviews] = useState<string[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const [lowerListingPriceModalOpen, setLowerListingPriceModalOpen] =
    useState(false);
  const [lowerReservePriceModalOpen, setLowerReservePriceModalOpen] =
    useState(false);
  const [requestDealerPriceModalOpen, setRequestDealerPriceModalOpen] =
    useState(false);
  const [lowerReservePrice, setLowerReservePrice] = useState<string | number>(
    listingDetails?.vehicle[0].reserve_price || ""
  );
  const [newListingPrice, setNewListingPrice] = useState<string | number>(
    listingDetails?.price || ""
  );
  const [timeAtCurrentPrice, setTimeAtCurrentPrice] = useState<
    string | number
  >();
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const existingImages = listingDetails?.images[0]?.image_urls || [];

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setSelectedFiles(files);

      // Create previews
      const newPreviews = files.map((file) => URL.createObjectURL(file));
      setPreviews(newPreviews);
    }
  };

  const uploadImages = async () => {
    if (selectedFiles.length === 0) {
      setUploadStatus("Please select at least one image.");
      return;
    }

    // Validate that all selected files are images or videos
    const validTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "video/mp4",
      "video/quicktime",
    ];
    const invalidFiles = selectedFiles.filter(
      (file) => !validTypes.includes(file.type)
    );

    if (invalidFiles.length > 0) {
      setUploadStatus(
        "Only images (JPEG, PNG, GIF) or videos (MP4, MOV) are allowed."
      );
      return;
    }

    setUploading(true);
    setUploadStatus("Uploading...");

    const formData = new FormData();
    console.log("listing details jatin", listingDetails?.vehicle[0]?.id);
    // formData.append("listingId", listingDetails?.id);
    formData.append("vehicleId", listingDetails?.vehicle[0]?.id);
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    console.log("formData", formData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/upload-vehicle-image`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const { message, totalImages, newImagesAdded } = response.data;
      setUploadStatus(message);

      // Generate new image URLs based on the uploaded files
      const newImageUrls = selectedFiles.map((file) =>
        URL.createObjectURL(file)
      );

      // Update listingDetails with new images
      setListingDetails((prevDetails: any) => {
        const updatedImages = [
          ...(prevDetails.images[0]?.image_urls || []),
          ...newImageUrls,
        ];
        return {
          ...prevDetails,
          images: [{ image_urls: updatedImages }],
        };
      });

      // Clear selected files and previews after successful upload
      setSelectedFiles([]);
      setPreviews([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      console.log(
        `Successfully added ${newImagesAdded} new images. Total images: ${totalImages}`
      );
    } catch (error) {
      console.error("Upload failed:", error);
      setUploadStatus("Failed to upload images. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const renderImageOrPlaceholder = (index: number) => {
    const totalImages = existingImages.length;

    if (index < 4) {
      // Show images for first 4 slots
      return (
        <img
          src={existingImages[index] || CarPlaceholder}
          alt={`Vehicle ${index + 1}`}
          className="w-full h-full object-contain rounded"
        />
      );
    } else if (index === 4) {
      if (totalImages > 5) {
        // Show 5th image with overlay when there are 6 or more images
        return (
          <div className="relative w-full h-full">
            <img
              src={existingImages[4]}
              alt={`Vehicle 5`}
              className="w-full h-full object-contain rounded"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
              <span className="text-white font-bold mb-2  text-xs md:text-md">
                +{totalImages - 5} more
              </span>
              {/* <Button
                onPress={onOpen}
                size="sm"
                color="primary"
                className="px-2 py-1"
              >
                <Upload size={14} className="mr-1" />
                Upload
              </Button> */}
              <Button
                onPress={() => setViewAllOpen(true)}
                size="sm"
                color="primary"
                className="px-0 py-1 md:px-2 md:py-1  bg-[#ff9800]  text-white"
              >
                View All
              </Button>
            </div>
          </div>
        );
      } else if (totalImages === 5) {
        // Show 5th image without overlay when there are exactly 5 images
        return (
          <img
            src={existingImages[4]}
            alt={`Vehicle 5`}
            className="w-full h-full object-contain rounded"
          />
        );
      } else {
        // Show upload button when there are fewer than 5 images
        return (
          <div className="relative w-full h-full">
            <img
              src={CarPlaceholder}
              alt={`Placeholder ${index + 1}`}
              className="w-full h-full object-contain rounded opacity-50"
            />
            <Button
              onPress={onOpen}
              size="sm"
              className="absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            >
              Upload Image
            </Button>
          </div>
        );
      }
    }
  };
  const [lowerPrice, setLowerPrice] = useState<string | number>(
    listingDetails?.vehicle[0].reserve_price || ""
  );

  const getTimeDifference = (startTime: string) => {
    const startDate = new Date(startTime);
    const now = new Date();
    const diffInMilliseconds = now.getTime() - startDate.getTime();

    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    if (diffInMinutes < 60) {
      return `${diffInMinutes} min ago`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} hrs ago`;
    }

    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    return `${diffInDays} days`;
  };

  const handleLowerReservePrice = async () => {
    if (typeof listingDetails?.id !== "number" || !lowerReservePrice) return;

    try {
      setIsLoading(true);
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/updatereservePrice`,
        {
          newreserveprice: lowerReservePrice,
          vehicleId: listingDetails?.vehicle[0].id,
        }
      );
      console.log("what are the listing details", listingDetails);
      setListingDetails((prevDetails: any) => ({
        ...prevDetails,
        reserve_price: lowerReservePrice,
      }));
      setIsLoading(false);
      setLowerReservePriceModalOpen(false);
    } catch (error) {
      console.error("Error lowering reserve price:", error);
    }
  };

  const listingDuration = getTimeDifference(listingDetails?.created_at);

  const handleEditPrice = async () => {
    if (typeof listingDetails?.id !== "number" || !newListingPrice) return;
    let lesserThenReserve = false;
    let rp = listingDetails?.vehicle[0]?.reserve_price;
    if (newListingPrice < rp) {
      lesserThenReserve = true;
      rp = newListingPrice;
    }
    try {
      setIsLoading(true);
      const currentTime = new Date().toISOString();
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/updateListing/${listingDetails?.id}`,
        {
          price: newListingPrice,
          reserve_price: rp,
        }
      );
      setListingDetails((prevDetails: any) => ({
        ...prevDetails,
        current_price_time: currentTime,
        price: newListingPrice,
        vehicle: [
          {
            ...prevDetails.vehicle[0],
            reserve_price: rp, // Update the reserve price in the state
          },
          ...prevDetails.vehicle.slice(1),
        ],
      }));
      setIsLoading(false);
      setLowerListingPriceModalOpen(false);
    } catch (error) {
      console.error("Error updating price:", error);
    }
  };

  const handleUpdateImages = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/update-vehicle-images/${listingDetails?.id}`,
        {
          imageUrls: listingDetails?.images[0].image_urls,
        }
      );

      setUploadStatus("Images updated successfully.");
      setViewAllOpen(false);
      setUploadStatus("");
    } catch (error) {
      console.error("Failed to update images:", error);
      setUploadStatus("Failed to update images.");
    }
  };

  const handleRemoveImage = (index: number) => {
    setListingDetails((prevDetails: any) => {
      const updatedImages = prevDetails.images[0]?.image_urls.filter(
        (_: string, i: number) => i !== index
      );
      return {
        ...prevDetails,
        images: [{ image_urls: updatedImages }],
      };
    });
    setIsDeletedImage(true);
  };
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? existingImages.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === existingImages.length - 1 ? 0 : prevIndex + 1
    );
  };
  const [isMobile, setIsMobile] = useState(false);

  // Check if the screen size is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Set initial value
    handleResize();

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (listingDetails?.current_price_time) {
      const timeDifference = getTimeDifference(
        listingDetails?.current_price_time
      );
      setTimeAtCurrentPrice(timeDifference);
    }
  }, [listingDetails?.current_price_time]);

  return (
    <div className="seller-dashboard-top-left-card seller-card">
      <div className="seller-dashboard-top-left-card-img">
        <div className="grid gap-4">
          <div className="row-span-1 w-full h-64 seller-dashboard-top-left-card-img-main relative">
            <div className="main-img-details">
              <div className="main-img-details-text">
                <div className="main-img-details-text-details">
                  <h1>{listingDetails?.vehicle[0].vehicle_name}</h1>
                  {!isMobile && (
                    <p>
                      {listingDetails?.vehicle[0].number_plate} |{" "}
                      {listingDetails?.vehicle[0].location}
                    </p>
                  )}
                </div>
              </div>
              <div className="main-img-details-share flex items-center">
                <Button
                  variant="bordered"
                  size="sm"
                  style={{
                    border: "1.1px solid #ff9800",
                    transform: "scale(0.85)",
                    color: "#ff9800",
                  }}
                  onClick={() => setShareModalOpen(true)}
                >
                  <Upload size={14} />
                  Share
                </Button>
              </div>
            </div>
            {/* Main Image Carousel */}
            {existingImages[currentImageIndex]?.endsWith(".mp4") ||
            existingImages[currentImageIndex]?.endsWith(".mov") ? (
              <video
                src={existingImages[currentImageIndex] || CarPlaceholder}
                controls
                className="w-full h-full object-contain rounded"
              >
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={existingImages[currentImageIndex] || CarPlaceholder}
                alt={`Vehicle ${currentImageIndex + 1}`}
                className="w-full h-full object-contain rounded"
              />
            )}

            {/* Carousel Controls */}
            {existingImages.length > 1 && (
              <div className="absolute top-1/2 w-full px-2 flex justify-between items-center">
                <div
                  onClick={handlePrevImage}
                  className="cursor-pointer bg-white bg-opacity-75 p-1 rounded-full shadow"
                >
                  <ChevronLeft size={20} />
                </div>
                <div
                  onClick={handleNextImage}
                  className="cursor-pointer bg-white bg-opacity-75 p-1 rounded-full shadow"
                >
                  <ChevronRight size={20} />
                </div>
              </div>
            )}
            {existingImages?.length == 0 && (
              <div
                className="absolute top-[50%]  right-0 bottom-0 bg-red flex justify-center items-center h-[30px] w-[100px]"
                style={{ left: "calc(50% - 50px)" }}
              >
                <Button
                  variant="solid"
                  size="sm"
                  style={{
                    backgroundColor: "#ff9800",
                    transform: "scale(0.85)",
                    color: "#fff",
                  }}
                  onPress={onOpen}
                >
                  Upload More
                </Button>
              </div>
            )}
          </div>
          <div className="flex gap-4 w-full h-[7rem]">
            {[1, 2, 3, 4].map((index) => (
              <div key={index} className="w-full h-[6rem]">
                {renderImageOrPlaceholder(index)}
              </div>
            ))}
          </div>
        </div>

        <Modal
          isOpen={isOpen}
          onOpenChange={(open) => {
            if (!open && isDeletedImage) {
              handleUpdateImages();
              setIsDeletedImage(false); // Call your custom function when modal closes
            }
            onOpenChange(); // This will handle the default behavior of opening and closing
          }}
          placement={"center"}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  Upload Vehicle Images
                </ModalHeader>

                <ModalBody>
                  <input
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                    ref={fileInputRef}
                  />
                  <div className="mt-4 grid grid-cols-3 gap-4">
                    {previews.map((preview, index) => (
                      <img
                        key={index}
                        src={preview}
                        alt={`Preview ${index + 1}`}
                        className="w-full h-24 object-contain rounded"
                      />
                    ))}
                  </div>
                  <div className="grid grid-cols-3 gap-4 overflow-y-scroll max-h-[250px] scrollbar-hide">
                    {existingImages.map((url: string, index: number) => (
                      <div key={index} className="relative">
                        <img
                          src={url}
                          alt={`Image ${index + 1}`}
                          className="w-full h-24 object-contain rounded"
                        />
                        <Button
                          onPress={() => handleRemoveImage(index)}
                          size="sm"
                          isIconOnly
                          radius="md"
                          className="bg-white shadow-xl absolute top-[-7px] right-[-10px]"
                        >
                          <MdOutlineDelete
                            style={{
                              width: "16px",
                              height: "16px",
                              color: "red",
                            }}
                          />
                        </Button>
                      </div>
                    ))}
                  </div>
                  {uploadStatus && (
                    <p
                      className={`mt-2 text-sm ${
                        uploadStatus.includes("success")
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {uploadStatus}
                    </p>
                  )}
                  <p className="w-full text-[#ff9500] font-thin text-xs shadow-inherit">
                    Please try and upload a minimum of 15 pictures for better
                    responses.
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="light" onPress={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="bg-[#ff9800] font-semibold text-white"
                    onPress={uploadImages}
                    disabled={uploading}
                  >
                    {uploading ? "Uploading..." : "Upload Images"}
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>

        <Modal
          isOpen={viewAllOpen}
          onOpenChange={(open) => {
            setViewAllOpen(open);
            if (!open) {
              setUploadStatus("");
            }
          }}
          placement={"center"}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader>All Images</ModalHeader>
                <ModalBody>
                  <input
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 cursor-pointer"
                    ref={fileInputRef}
                  />
                  {uploadStatus && (
                    <p
                      className={`mt-2 text-sm ${
                        uploadStatus.includes("success")
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {uploadStatus}
                    </p>
                  )}
                  <div className="grid grid-cols-3 gap-4 overflow-y-scroll max-h-[350px] scrollbar-hide">
                    {existingImages.map((url: string, index: number) => {
                      // Check if the file is a video based on the URL extension
                      const isVideo =
                        url.endsWith(".mp4") || url.endsWith(".mov");

                      return (
                        <div key={index} className="relative">
                          {isVideo ? (
                            <video
                              src={url}
                              controls
                              className="w-full h-24 object-contain rounded"
                            >
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={url}
                              alt={`Media ${index + 1}`}
                              className="w-full h-24 object-contain rounded"
                            />
                          )}

                          <Button
                            onPress={() => handleRemoveImage(index)}
                            size="sm"
                            isIconOnly
                            radius="md"
                            className="bg-white shadow-xl absolute top-[-7px] right-[-10px]"
                          >
                            <MdOutlineDelete
                              style={{
                                width: "16px",
                                height: "16px",
                                color: "red",
                              }}
                            />
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                  <p className="w-full text-[#ff9500] font-thin text-xs shadow-inherit">
                    Please try and upload a minimum of 15 pictures for better
                    responses.
                  </p>
                </ModalBody>
                <ModalFooter>
                  {uploading ? (
                    <Button
                      color={"primary"}
                      className="font-semibold text-white"
                      isLoading
                    >
                      Uploading
                    </Button>
                  ) : (
                    <Button
                      color={"primary"}
                      className="font-semibold text-white"
                      onPress={uploadImages}
                      disabled={uploading}
                    >
                      Upload Image
                    </Button>
                  )}

                  <Button
                    className="bg-[#ff9800] font-semibold text-white"
                    onPress={handleUpdateImages}
                  >
                    Save Changes
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>

      <div className="seller-dashboard-top-left-card-details">
        <div className="seller-dashboard-top-left-card-details-top">
          <div className="flex justify-between items-center mt-2">
            <h2 className="listing-price">Current Listing Price</h2>
            <h2 className="listing-value">
              $
              {new Intl.NumberFormat("en-US", {
                style: "decimal",
                maximumFractionDigits: 0,
              }).format(Math.floor(listingDetails?.price))}
            </h2>
          </div>
          <div className="seller-dashboard-top-left-card-details-top-details mt-3">
            <div className="seller-dashboard-top-left-card-details-top-label">
              <ClockArrowUp size={14} color="grey" strokeWidth={2} />
              Average time to sell
              <Tooltip
                showArrow={true}
                content={
                  <div className="px-1 py-1 w-52 flex flex-wrap">
                    <div className="text-tiny">
                    The average time it has taken your cars make and model to sell over the last 90 days of sales data. 
                    </div>
                  </div>
                }
              >
                <img src={ToolTip} alt="Tooltip" />
              </Tooltip>
            </div>
            <div className="seller-dashboard-top-left-card-details-top-value">
              {parseFloat(
                listingDetails?.valueAnalyser?.[0]?.avg_sell_time ?? "0"
              ).toFixed(2)}
              &nbsp;days
            </div>
          </div>
          <div className="seller-dashboard-top-left-card-details-top-details">
            <div className="seller-dashboard-top-left-card-details-top-label">
              <BadgeDollarSign size={14} color="grey" strokeWidth={2} />
              Reserve Price
              <Tooltip
                showArrow={true}
                content={
                  <div className="px-1 py-1 w-52 flex flex-wrap">
                    <div className="text-tiny">
                    The lowest amount you would accept for your vehicle. NOTE: we will not present you with any offers below this amount. 
                    </div>
                  </div>
                }
              >
                <img src={ToolTip} alt="Tooltip" />
              </Tooltip>
            </div>
            <div className="seller-dashboard-top-left-card-details-top-value">
              $
              {new Intl.NumberFormat("en-US", {
                style: "decimal",
                maximumFractionDigits: 0,
              }).format(Math.floor(listingDetails?.reserve_price))}
            </div>
          </div>
          <div className="seller-dashboard-top-left-card-details-top-details">
            <div className="seller-dashboard-top-left-card-details-top-label">
              <ClockArrowUp size={14} color="grey" strokeWidth={2} />
              Time at current price
              <Tooltip
                showArrow={true}
                content={
                  <div className="px-1 py-1 w-52 flex flex-wrap">
                    <div className="text-tiny">
                    The number of days you car has been at current listing price.
                    </div>
                  </div>
                }
              >
                <img src={ToolTip} alt="Tooltip" />
              </Tooltip>
            </div>
            <div className="seller-dashboard-top-left-card-details-top-value green-colour">
              {timeAtCurrentPrice}
            </div>
          </div>
          <div className="seller-dashboard-top-left-card-details-top-details">
            <div className="seller-dashboard-top-left-card-details-top-label">
              <ClockArrowUp size={14} color="grey" strokeWidth={2} />
              Listing Duration
              <Tooltip
                showArrow={true}
                content={
                  <div className="px-1 py-1 w-52 flex flex-wrap">
                    <div className="text-tiny">
                    The total number of days your listing has been live.
                    </div>
                  </div>
                }
              >
                <img src={ToolTip} alt="Tooltip" />
              </Tooltip>
            </div>
            <div className="seller-dashboard-top-left-card-details-top-value green-colour">
              {listingDuration}
            </div>
          </div>
        </div>
        <div className="seller-dashboard-top-left-card-details-bottom mt-4">
          <Button
            className="bg-[#fefefe] text-black border-[#2c2c2c] border-1"
            style={{ width: "100%" }}
            variant="flat"
            onClick={() => setLowerListingPriceModalOpen(true)}
          >
            <Edit size={14} />
            Lower Listing Price
          </Button>
          <Button
            className="bg-[#FFE8CE] text-black border-[#ff9800] border-1"
            style={{ width: "100%" }}
            variant="flat"
            onClick={() => setLowerReservePriceModalOpen(true)}
          >
            <ChevronsDown size={14} />
            Lower Reserve Price
          </Button>

          <Button
            className="bg-[#ff9800] text-white"
            style={{ width: "100%" }}
            onClick={() => setRequestDealerPriceModalOpen(true)}
          >
            <Rocket size={14} />
            Request Dealer Price
          </Button>
        </div>
      </div>

      <LowerListingPriceModal
        isOpen={lowerListingPriceModalOpen}
        onClose={() => setLowerListingPriceModalOpen(false)}
        newPrice={newListingPrice}
        setNewPrice={setNewListingPrice}
        handleEditPrice={handleEditPrice}
        isLoading={isLoading}
      />
      <LowerReservePriceModal
        isOpen={lowerReservePriceModalOpen}
        onClose={() => setLowerReservePriceModalOpen(false)}
        lowerPrice={lowerReservePrice}
        setLowerPrice={setLowerReservePrice}
        handleLowerReservePrice={handleLowerReservePrice}
        isLoading={isLoading}
      />

      <ShareModal
        isOpen={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        listingID={listingDetails?.id}
      />

      <RequestDealerPriceModal
        isOpen={requestDealerPriceModalOpen}
        onClose={() => setRequestDealerPriceModalOpen(false)}
        listingId={listingDetails?.id}
        userId={listingDetails?.user_id}
      />
    </div>
  );
};

export default CarImage3;
