import { Input } from "@nextui-org/input";
import { Button } from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { FaRegCalendarCheck } from "react-icons/fa6";
import axios from "axios"; // Import Axios
import { useParams } from "react-router-dom"; // Import useParams
import Modal from "./MeetingModal";

interface UserDetailFormProps {
  finalSelectedData: any;
  vehicleData: any;
  location:any;
}

const UserDetailForm: React.FC<UserDetailFormProps> = ({
  finalSelectedData,
  vehicleData,
  location,
}) => {
  const { uniqueId } = useParams<{ uniqueId: string }>(); // Extract the unique link from the URL
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const firstNameRef = useRef<HTMLInputElement>(null);
  const [showSucessModal, setShowSucessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const validateForm = () => {
    let hasError = false;
    const newErrors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    };

    // Validate first name
    if (!firstName.trim()) {
      newErrors.firstName = "First Name is mandatory.";
      hasError = true;
    }

    // Validate last name
    if (!lastName.trim()) {
      newErrors.lastName = "Last Name is mandatory.";
      hasError = true;
    }

    // Validate email
    if (!email.trim()) {
      newErrors.email = "Email is mandatory.";
      hasError = true;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      newErrors.email = "Invalid email address.";
      hasError = true;
    }

    // Validate phone number
    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone Number is mandatory.";
      hasError = true;
    }

    setErrors(newErrors);
    return !hasError; // Return true if no errors
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      // Create the request URL using the base URL from the environment variable
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const requestUrl = `${baseUrl}/api/schedule-meeting/book/${uniqueId}`;

      // Prepare the data to be sent in the request body
      const requestData = {
        name: `${firstName} ${lastName}`,
        email,
        phone_number: phoneNumber,
        ...finalSelectedData, // Spread the finalSelectedData into the request body
      };

      try {
        setLoading(true);
        const response = await axios.post(requestUrl, requestData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        // Handle success (e.g., show a success message)
        setLoading(false);
        console.log("Booking successful:", response.data);
        setShowSucessModal(true);
      } catch (error) {
        // Handle error (e.g., show an error message)
        setLoading(false);
        console.error("Error scheduling viewing:", error);
        alert("There was an error scheduling your viewing. Please try again.");
      }
    }
  };
  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    });
    setShowSucessModal(false); // Reset success modal
    setLoading(false); // Reset loading
  };
  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
    resetForm();
  }, []);

  return (
    <div className="user-detail-form w-full lg:w-[450px] py-3 px-5 lg:py-14 lg:px-4 flex flex-col gap-5">
      <h2 className="mb-4 text-xl font-semibold">Enter Details</h2>

      {/* First Name Input */}
      <Input
        ref={firstNameRef}
        key="firstName"
        label="First Name*"
        labelPlacement="outside"
        placeholder="John"
        onChange={(e) => setFirstName(e.target.value)}
        className="border-gray-500 border-1 rounded-md"
      />
      {errors.firstName && (
        <p className="text-red-500 text-sm -mt-3">{errors.firstName}</p>
      )}

      {/* Last Name Input */}
      <Input
        key="lastName"
        label="Last Name*"
        labelPlacement="outside"
        placeholder="Doe"
        onChange={(e) => setLastName(e.target.value)}
        className="border-gray-500 border-1 rounded-md"
      />
      {errors.lastName && (
        <p className="text-red-500 text-sm -mt-3">{errors.lastName}</p>
      )}

      {/* Phone Number Input */}
      <Input
        key="phoneNumber"
        label="Mobile Number*"
        labelPlacement="outside"
        placeholder="+91 0400 123 123"
        onChange={(e) => setPhoneNumber(e.target.value)}
        className="border-gray-500 border-1 rounded-md"
      />
      {errors.phoneNumber && (
        <p className="text-red-500 text-sm -mt-3">{errors.phoneNumber}</p>
      )}

      {/* Email Input */}
      <Input
        key="email"
        label="Email Address*"
        labelPlacement="outside"
        placeholder="johndoe@gmail.com"
        onChange={(e) => setEmail(e.target.value)}
        className="border-gray-500 border-1 rounded-md"
      />
      {errors.email && (
        <p className="-mt-3 text-red-500 text-sm">{errors.email}</p>
      )}

      {/* Submit Button */}
      {loading ? (
        <Button className="text-white bg-[#ff9800] py-[25px] mt-2" isLoading>
          <FaRegCalendarCheck />
          Scheduling a View
        </Button>
      ) : (
        <Button
          className="text-white bg-[#ff9800] py-[25px] mt-2"
          onPress={handleSubmit}
        >
          <FaRegCalendarCheck />
          Schedule a Viewing
        </Button>
      )}

      {showSucessModal && (
        <Modal
          isOpen={showSucessModal}
          toggleModal={() => setShowSucessModal(false)}
          sellerName={vehicleData?.seller_name}
          vehicleName={vehicleData?.vehicle_name}
          vehicleLocation={vehicleData?.location}
          finalSelectedData={finalSelectedData}
          location={location}
        />
      )}
    </div>
  );
};

export default UserDetailForm;
