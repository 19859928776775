import React, { useCallback, useEffect, useState } from "react";
import { cn } from "../../../utils/cn";
import { Checkbox, Input, Radio, Slider, Textarea } from "@nextui-org/react";
import Logo from "../../../assets/Icons/logo.svg";
import "./new-form.css";

export type CompanyInformationFormProps =
  React.HTMLAttributes<HTMLFormElement> & {
    setFormData: React.Dispatch<React.SetStateAction<any>>;
    formData: any;
    validationErrors: any;
    vehicleDetails: any;
  };

const TellUsMore = React.forwardRef<
  HTMLFormElement,
  CompanyInformationFormProps
>(
  (
    {
      className,
      setFormData,
      formData,
      validationErrors,
      vehicleDetails,
      ...props
    },
    ref
  ) => {
    console.log("validations error in tell us",validationErrors);
    const fields = [
      {
        label:
          "Does your car have any damage, scratches or imperfections (ie: paint fade, stained seats, ripped upholstery, etc)? If so, list all below (put NIL if there is no damage or imperfections):",
        name: "damage",
        type: "text",
        placeholder: "Describe details",
      },
      {
        label:
          "Does your car have any non-factory accessories or modifications that make it unique? If so, provide as much details as possible below, including accessory/modification brand names where applicable (put NIL if there are no accessories or modifications):",
        name: "modifications",
        type: "text",
        placeholder: "Describe here",
      },
      {
        label: "Why are you selling your car?",
        name: "sellingReason",
        type: "text",
        placeholder: "Enter the reason for selling your car",
      },
      {
        label:
          "On a scale of 1-5, how would you rate the overall condition of your car?",
        name: "conditionRating",
        type: "slider",
        placeholder: "Rate the overall condition of your car",
      },
      {
        label:
          "Reserve price - what is the lowest offer you would consider? This helps ensure we don't bother you with offers lower than your expectation.",
        name: "reservePrice",
        type: "slider2",
        placeholder: "Enter your reserve price",
      },
      {
        label: "Which of the following best describes your desired outcome?",
        name: "desiredOutcome",
        type: "radio",
        options: [
          "A quick sale",
          "I would rather wait a little longer and get as much as I can for my car",
          "A happy medium between time to sell and sell price",
        ],
      },
      {
        label:
          "It is against our Terms Of Service to advertise your cars elsewhere while we are Marketing your Car.",
        name: "agreeToTerms",
        type: "checkbox",
      },
      {
        label: "You confirm ownership to the car you're listing",
        name: "confirmOwnership",
        type: "checkbox",
      },
      {
        label: "I agree to pay 1Wheel 3% of the sale value, charged only once my car is sold and transferred.",
        name: "agreeToPay",
        type: "checkbox",
      }
    ];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, type, checked, value } = e.target;
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    };

    const parseCurrencyValue = (value: string): number => {
      return parseInt(value?.replace(/[$,]/g, "")) || 0;
    };
    const calculateInitialSliderValue = useCallback(() => {
      const dealershipValue = parseInt(formData.dealership) || 0;
      const privateSaleValue = parseInt(formData.privateSale) || 0;
      return (dealershipValue + privateSaleValue) / 2;
    }, [formData.dealership, formData.privateSale]);

    const [value, setValue] = useState<number>(calculateInitialSliderValue());
    const [price2, setPrice2] = useState<string>(value.toString());
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1025);

    useEffect(() => {
      const handleResize = () => {
        setIsMobileView(window.innerWidth < 1025);
      };

      window.addEventListener("resize", handleResize);

      // Clean up the event listener
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    useEffect(() => {
      setValue(calculateInitialSliderValue());
    }, [calculateInitialSliderValue]);
    useEffect(() => {
      // Initialize formData with default values
      setFormData((prevData: any) => ({
        ...prevData,
        conditionRating: "3", // Set default value as a string
      }));
    }, []);

    useEffect(() => {
      // Set default reservePrice when the component mounts if it's not already set
      setFormData((prevData: any) => ({
        ...prevData,
        reservePrice: prevData.reservePrice || calculateInitialSliderValue().toString(),
      }));
    }, [calculateInitialSliderValue, setFormData]);

    useEffect(() => {
      setPrice2(value.toString());
    }, [value]);

    // const Component = ({
    //   value,
    //   setValue,
    //   field,
    // }: {
    //   value: number;
    //   field: any;
    //   setValue: React.Dispatch<React.SetStateAction<number>>;
    // }) => {
    //   const [inputValue, setInputValue] = useState<string>(value.toString());

    //   useEffect(() => {
    //     console.log("form data ha kya",formData);
    //     setInputValue(formData.reservePrice || "");
    //   }, [formData.reservePrice]);

    //   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newValue = e.target.value;
    //     setInputValue(newValue);
    //     const parsedValue = parseInt(newValue);
    //     if (!isNaN(parsedValue)) {
    //       setValue(parsedValue);
    //       setFormData((prev: any) => ({
    //         ...prev,
    //         reservePrice: parsedValue.toString(),
    //       }));
    //     }
    //   };

    //   return (
    //     <div className="flex gap-3" key={field.name}>
    //       <p className="w-[60%]">
    //         Reserve price - what is the lowest offer you would consider? This
    //         helps ensure we don't bother you with offers lower than your
    //         expectation.*
    //       </p>
    //       <div className="w-[40%] input-dollar">
    //         <p>$</p>
    //         <Input
    //           // label="Reserve Price"
    //           fullWidth
    //           value={inputValue}
    //           onChange={handleInputChange}
    //         />
    //       </div>
    //     </div>
    //   );
    // };
    const Component = ({
      value,
      setValue,
      field,
      minValue,
      maxValue,
    }: {
      value: number;
      field: any;
      setValue: React.Dispatch<React.SetStateAction<number>>;
      minValue: number;
      maxValue: number;
    }) => {
      const [inputValue, setInputValue] = useState<string>(
        value.toLocaleString()
      );

      useEffect(() => {
        setInputValue(value.toLocaleString());
      }, [value]);

      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/,/g, ""); // Remove commas
        // Allow the user to type non-numeric input for better flexibility
        if (/^\d*$/.test(newValue)) {
          setInputValue(newValue); // Update inputValue with the user's raw input
          const parsedValue = parseInt(newValue);
      
          if (!isNaN(parsedValue)) {
            setFormData((prev: any) => ({
              ...prev,
              reservePrice: parsedValue.toString(),
            }));
          } else {
            setFormData((prev: any) => ({
              ...prev,
              reservePrice: "",
            }));
          }
        }
        // setInputValue(e.target.value);
      };

      const handleBlur = () => {
        if (inputValue === "") {
          setValue(minValue);
          setInputValue(minValue.toLocaleString());
          setFormData((prev: any) => ({
            ...prev,
            reservePrice: minValue.toString(),
          }));
        }
      };

      return (
        <div className="flex gap-3" key={field.name}>
          <p className="w-[60%]">
            Reserve price - what is the lowest offer you would consider? This
            helps ensure we don't bother you with offers lower than your
            expectation.*
          </p>
          <div className="w-[40%] input-dollar">
            <p>$</p>
            <Input
              fullWidth
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
      );
    };
    console.log(formData);

    return (
      <>
        <div className="form-logo">
          <img src={Logo} alt="" />
        </div>

        {isMobileView && (vehicleDetails || formData?.vehicleName) && (
          <div className="bg-[#ff8800] rounded-md mt-4">
            <div className="snapshot">
              <h1>{formData?.vehicleName}</h1>
              <h2>
                {formData?.location} | {formData?.numberPlate} | {formData?.km}{" "}
                km
              </h2>
            </div>
          </div>
        )}

        <div className={cn("grid grid-cols-2 flex-col gap-4 py-8", className)}>
          {fields.map((field, index) => {
            if (index < 3) {
              return (
                <div key={field.name} className="col-span-2 textarea">
                  <label>{field.label}</label>
                  <Textarea
                    name={field.name}
                    label={field.placeholder}
                    value={formData[field.name]}
                    onChange={handleInputChange}
                    fullWidth
                  />
                  {validationErrors[field.name] && (
                    <span className="text-red-500">
                      {validationErrors[field.name]}
                    </span>
                  )}
                </div>
              );
            } else if (field.name === "conditionRating") {
              return (
                <div key={field.name} className="col-span-2">
                  <label>{field.label} *</label>
                  <div className="emoji-slider slider-style">
                    <Slider
                      showTooltip={true}
                      step={1}
                      color="warning"
                      maxValue={5}
                      minValue={1}
                      value={parseInt(formData.conditionRating) || 3}
                      marks={[
                        { value: 1, label: "Poor" },
                        { value: 2, label: "Fair" },
                        { value: 3, label: "Average" },
                        { value: 4, label: "Good" },
                        { value: 5, label: "Excellent" },
                      ]}
                      onChange={(value) => {
                        if (typeof value === "number") {
                          setFormData({
                            ...formData,
                            conditionRating: value.toString(),
                          });
                        }
                      }}
                      defaultValue={3}
                      className="w-[95%]"
                    />
                  </div>
                  {validationErrors[field.name] && (
                    <span className="text-red-500">
                      {validationErrors[field.name]}
                    </span>
                  )}
                </div>
              );
            } else if (field.name === "reservePrice") {
              return (
                <>
                  <div className="col-span-2">
                  <Component
                     value={value}
                     setValue={setValue}
                     field={field}
                     minValue={Math.max(10000, parseCurrencyValue(formData?.dealership) * 0.8)}
                     maxValue={Math.min(100000, parseCurrencyValue(formData?.privateSale) * 1.2)}
                   />
                  </div>
                  <div className="col-span-2 mb-5">
                    <div
                      key={field.name}
                      id="price-slider-range-id"
                      className="price-slider-range flex flex-col gap-2 w-full h-full items-start justify-center slider-style"
                    >
                      <Slider
                        showTooltip={true}
                        // tooltipValueFormatOptions={{ style: "currency", currency: "USD" }}
                        // formatOptions={{ style: "currency", currency: "USD" }}
                        step={100}
                        tooltipProps={{
                          content: `$${value}`,
                          defaultOpen: true,
                        }}
                        color="warning"
                        formatOptions={{ style: "currency", currency: "USD" }}
                        tooltipValueFormatOptions={{
                          style: "currency",
                          currency: "USD",
                        }}
                        value={value}
                        minValue={0}
                        maxValue={100000}
                        onChange={(val) => {
                          if (typeof val === "number") {
                            setValue(val);
                            setFormData((prev: any) => ({
                              ...prev,
                              reservePrice: val.toString(),
                            }));
                          }
                        }}
                        classNames={{
                          base: "gap-3 w-[95%]",
                        }}
                      />
                    </div>
                    <div className="price-values flex w-[95%] justify-between">
                      <p className="text-default-500 font-medium text-small">
                        $0
                      </p>
                      <p className="text-default-500 font-medium text-small">
                        $100,000
                      </p>
                    </div>
                    {validationErrors[field.name] && !formData.reservePrice && (
                      <span className="text-red-500">
                        {validationErrors[field.name]}
                      </span>
                    )}
                  </div>
                </>
              );
            } else if (field.type === "radio") {
              return (
                <div className="col-span-2">
                  <div className="onboarding-input-radio">
                    <label>{field.label} *</label>
                    {field.options?.map((option) => (
                      <div key={option} className="onboarding-input-radio-item">
                         <Checkbox
                          checked={formData[field.name] === option}
                          onChange={() => {
                            setFormData({
                              ...formData,
                              [field.name]: option,
                            });
                          }}
                          size="sm"
                          data-selected={
                            formData[field.name] === option ? "true" : "false"
                          }
                            aria-label="Close"
                        >
                          <span className="text-small font-normal">
                            {option}
                          </span>
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                  {validationErrors[field.name] && (
                    <span className="text-red-500">
                      {validationErrors[field.name]}
                    </span>
                  )}
                </div>
              );
            } else if (field.type === "checkbox") {
              return (
                <div key={field.name} className="col-span-2">
                  <div className="onboarding-input-checkbox">
                    <label>{field.label} *</label>
                    <div>
                      <Checkbox
                        checked={formData[field.name] || false}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [field.name]: e.target.checked,
                          });
                        }}
                        size="sm"
                      >
                        <span className="text-small font-normal">Agree</span>
                      </Checkbox>
                    </div>
                  </div>
                  {validationErrors[field.name] && (
                    <span className="text-red-500">
                      {validationErrors[field.name]}
                    </span>
                  )}
                  
                </div>
              );
            }
            return null;
          })}
        </div>
      </>
    );
  }
);

TellUsMore.displayName = "TellUsMore";
export default TellUsMore;
