import React, { useState } from "react";
import ListingDetails from "../../../assets/Icons/BottomBar/ListingDetails.svg";
import Docs from "../../../assets/Icons/BottomBar/Docs.svg";
import Calendar from "../../../assets/Icons/BottomBar/Calendar.svg";
import Schedule from "../../../assets/Icons/BottomBar/Schedule.svg";
import ListingDetailsActive from "../../../assets/Icons/BottomBar/ListingDetailsActive.svg";
import CalendarActive from "../../../assets/Icons/BottomBar/CalendarActive.svg";
import ScheduleActive from "../../../assets/Icons/BottomBar/ScheduleActive.svg";
import DocsActive from "../../../assets/Icons/BottomBar/DocsActive.svg";

const BottomBar = ({ scrollToSection }: { scrollToSection: any }) => {
  const [activeItem, setActiveItem] = useState("carImage");

  const items = [
    {
      name: "Listing Details",
      img: ListingDetails,
      activeImg: ListingDetailsActive,
      ref: "carImage",
    },
    {
      name: "Your Docs",
      img: Docs,
      activeImg: DocsActive,
      ref: "docs",
    },
    {
      name: "Calendar",
      img: Calendar,
      activeImg: CalendarActive,
      ref: "calendar",
    },
    {
      name: "Schedules",
      img: Schedule,
      activeImg: ScheduleActive,
      ref: "schedule",
    },
  ];

  const handleItemClick = (ref:any) => {
    setActiveItem(ref);
    scrollToSection(ref);
  };

  return (
    <div className="seller-dashbaord-bottombar">
      <div className="seller-dashbaord-bottombar-items">
        {items.map((item, index) => (
          <div
            className="seller-dashbaord-bottombar-item"
            key={index}
            onClick={() => handleItemClick(item.ref)}
          >
            <img 
              src={activeItem === item.ref ? item.activeImg : item.img} 
              alt={item.name} 
            />
            <p style={{ color: activeItem === item.ref ? "#FF8800" : "inherit" }}>
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomBar;