import React, { useState } from "react";
import { Button } from "@nextui-org/react";

interface TimeSlotProps {
  date: string | null; // Accepting the selected date
  isVisible: boolean;
  changeFormVisibility: (data: any) => void;
  slotData: any[];
  formattedDate: any;
}

const TimeSlot: React.FC<TimeSlotProps> = ({
  date,
  isVisible,
  changeFormVisibility,
  slotData,
  formattedDate,
}) => {
  // Track selected time slot
  const [isTimeSelected, setIsTimeSelected] = useState<string>("");

  // Function to convert time (HH:MM) to minutes since midnight for comparison
  const timeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  // Sort slotData by start_time in ascending order
  const sortedSlotData = slotData.slice().sort((a, b) => {
    return timeToMinutes(a.start_time) - timeToMinutes(b.start_time);
  });

  return (
    <div
      className={`${
        isVisible ? "flex" : "hidden"
      } lg:h-[600px] lg:w-[240px] w-full h-full p-4 flex-col`}
    >
      {/* Display the selected date */}
      {date ? (
        <h2 className="text-start hidden lg:flex -mt-6 mb-4 text-[#747171] font-light">
          {date}
        </h2>
      ) : (
        <p>No Date Selected</p>
      )}

      {/* Time slots rendering */}
      <div className="flex flex-col gap-2 overflow-y-scroll scrollbar-hide">
        {sortedSlotData?.length > 0 ? (
          sortedSlotData.map((time: any, index: number) => (
            <div key={index}>
              {isTimeSelected === time?.start_time ? (
                // Selected time slot
                <div className="flex gap-2 w-full transition-opacity duration-1000 ease-in-out">
                  <Button
                    className="w-full h-full py-4 rounded-md bg-[#565656] text-md font-semibold text-white transition-transform duration-500 ease-in-out"
                    onClick={() => setIsTimeSelected(time?.start_time)}
                  >
                    {time?.start_time?.split(":")[0]}:
                    {time?.start_time?.split(":")[1]}
                  </Button>
                  <Button
                    color="primary"
                    className="w-full h-full py-4 rounded-md bg-[#FF9800] font-semibold text-white text-md transition-transform duration-500 ease-in-out"
                    onClick={() =>
                      changeFormVisibility({
                        start_time: time?.start_time,
                        end_time: time?.end_time,
                        date: formattedDate,
                        availability_id: time?.availability_id,
                        slot_id: time?.slot_id,
                      })
                    }
                  >
                    Next
                  </Button>
                </div>
              ) : (
                // Non-selected time slot
                <div
                  className="w-full border-2 rounded-md border-[#FF9800] py-3 px-2 text-center items-center cursor-pointer hover:border-orange-500 transition-all duration-300 ease-in-out"
                  onClick={() => setIsTimeSelected(time?.start_time)}
                >
                  {time?.start_time?.split(":")[0]}:
                  {time?.start_time?.split(":")[1]}
                </div>
              )}
            </div>
          ))
        ) : (
          // If no slots available
          <p>No available time slots</p>
        )}
      </div>
    </div>
  );
};

export default TimeSlot;
