import React, { useEffect, useState } from "react";
// import AuthRightComponent from "../AuthComponents/AuthRightComponent";
import "../styles/auth.css";
// import AuthRight from "../../../assets/Images/authRight.png";
import Logo from "../../../assets/Icons/logo.svg";
import SingupLeft from "../AuthComponents/SingupLeft";
import LoginLeft from "../AuthComponents/LoginLeft";
import { useLocation } from "react-router-dom";
import { Tabs, Tab } from "@nextui-org/react";
import Icon1 from "../../../assets/Icons/SalePrice.svg";
import Icon2 from "../../../assets/Icons/HassleFree.svg";
import Icon3 from "../../../assets/Icons/NoUpfront.svg";
import Icon4 from "../../../assets/Icons/Support.svg";
import Icon5 from "../../../assets/Icons/QuickSales.svg";
import Icon6 from "../../../assets/Icons/SecureTransaction.svg";

const Signup = () => {
  const [isSignIn, setIsSignIn] = useState(true);

  const location = useLocation();
  const [vehicleId, setVehicleId] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const vehicleIdParam = params.get("id");
    if (vehicleIdParam) {
      setVehicleId(vehicleIdParam);
      setIsSignIn(false);
    }
  }, [location]);

  const data = [
    {
      id: 1,
      name: "Higher Sale Price",
      desc: "Maximum profit",
      img: Icon1,
    },
    {
      id: 1,
      name: "Hassle-Free Process",
      desc: "Great convenience",
      img: Icon2,
    },
    {
      id: 1,
      name: "No Upfront Fees",
      desc: "Pay only after sale",
      img: Icon3,
    },

    {
      id: 1,
      name: "Comprehensive Support",
      desc: "24x7 support available",
      img: Icon4,
    },
    {
      id: 1,
      name: " Quick Sales",
      desc: "Speedy sales process",
      img: Icon5,
    },
    {
      id: 1,
      name: "Secure Transactions",
      desc: "Top notch security",
      img: Icon6,
    },
  ];

  return (
    // <div className="authentication-flex">
    //   <div className="authentication-left">
    //     <div className="auth-left-component">
    //       <div className="logo">
    //         <img src={Logo} alt="" />
    //       </div>
    //       <div className="form-div">
    //         {/* <div className="toggle-button">
    //           <div
    //             className={`toggle-button-option ${isSignIn ? "active" : ""}`}
    //             onClick={() => setIsSignIn(true)}
    //           >
    //             Sign in
    //           </div>
    //           <div
    //             className={`toggle-button-option ${!isSignIn ? "active" : ""}`}
    //             onClick={() => setIsSignIn(false)}
    //           >
    //             Create account
    //           </div>
    //         </div> */}
    //         <Tabs
    //           size={"lg"}
    //           fullWidth
    //           aria-label="Tabs sizes"
    //           selectedKey={isSignIn ? "signin" : "singup"}
    //           onSelectionChange={(key) => {
    //             setIsSignIn(key === "signin");
    //           }}
    //         >
    //           <Tab
    //             key="signin"
    //             title="Sign In"
    //             className={`toggle-button-tab ${isSignIn ? "active" : ""}`}
    //           />
    //           <Tab key="singup" title="Sign Up"
    //           className={`toggle-button-tab ${!isSignIn ? "active" : ""}`}
    //           />
    //         </Tabs>
    //         {isSignIn ? (
    //           <LoginLeft isSignIn={isSignIn} setIsSignIn={setIsSignIn} />
    //         ) : (
    //           <SingupLeft
    //             isSignIn={isSignIn}
    //             setIsSignIn={setIsSignIn}
    //             vehicleId={vehicleId}
    //           />
    //         )}
    //       </div>
    //     </div>
    //   </div>
    //   <div className="authentication-right">
    //     <AuthRightComponent />
    //   </div>

    //   {/*   <form onSubmit={handleSubmit}>
    //     <div>
    //       <label>Name:</label>
    //       <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
    //     </div>
    //     <div>
    //       <label>Email:</label>
    //       <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
    //     </div>
    //     <div>
    //       <label>Password:</label>
    //       <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
    //     </div>
    //     <button type="submit">Signup</button>
    //   </form> */}
    // </div>
    <div className="authcontainer-wrap">
      <div className="authcontainer">
        <div className="authcontainer-details">
          <div className="authcontainer-left">
            <div className="auth-left-card">
              <div className="auth-left-component">
                <div className="logo">
                  <img src={Logo} alt="" />
                </div>
                <div className="form-div">
                  {/* <div className="toggle-button">
              <div
                className={`toggle-button-option ${isSignIn ? "active" : ""}`}
                onClick={() => setIsSignIn(true)}
              >
                Sign in
              </div>
              <div
                className={`toggle-button-option ${!isSignIn ? "active" : ""}`}
                onClick={() => setIsSignIn(false)}
              >
                Create account
              </div>
            </div> */}
                  <Tabs
                    size={"lg"}
                    fullWidth
                    aria-label="Tabs sizes"
                    selectedKey={isSignIn ? "signin" : "singup"}
                    onSelectionChange={(key) => {
                      setIsSignIn(key === "signin");
                    }}
                  >
                    <Tab
                      key="signin"
                      title="Sign In"
                      className={`toggle-button-tab ${
                        isSignIn ? "active" : ""
                      }`}
                    />
                    <Tab
                      key="singup"
                      title="Sign Up"
                      className={`toggle-button-tab ${
                        !isSignIn ? "active" : ""
                      }`}
                    />
                  </Tabs>
                  {isSignIn ? (
                    <LoginLeft isSignIn={isSignIn} setIsSignIn={setIsSignIn} />
                  ) : (
                    <SingupLeft
                      isSignIn={isSignIn}
                      setIsSignIn={setIsSignIn}
                      vehicleId={vehicleId}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="authcontainer-right">
            <div className="auth-right-details">
              <h1>
                Why Choose {" "}
                <span>1Wheel</span>{" "}?
              </h1>
              <p>Experience the Best in Car Selling with Our Key Benefits</p>
              <div className="auth-right-details-flex">
                {data.map((item) => (
                  <div className="auth-right-details-flex-item" key={item.id}>
                    <img src={item.img} alt="" />
                    <h3>{item.name}</h3>
                    <p>{item.desc}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
