import React, { useState, useEffect } from "react";
import { saveToLocalStorage } from "../../../utils/localStorageUtil.ts";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import {
  requestOTPSignin,
  verifyOTP,
} from "../../../services/authServies/authService";
import { Button, Input, Link } from "@nextui-org/react";
import { updateIntercomUser } from "../../../utils/intercomUtils";

const LoginLeft = ({
  isSignIn,
  setIsSignIn,
  admin,
}: {
  isSignIn: boolean;
  setIsSignIn: React.Dispatch<React.SetStateAction<boolean>>;
  admin?: boolean;
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [countryCode] = useState<string>("+61"); // Default country code set to +61
  const [name, setName] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [timer, setTimer] = useState<number>(0);
  const [isRequestingOTP, setIsRequestingOTP] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const validatePhoneNumber = () => {
    const newErrors: { [key: string]: string } = {};
    if (!phoneNumber) newErrors.phoneNumber = "Phone number is required";
    else if (!/^\d+$/.test(phoneNumber))
      newErrors.phoneNumber = "Phone number must be numeric";
    else if (phoneNumber.length !== 10)
      newErrors.phoneNumber = "Phone number must be of 10 digits";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateOtp = () => {
    const newErrors: { [key: string]: string } = {};
    if (!otp) newErrors.otp = "OTP is required";
    else if (otp.length !== 6) newErrors.otp = "OTP must be of 6 digits";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRequestOTP = async () => {
    if (!validatePhoneNumber()) return;

    setIsRequestingOTP(true);

    try {
      await requestOTPSignin(phoneNumber, countryCode, name, "email");
      setOtpSent(true);
      toast.success("OTP sent successfully, it will expire in 5 minutes.");
      setTimer(300); // Set timer for 2 minutes (120 seconds)
    } catch (error: any) {
      setIsRequestingOTP(false);
      if (error.response && error.response.status === 400) {
        if (error.response.data.error === "User does not exist") {
          setError("User does not exist");
          toast.error("User does not exist, please sign up");
          setIsSignIn(false);
        } else {
          setError(error.response.data.message);
        }
      } else {
        setError((error as Error).message);
      }
    }
  };

  const handleResendOTP = async () => {
    // Step 1: Validate the phone number
    if (!validatePhoneNumber()) return;

    // Step 2: Set the state to indicate the request is in progress
    setIsRequestingOTP(true);

    try {
      // Step 3: Resend OTP request to the backend (the same as the original request)
      await requestOTPSignin(phoneNumber, countryCode, name, "email");

      // Step 4: Set state indicating the OTP has been resent successfully
      setOtpSent(true);
      toast.success("OTP resent successfully, it will expire in 5 minutes.");

      // Step 5: Reset the timer for 5 minutes (300 seconds)
      setTimer(300);
    } catch (error: any) {
      // Step 6: Handle errors if OTP resend fails
      setIsRequestingOTP(false);

      if (error.response && error.response.status === 400) {
        if (error.response.data.error === "User does not exist") {
          setError("User does not exist");
          toast.error("User does not exist, please sign up");
          setIsSignIn(false); // Switch to sign-up flow
        } else {
          setError(error.response.data.message); // Show backend error message
        }
      } else {
        setError((error as Error).message); // Handle generic errors
      }
    }
  };

  const handleVerifyOTP = async () => {
    if (!validateOtp()) return;

    try {
      const response = await verifyOTP(phoneNumber, otp, navigate);
      await saveToLocalStorage("onewheel_user", JSON.stringify(response));
      // Update Intercom user data
      updateIntercomUser({
        id: response.user.id,
        name: response.user.name,
        email: response.user.email,
      });
        console.log("handling signing up",response);
      if (response.user.isAdmin === true || admin) {
        navigate("/admin/listings");
      } else {
        if(response?.user?.vehicle_onboarding)
        {
          navigate("/");
        }
        else{
          navigate("/vehicle-onboarding");
        }
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data.error === "Invalid or expired OTP"
      ) {
        toast.error("Invalid or expired OTP");
        setOtp("");
      } else {
        setError((error as Error).message);
      }
    }
  };

  return (
    <>
      <h1>{isSignIn ? "Welcome back!" : "Create your account"}</h1>
      <p>Please sign in to continue.</p>
      <div className="form-div-options">
        <div className="form-div-options-item">
          <div className="input-item">
            <p>Enter your phone number</p>
            <Input
              type="text"
              label="Phone Number"
              value={phoneNumber}
              isInvalid={error || errors.phoneNumber?.length > 0 ? true : false}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className={errors.phoneNumber ? "input-error" : ""}
            />
            {errors.phoneNumber && (
              <p className="error-text">{errors.phoneNumber}.</p>
            )}
          </div>
          {!isSignIn && (
            <div className="input-item">
              <p>Enter your name</p>
              <Input
                type="text"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          )}
          {otpSent && (
            <>
              <div className="input-item">
                <p>Enter the OTP sent to your phone number</p>
                <Input
                  type="text"
                  label="OTP"
                  value={otp}
                  isInvalid={errors.otp ? true : false}
                  onChange={(e) => setOtp(e.target.value)}
                />
                {errors.otp && <p className="error-text">{errors.otp}.</p>}
              </div>
              {timer > 0 && (
                <div className="mt-3 flex w-full items-center justify-between">
                  <p className="text-sm">
                    OTP will expire in <strong>{timer}</strong> seconds
                  </p>
                  <p
                    className={`text-sm font-medium cursor-pointer py-1 ${
                      timer > 240
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-[#ff8802]"
                    }`}
                    onClick={timer <= 240 ? handleResendOTP : undefined}
                    style={{ pointerEvents: timer > 240 ? "none" : "auto" }}
                  >
                    Resend OTP
                  </p>
                </div>
              )}
            </>
          )}
          {error && <p className="error">{error}</p>}
          <div className="button form-div-submit">
            {!otpSent ? (
              <Button
                className="bg-[#FFD700]"
                onClick={handleRequestOTP}
                disabled={isRequestingOTP}
              >
                {isRequestingOTP
                  ? "Requesting..."
                  : isSignIn
                  ? "Request OTP"
                  : "Sign In"}
              </Button>
            ) : (
              <Button className="bg-[#FFD700]" onClick={handleVerifyOTP}>
                {isSignIn ? "Log in" : "Verify OTP"}
              </Button>
            )}
          </div>
          <p className="text-center text-small mt-1">
            Don't have an account?&nbsp;
            <Link
              onClick={() => setIsSignIn(!isSignIn)}
              className="text-[#ff8802]"
            >
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginLeft;
