import React, { useEffect, useRef, useState } from "react";
import DateRangeComponent from "./DateRangeComponent";
import AddressComponent from "./AddressComponent";
import DayAvailability from "./DayAvailability";
import { toast } from "sonner";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner
import axios from "axios";

interface CalendarProps {
  isModalVisible: boolean; // To receive the modal visibility state
  handleCancel: () => void; // To receive the close modal function
}

interface DateRange {
  from: string;
  to: string;
}

interface TimeSlot {
  startTime: string;
  endTime: string;
}

interface DateAvailability {
  date: string; // Date in "DD/MM/YYYY" format
  available: boolean;
  timeSlots: TimeSlot[];
}

const Calendar: React.FC<CalendarProps> = ({
  isModalVisible,
  handleCancel,
}) => {
  // Global state
  const [address, setAddress] = useState(""); // Address
  const [isTouchedAddress, setIsTouchedAddress] = useState(false); // Address
  const [note, setNote] = useState(""); // Note
  const [addressError, setAddressError] = useState(false);
  const [dateRangeError, setDateRangeError] = useState(false); // State for date range error
  const [timeSlotError, setTimeSlotError] = useState(false);

  const addressRef = useRef<HTMLInputElement | null>(null); // Create a ref for the address input

  const [dateRanges, setDateRanges] = useState<DateRange[]>([
    { from: "", to: "" },
  ]);

  const [dates, setDates] = useState<DateAvailability[]>([]); // For selected dates availability
  const [loading, setLoading] = useState(false);

  // Generate dates from dateRanges whenever dateRanges change
  useEffect(() => {
    const generateDates = () => {
      const newDates: DateAvailability[] = [];
      dateRanges.forEach((range) => {
        if (range.from && range.to) {
          const datesBetween = getDatesBetween(range.from, range.to);
          datesBetween.forEach((date) => {
            // Only add the date if it's not already in the list
            if (!newDates.some((d) => d.date === date)) {
              newDates.push({ date: date, available: true, timeSlots: [] });
            }
          });
        }
      });
      setDates(newDates);
    };
    generateDates();
  }, [dateRanges]);

  // Function to get dates between two dates
  const getDatesBetween = (
    startDateStr: string,
    endDateStr: string
  ): string[] => {
    const startParts = startDateStr.split("/");
    const endParts = endDateStr.split("/");

    const startDate = new Date(
      +startParts[2],
      +startParts[1] - 1,
      +startParts[0]
    );
    const endDate = new Date(+endParts[2], +endParts[1] - 1, +endParts[0]);

    const dates: string[] = [];

    for (
      let d = new Date(startDate);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      const day = d.getDate().toString().padStart(2, "0");
      const month = (d.getMonth() + 1).toString().padStart(2, "0");
      const year = d.getFullYear();
      dates.push(`${day}/${month}/${year}`);
    }

    return dates;
  };

  // Validate address input only after it's touched
  useEffect(() => {
    if (isTouchedAddress && !address.trim()) {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
  }, [address, isTouchedAddress]);

  // Validate date ranges and time slots
  useEffect(() => {
    if (dateRanges?.length !== 0) {
      setDateRangeError(false);
    } else {
      setDateRangeError(true);
    }
    if (dates?.length !== 0) {
      setTimeSlotError(false);
    } else {
      setTimeSlotError(true);
    }
  }, [dateRanges, dates]);


  // Helper function to check if two date ranges overlap
  const isOverlapping = (range1: DateRange, range2: DateRange) => {
    const from1 = new Date(range1.from);
    const to1 = new Date(range1.to);
    const from2 = new Date(range2.from);
    const to2 = new Date(range2.to);

    return from1 <= to2 && to1 >= from2; // Check overlap condition
  };

  const handleReset = () => {
    setIsTouchedAddress(false);
    setAddress("");
    setNote("This is for notes");
    setDateRanges([{ from: "", to: "" }]);
    setDates([]);
  };


  const handleSave = async () => {
    // Make the function async
    setIsTouchedAddress(true); // Set form as submitted
    if (!address.trim()) {
      setAddressError(true);
      // Focus on the address input and scroll to it
      if (addressRef.current) {
        addressRef.current.focus(); // Focus the input
        addressRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        }); // Scroll to the input
      }
      return;
    }

    setAddressError(false);

    // Check for overlapping date ranges
    for (let i = 0; i < dateRanges.length; i++) {
      for (let j = i + 1; j < dateRanges.length; j++) {
        if (isOverlapping(dateRanges[i], dateRanges[j])) {
          toast.error(`Date range ${i + 1} and ${j + 1} are overlapping.`);
          setDateRangeError(true); // Mark error state
          return; // Stop save if overlapping is found
        }
      }
    }

    setDateRangeError(false); // Reset the error if validation passes

    // Validate date ranges
    const hasValidDateRange = dateRanges.some(
      (range) => range.from && range.to
    );

    if (!hasValidDateRange) {
      setDateRangeError(true); // Set date range error state
      if (addressRef.current) {
        addressRef.current.focus(); // Focus the input
        addressRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        }); // Scroll to the input
      }
      return;
    } else {
      setDateRangeError(false); // Reset the error if validation passes
    }

    // Validate time slots
    const hasValidTimeSlots = dates.some(
      (date) =>
        date.available &&
        date.timeSlots.some((slot) => slot.startTime && slot.endTime)
    );

    if (!hasValidTimeSlots) {
      setTimeSlotError(true); // Set time slot error state
      if (addressRef.current) {
        addressRef.current.focus(); // Focus the input
        addressRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        }); // Scroll to the input
      }
      return;
    } else {
      setTimeSlotError(false); // Reset the error if validation passes
    }

    setLoading(true);
    setTimeout(async () => {
      const timeRanges = dates
        .filter((date) => date.available)
        .map((date) => {
          const validTimeSlots = date.timeSlots.filter(
            (slot) => slot.startTime && slot.endTime
          ); // Filter out empty slots

          return validTimeSlots.map((slot) => ({
            date: formatDate(date.date), // Format date as YYYY-MM-DD
            start_time: convertTo24HourFormat(slot.startTime), // Convert to HH:mm format
            end_time: convertTo24HourFormat(slot.endTime), // Convert to HH:mm format
          }));
        })
        .flat(); // Flatten the array

      console.log("timeRanges", timeRanges);
      const userData: any = localStorage.getItem("onewheel_user");
      const users = JSON.parse(userData);

      const dataToSave = {
        user_id: users?.user?.id, // Replace with actual user ID
        address: address,
        date_ranges: dateRanges.map((range) => ({
          start_date: formatDate(range.from), // Format date as YYYY-MM-DD
          end_date: formatDate(range.to), // Format date as YYYY-MM-DD
        })),
        time_ranges: timeRanges,
      };

      console.log("dataToSave", dataToSave); // For debugging
      try {
        // Make the API call using Axios
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/availability/createAvailability`,
          dataToSave
        );
        console.log("response", response);
        if (response.status === 200 || response.status === 201) {
          toast.success("Successfully saved availability!");
          handleReset(); // Reset the form after saving
          handleCancel();
        } else {
          toast.error("Failed to save availability.");
        }
      } catch (error) {
        toast.error("Error saving availability. Please try again later.");
        console.error("API error:", error);
      } finally {
        setLoading(false); // Set loading to false in the end
      }
    }, 2000); // Simulating a 2-second delay for demonstration
  };

  
  // Helper function to convert time to HH:mm format
  const convertTo24HourFormat = (time: string) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":");

    if (modifier === "PM" && hours !== "12") {
      hours = String(Number(hours) + 12);
    }

    if (modifier === "AM" && hours === "12") {
      hours = "00";
    }

    return `${hours}:${minutes}`;
  };

  // Helper function to format date as YYYY-MM-DD
  const formatDate = (date: string) => {
    console.log("date", date);
    const [day, month, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  console.log("dateRangeError", dateRangeError);

  return (
    <div className="h-screen">
      {/* Top Section */}
      <div className="bg-white p-1 md:p-2 w-full flex flex-col justify-center">
        <div className="flex justify-center items-center mb-8 lg:mb-6">
          <div className="text-lg  p-1 lg:text-3xl font-semibold text-center h-10 flex justify-center items-center rounded-lg ">
            <p ref={addressRef} className="text-center">
              Tell us when your are available for viewings
            </p>
          </div>
        </div>

        {/* Address Section */}
        <AddressComponent
          label="Viewing Location"
          value={address}
          setValue={(newValue) => {
            setAddress(newValue);
            setIsTouchedAddress(true); // Mark address as touched
            if (newValue.trim()) setAddressError(false); // Reset error if value is present
          }}
        />
        {isTouchedAddress && addressError && (
          <div className="text-red-500 mb-[1rem] ml-[3.5rem] md:ml-[8.5rem]">
            Address is required
          </div>
        )}

        {/* Date Range Section */}
        <DateRangeComponent
          dateRanges={dateRanges}
          setDateRanges={setDateRanges}
        />

        {dateRangeError && (
          <div className="text-red-500 mb-[1rem] ml-[3.5rem] md:ml-[8.5rem]">
            Date range is required.
          </div>
        )}
      </div>

      <hr className="border-gray-300 border-t-1 my-4" />

      {/* {timeSlotError && (
        <div className="text-red-500 mt-[1rem] ml-[3.5rem] md:ml-[8.5rem]">
          Please select valid time slots for available dates.
        </div>
      )} */}
      <div className="flex justify-center items-center">
        <DayAvailability
          dates={dates}
          setDates={setDates}
          note={note}
          setNote={setNote}
        />
      </div>

      <div className="w-full flex justify-center space-x-3 mt-6">
        <button
          className="w-[40%] md:w-[25%] px-6 py-2 bg-gray-300 hover:bg-gray-400 text-gray-700 rounded-lg shadow-sm transition duration-300"
          onClick={() => {
            handleReset();
            handleCancel();
          }}
        >
          Cancel
        </button>
        <button
          className="w-[40%] md:w-[25%] px-6 py-2 bg-[#FF9800] hover:bg-orange-600 text-white rounded-lg shadow-sm transition duration-300"
          onClick={handleSave}
        >
          {loading ? <ClipLoader color="#ffffff" size={20} /> : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default Calendar;
