import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { Upload, XCircle } from "lucide-react";
import JSZip from "jszip";
import axios from "axios";
import { toast } from "sonner";

interface Vehicle {
  id: number;
  user_id: number;
  postcode_suburb: string;
  vehicle_name: string;
  number_plate: string;
  state: string;
  location: string;
  year: number;
  km: number;
  contact_number: string | null;
  ownership: string;
  driving_type: string;
  service_history: string;
  both_keys: string;
  money_owed: string;
  damage: string;
  modifications: string;
  selling_reason: string;
  condition_rating: string;
  reserve_price: string;
  desired_outcome: string;
  avg_sell_time: string;
  dealership: string;
  fees: string;
  potential_saving: string;
  private_sale: string;
  created_at: string;
  updated_at: string;
  agree_to_terms: number;
  confirm_ownership: number;
}
interface Listing {
  id: number;
  vehicle_id: number;
  user_id: number;
  listing_title: string;
  description: string;
  price: string;
  isListingLive: number;
  created_at: string;
  updated_at: string;
  current_price_time: string;
}

interface Image {
  id: number;
  listing_id: number;
  image_urls: string[];
}

interface Document {
  id: number;
  user_id: number;
  document_type: string;
  document_name: string;
  document_url: string;
  status: string;
}

interface VehicleData {
  vehicle: Vehicle;
  listing: Listing | null;
  images: Image[];
  documents: Document[];
}
interface Link {
  link_id: number;
  listing_id: number;
  url: string;
  url_source: 'oneWheel' | 'carsGuide' | 'carSales' | 'autoTrader' | 'gumTree'; // Explicitly define the possible sources
  description: string | null;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;
}

const AdminSingleVehicle = () => {
  const { id } = useParams();
  const [vehicleData, setVehicleData] = useState<VehicleData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [links, setLinks] = useState({
    oneWheel: "",
    carsGuide: "",
    carSales: "",
    autoTrader: "",
    gumTree: "",
  });

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(Array.from(event.target.files));
    }
  };

  const uploadImages = async () => {
    if (selectedFiles.length === 0 || !vehicleData?.listing?.id) {
      setUploadStatus("Please select at least one image.");
      return;
    }

    setUploading(true);
    setUploadStatus("Uploading...");

    const formData = new FormData();
    formData.append("listingId", vehicleData.listing.id.toString());
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/upload-vehicle-image`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const { message, totalImages, newImagesAdded, updatedImageUrls } =
        response.data;
      setUploadStatus(message);

      // Update vehicleData with new images
      setVehicleData((prevData) => {
        if (prevData && prevData.images.length > 0) {
          return {
            ...prevData,
            images: [{ ...prevData.images[0], image_urls: updatedImageUrls }],
          };
        }
        return prevData;
      });

      // Clear selected files after successful upload
      setSelectedFiles([]);
      // Reset the file input by updating its key
      setFileInputKey(Date.now());

      console.log(
        `Successfully added ${newImagesAdded} new images. Total images: ${totalImages}`
      );
    } catch (error) {
      console.error("Upload failed:", error);
      setUploadStatus("Failed to upload images. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    const fetchVehicle = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/vehicle/fetchVehicleByVehicleId/${id}`
        );
        const data = await response.json();

        if (!data.success) {
          throw new Error("Failed to fetch vehicle details");
        }
        console.log("vehicle data", data.data);
        setVehicleData(data.data);

        // Fetch external links for the vehicle
        await fetchExternalLinks(data?.data?.listing?.id);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching vehicle data:", error);
        setError("Failed to fetch vehicle data. Please try again later.");
        setLoading(false);
      }
    };

    const fetchExternalLinks = async (listingId: number) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/links/${listingId}`
        );
        const data = await response.json();

        if (data) {
          const newLinks = {
            oneWheel: "",
            carsGuide: "",
            carSales: "",
            autoTrader: "",
            gumTree: "",
          };

          // Iterate through the fetched links and set the corresponding URLs
          data.links.forEach((link: Link) => { 
            if (link.url_source in newLinks) { 
              newLinks[link.url_source] = link.url;
            }
          });
    
          setLinks(newLinks); // Update the state with the new links
        } else {
          console.log("failed to fetch external links");
        }
      } catch (error) {
        console.error("Error fetching external links:", error);
      }
    };

    fetchVehicle();
    // Check if user is admin
    const user = JSON.parse(localStorage.getItem("onewheel_user") || "{}");
    setIsAdmin(user?.user?.isAdmin || false);
  }, [id]);
  const handleImageClick = (url: string) => {
    setSelectedImage(url);
    onOpen();
  };
  const handleRemoveImage = async (indexToRemove: number) => {
    if (!vehicleData || !vehicleData.images.length || !vehicleData.listing)
      return;

    try {
      // Update local state
      const updatedImageUrls = vehicleData.images[0].image_urls.filter(
        (_, index) => index !== indexToRemove
      );

      setVehicleData((prevData) => {
        if (prevData && prevData.images.length > 0) {
          const updatedImages = [...prevData.images];
          updatedImages[0] = {
            ...updatedImages[0],
            image_urls: updatedImageUrls,
          };
          return { ...prevData, images: updatedImages };
        }
        return prevData;
      });

      // Call API to update backend
      const token = JSON.parse(
        localStorage.getItem("onewheel_user") || "{}"
      )?.token;
      if (!token) {
        throw new Error("No token found");
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/update-vehicle-images/${vehicleData.listing.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ imageUrls: updatedImageUrls }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update images on the server");
      }

      const result = await response.json();
      console.log("Images updated successfully:", result);
    } catch (error) {
      console.error("Error updating images:", error);
      // Optionally, revert the local state change or show an error message to the user
      // You might want to add some error handling UI here
    }
  };
  const handleDownloadAllImages = async () => {
    if (
      vehicleData &&
      vehicleData.images.length > 0 &&
      vehicleData.images[0].image_urls.length > 0
    ) {
      const zip = new JSZip();
      const imageFolder = zip.folder("vehicle_images");

      if (imageFolder) {
        const imagePromises = vehicleData.images[0].image_urls.map(
          async (url, index) => {
            try {
              const response = await fetch(url,{ mode: 'cors', cache: 'no-cache' });
              if (!response.ok)
                throw new Error(`Failed to fetch image: ${url}`);
              const blob = await response.blob();
              imageFolder.file(`image_${index + 1}.jpg`, blob);
            } catch (error) {
              console.error(`Error processing image ${index + 1}:`, error);
              // Optionally, you can add error handling UI here
            }
          }
        );

        await Promise.all(imagePromises);

        try {
          const content = await zip.generateAsync({ type: "blob" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(content);
          link.download = "vehicle_images.zip";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error generating zip file:", error);
          // Optionally, you can add error handling UI here
        }
      } else {
        console.error("Failed to create image folder in zip");
        // Optionally, you can add error handling UI here
      }
    } else {
      console.log("No images available to download");
      // Optionally, you can add UI feedback here
    }
  };

  const formatValue = (key: string, value: any): string => {
    if (
      [
        "reserve_price",
        "dealership",
        "potential_saving",
        "private_sale",
      ].includes(key)
    ) {
      return `$${value}`;
    }
    if (key === "price") {
      return `$${Math.round(parseFloat(value))}`;
    }
    if (["created_at", "updated_at", "current_price_time"].includes(key)) {
      return new Date(value).toLocaleString();
    }
    if (key === "avg_sell_time") {
      return `${Math.ceil(parseFloat(value) * 100) / 100}`;
    }
    if (["created_at", "updated_at", "current_price_time"].includes(key)) {
      return new Date(value).toLocaleString();
    }
    if (["agree_to_terms", "confirm_ownership"].includes(key)) {
      return value === 1 ? "True" : "False";
    }
    return String(value);
  };

  const openDocument = (url: string) => {
    window.open(url, "_blank");
  };

  const handleUploadLinks = async () => {
    if (!vehicleData?.listing?.id) return;

    try {
      setUploading(true);
      const token = JSON.parse(
        localStorage.getItem("onewheel_user") || "{}"
      )?.token;

      if (!token) {
        throw new Error("No token found");
      }

      const userId = JSON.parse(localStorage.getItem("onewheel_user") || "{}")
        ?.user?.id;
      let makeListingLive;
      if (vehicleData.listing.isListingLive) {
        makeListingLive = true;
      } else {
        makeListingLive = false;
      }
      // Filter out empty links before sending to the API
      const filteredLinks = Object.keys(links)
        .filter((key) => links[key as keyof typeof links] !== "") // Type assertion here
        .reduce((obj, key) => {
          obj[key as keyof typeof links] = links[key as keyof typeof links];
          return obj;
        }, {} as Partial<typeof links>);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/toggleListingLiveStatus/${vehicleData.listing.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            makeListingLive,
            links: filteredLinks, // Send only non-empty links
            userId,
          }),
        }
      );
      setUploading(false);
      toast.success('Link uploaded successfully!'); 
    } catch (error) {
      console.error("Error updating listing status:", error);
    }
  };
  const handleToggleLiveStatus = async () => {
    if (!vehicleData?.listing?.id) return;

    try {
      const token = JSON.parse(
        localStorage.getItem("onewheel_user") || "{}"
      )?.token;

      if (!token) {
        throw new Error("No token found");
      }

      const makeListingLive = vehicleData.listing.isListingLive === 0;
      const userId = JSON.parse(localStorage.getItem("onewheel_user") || "{}")
        ?.user?.id;

      // Filter out empty links before sending to the API
      const filteredLinks = Object.keys(links)
        .filter((key) => links[key as keyof typeof links] !== "") // Type assertion here
        .reduce((obj, key) => {
          obj[key as keyof typeof links] = links[key as keyof typeof links];
          return obj;
        }, {} as Partial<typeof links>);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/toggleListingLiveStatus/${vehicleData.listing.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            makeListingLive,
            links: filteredLinks,
            userId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to ${
            makeListingLive ? "make listing live" : "take listing offline"
          }`
        );
      }

      const result = await response.json();
      console.log(`Listing ${vehicleData.listing.id} status updated:`, result);

      // Update the local state to reflect the change
      setVehicleData((prevData) => {
        if (prevData && prevData.listing) {
          return {
            ...prevData,
            listing: {
              ...prevData.listing,
              isListingLive: makeListingLive ? 1 : 0,
            },
          };
        }
        return prevData;
      });
    } catch (error) {
      console.error("Error updating listing status:", error);
    }
  };

  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLinks((prevLinks) => ({
      ...prevLinks,
      [name]: value,
    }));
  };

  if (loading) {
    return <p className="text-center text-gray-500">Loading...</p>;
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }
  const getGridClassName = (imageCount: number) => {
    if (imageCount > 6) {
      return "grid grid-cols-2 md:grid-cols-4 gap-4";
    }
    return "grid grid-cols-2 md:grid-cols-3 gap-4";
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md overflow-y-scroll h-[97vh] scrollbar-hide mt-3">
      <div className="flex items-center mb-6">
        <button onClick={handleBack}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon icon-back-arrow mt-[3px]"
          >
            <path d="M19 12H5" />
            <polyline points="12 19 5 12 12 5" />
          </svg>
        </button>
        <h1 className="text-2xl font-semibold ml-1">Vehicle Details</h1>
      </div>
      {vehicleData &&
        vehicleData.images.length > 0 &&
        vehicleData.images[0].image_urls && (
          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Vehicle Images</h2>
            <div className="flex items-center mb-5">
              <Button
                onClick={handleDownloadAllImages}
                className="mr-3"
                color="primary"
                size="sm"
              >
                Download All Images
              </Button>
              {isAdmin && (
                <>
                  <Input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleFileChange}
                    className="hidden"
                    id="image-upload"
                    key={fileInputKey} // Add this line
                  />
                  <Button
                    as="label"
                    htmlFor="image-upload"
                    color="secondary"
                    size="sm"
                    className="mr-3"
                    startContent={<Upload size={20} />}
                  >
                    Select Images
                  </Button>
                  <Button
                    onClick={uploadImages}
                    color="success"
                    size="sm"
                    className="mr-3 text-white"
                    isLoading={uploading}
                    isDisabled={selectedFiles.length === 0}
                  >
                    Upload Images
                  </Button>
                </>
              )}
            </div>
            <div
              className={getGridClassName(
                vehicleData.images[0].image_urls.length
              )}
            >
              {vehicleData.images[0].image_urls.map((url, index) => (
                <div key={index} className="relative group">
                  <img
                    src={url}
                    alt=""
                    className="w-full h-48 object-cover rounded-lg shadow-md cursor-pointer"
                    onClick={() => handleImageClick(url)}
                  />
                  {isAdmin && (
                    <Button
                      isIconOnly
                      color="danger"
                      size="sm"
                      className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <XCircle size={20} />
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      {vehicleData && vehicleData.documents.length > 0 && (
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Documents</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {vehicleData.documents.map((doc) => (
              <div key={doc.id} className="flex flex-col p-4 border rounded-md">
                <p className="font-medium">{doc.document_name}</p>
                <p className="text-sm text-gray-500 mb-2">
                  Status: {doc.status}
                </p>
                <Button
                  onClick={() => openDocument(doc.document_url)}
                  color="primary"
                  size="sm"
                >
                  Open Document
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="5xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Vehicle Image
              </ModalHeader>
              <ModalBody>
                {selectedImage && (
                  <img
                    src={selectedImage}
                    alt="Vehicle"
                    className="w-full h-auto"
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      {vehicleData && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            {Object.entries(vehicleData.vehicle).map(([key, value]) => (
              <div key={key} className="mb-2">
                <label
                  htmlFor={`vehicle-${key}`}
                  className="block font-medium text-gray-700 capitalize mb-1"
                >
                  {key.replace(/_/g, " ")}:
                </label>
                <Input
                  id={`vehicle-${key}`}
                  value={formatValue(key, value)}
                  disabled
                  fullWidth
                  className="bg-gray-100"
                />
              </div>
            ))}
          </div>

          {vehicleData.listing && (
            <>
              <h2 className="text-xl font-semibold mt-8 mb-4">
                Listing Details
              </h2>
              <div className="mb-6 p-4 border rounded-md">
                <h3 className="font-semibold mb-2">
                  Listing ID: {vehicleData.listing.id}
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {Object.entries(vehicleData.listing).map(([key, value]) => (
                    <div key={key} className="mb-2">
                      <label
                        htmlFor={`listing-${key}`}
                        className="block font-medium text-gray-700 capitalize mb-1"
                      >
                        {key.replace(/_/g, " ")}:
                      </label>
                      <Input
                        id={`listing-${key}`}
                        value={formatValue(key, value)}
                        disabled
                        fullWidth
                        className="bg-gray-100"
                      />
                    </div>
                  ))}
                </div>
                <div className="mb-8 mt-3">
                  <h2 className="text-xl font-semibold mb-4">External Links</h2>
                  <div className="space-y-4 mb-5">
                    <div className="flex items-center">
                      <label className="font-medium text-gray-700 mr-6">
                        OneWheel:
                      </label>
                      <Input
                        type="text"
                        name="oneWheel"
                        value={links.oneWheel}
                        onChange={handleLinkChange}
                        className="w-full"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="font-medium text-gray-700 mr-6">
                        CarsGuide:
                      </label>
                      <Input
                        type="text"
                        name="carsGuide"
                        value={links.carsGuide}
                        onChange={handleLinkChange}
                        className="w-full"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="font-medium text-gray-700 mr-9">
                        CarSales:
                      </label>
                      <Input
                        type="text"
                        name="carSales"
                        value={links.carSales}
                        onChange={handleLinkChange}
                        className="w-full"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="font-medium text-gray-700 mr-4">
                        AutoTrader:
                      </label>
                      <Input
                        type="text"
                        name="autoTrader"
                        value={links.autoTrader}
                        onChange={handleLinkChange}
                        className="w-full"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="font-medium text-gray-700 mr-8">
                        GumTree:
                      </label>
                      <Input
                        type="text"
                        name="gumTree"
                        value={links.gumTree}
                        onChange={handleLinkChange}
                        className="w-full"
                      />
                    </div>
                  </div>
                  {uploading ? (
                     <Button
                    color="primary"
                    variant="ghost"
                    isLoading

                  >
                    Uploading Links
                  </Button>
                  ) : (
                     <Button
                    color="primary"
                    variant="ghost"
                    onClick={handleUploadLinks}
                  >
                    Upload Links
                  </Button>
                  )}
                 
                </div>
              </div>
            </>
          )}

          {isAdmin && vehicleData && vehicleData.listing && (
            <div className="mt-6">
              <Button
                onClick={handleToggleLiveStatus}
                color={
                  vehicleData.listing.isListingLive === 0
                    ? "success"
                    : "warning"
                }
                size="lg"
              >
                {vehicleData.listing.isListingLive === 0
                  ? "Make Listing Live"
                  : "Take Listing Offline"}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdminSingleVehicle;
