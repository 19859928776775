import React from "react";
import { Button, CircularProgress } from "@nextui-org/react";
import { forEach } from "jszip";

const ProfileComplete = ({
  listingDetails,
  setProfilecompleteUpload15,
  setVehicleRegistrationOpen,
  setDrivingLicenseOpen,
  setBankStatementOpen
}: {
  listingDetails: any;
  setProfilecompleteUpload15: any;
  setVehicleRegistrationOpen: any;
  setDrivingLicenseOpen:any;
  setBankStatementOpen:any;
}) => {
  const completionPercentage = parseInt(
    listingDetails?.profileCompletionPercentage
  );
  const missingItems = listingDetails?.missingItems || [];

  const handleMissingItemClick = (item: string) => {
    if (item === "Upload at least 15 photos") {
      // Assuming there's a property in listingDetails that stores the number of uploaded images
      const uploadedImagesCount = listingDetails?.images?.length || 0;

      if (uploadedImagesCount >= 15) {
      } else {
        // Here you can add logic to open an image upload dialog or navigate to an upload page
        setProfilecompleteUpload15(true);
        console.log("Please upload more images");
      }
    }
    // Add handling for other missing items if needed
  };
  const checkIsDocumentUploaded = (name: string): boolean => {
    return listingDetails?.documents.some((element:any) => element?.document_name === name);
  };
  
  const handleCompleteProfileClick = () => {
    console.log("missing items", missingItems);
    if (listingDetails?.images?.length<15) {
      setProfilecompleteUpload15(true);
    }
    else{
      // upload document of vehicle
      if(checkIsDocumentUploaded("Vehicle Registration"))
      {
        // open document
        setVehicleRegistrationOpen(true);
      }
      else if(checkIsDocumentUploaded("Driving License"))
      {
        // upload driving license
        setDrivingLicenseOpen(true);
      }
      else{
        // upload bank statement
        setBankStatementOpen(true);
      }
    }
  };

  return (
    <div className="seller-dashboard-top-right-cards-top seller-card">
      <div className="card-flex">
        <div className="card-flex-item circluar-progress-item">
          <CircularProgress
            size="lg"
            value={completionPercentage}
            className="seller-circle-progress"
            color={completionPercentage >= 75 ? "success" : "warning"}
            formatOptions={{
              style: "percent",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
            showValueLabel={true}
          />
        </div>
        <div className="card-flex-item">
          <h2>Complete your profile!</h2>
          <p>
            {/* {missingItems.length > 0 && (
              <div className="mt-1">
                {missingItems.map((item: any, index: any) => (
                  <p
                    key={index}
                    className="text-sm cursor-pointer hover:underline"
                    onClick={() => handleMissingItemClick(item)}
                  >
                    {item}
                  </p>
                ))}
              </div>
            )} */}
            A complete profile increases the exposure of your vehicles listing
            and can also speed up the transfer process once sold.
          </p>
        </div>
      </div>

      <Button
        className="bg-[#ff9800] text-white w-full -mt-2"
        size="md"
        disabled={completionPercentage >= 100}
        onClick={handleCompleteProfileClick}
      >
        {completionPercentage >= 100 ? "Profile Completed" : "Complete Profile"}
      </Button>
    </div>
  );
};

export default ProfileComplete;
