import React from "react";
import { Button, Spacer, useDisclosure } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import Logo from "../../assets/Icons/logo.svg";
import Sidebar from "./adminComponents/sidebar";
import { sectionItemsWithTeams } from "./adminComponents/sidebar-items";
import SidebarDrawer from "./adminComponents/sidebar-drawer";
import UsersTable from "./adminPages/UsersTable";
import VehicleTable from "./adminPages/VehicleTable";

export default function Admin() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const content = (
    <div className="relative flex w-72 flex-1 flex-col p-6 h-full">
      <div className="flex items-center gap-2 px-2">
        <img src={Logo} alt="" className="w-[180px]" />
      </div>

      <Spacer y={8} />

      <Sidebar defaultSelectedKey="home" items={sectionItemsWithTeams} />

      <Spacer y={8} />
    </div>
  );

  return (
    <div className="flex min-h-[100vh] overflow-hidden max-h[100vh] w-full">
      <SidebarDrawer
        className=" !border-r-small border-divider"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        {content}
      </SidebarDrawer>
      <div className="w-full flex-1 flex-col p-4">
        <header className="flex h-16 items-center gap-2 rounded-medium border-small border-divider px-4">
          <Button
            isIconOnly
            className="flex sm:hidden"
            size="sm"
            variant="light"
            onPress={onOpen}
          >
            <Icon
              className="text-default-500"
              height={24}
              icon="solar:hamburger-menu-outline"
              width={24}
            />
          </Button>
          <div className="flex items-center justify-between w-full">
            <h2 className="text-medium font-medium text-default-700">
              All Users
            </h2>
            <Button
              variant="bordered"
              onClick={() => {
                localStorage.removeItem("onewheel_user");
                window.location.href = "/auth/admin";
              }}
            >
              Logout
            </Button>
          </div>
        </header>
        <main className="mt-4 h-full w-full overflow-visible ">
          <div className="flex h-[90%] w-full flex-col gap-4 rounded-medium border-small border-divider pt-10 pl-10 pr-10">
            <UsersTable />
            {/* <VehicleTable /> */}
          </div>
        </main>
      </div>
    </div>
  );
}
