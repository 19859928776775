import React, { useEffect, useRef } from "react";
import verifiedCheckedIcon from "../../../assets/Images/Verified Check.png";
import userIcon from "../../../assets/Images/User Rounded.jpg";
import mapPointIcon from "../../../assets/Images/Map Point Wave.png";
import calendar from "../../../assets/Images/Calendar Date.png";

interface ModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  meetingDetails: any;
}

const Modal: React.FC<ModalProps> = ({ isOpen, toggleModal, meetingDetails }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  // Close modal when clicking outside of the modal content
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        toggleModal();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleModal]);

  if (!isOpen) return null;
  const formatDate = (dateString: string) => {
    const date = new Date(dateString); // Convert string to Date object
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options); // Format the date
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div ref={modalRef} className="relative bg-white rounded px-16 py-10">
        {/* Close button at the top right */}
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-medium"
          onClick={toggleModal}
          style={{
            width: "40px",
            height: "40px",
            fontSize: "28px",
            lineHeight: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          &times;
        </button>

        <div className="flex justify-center px-8 font-bold text-[18px] md:text-2xl">
          <img src={verifiedCheckedIcon} className="w-8 h-8" alt="Verified Check" />
          <p> Car Showcase Meet with Buyer</p>
        </div>
        <div className="flex justify-center md:mb-3 p-4 md:p-2 text-[10px] md:text-sm">
          <p>A calendar invitation has been sent to your email address.</p>
        </div>

        <div className="border-2 border-gray-300 md:px-10 md:py-6 px-4 py-4 rounded">
          <div className="text-xl font-bold m-1 flex justify-center md:justify-start text-[10px] md:text-xl">
            Your appointment has been scheduled.
          </div>
          <div className="flex gap-3 m-2 text-gray-500">
            <img src={userIcon} className="w-6 h-6" alt="User Icon" />
            <p>{meetingDetails?.name}</p>
          </div>
          <div className="flex gap-3 m-2 text-gray-500">
            <img src={calendar} className="w-6 h-6" alt="Calendar" />
            <p>
              {meetingDetails?.start_time.split(":")[0]}:{meetingDetails?.start_time.split(":")[1]} -{" "}
              {meetingDetails?.end_time?.split(":")?.[0]}:{meetingDetails?.end_time?.split(":")?.[1]}&nbsp; - &nbsp;
              {formatDate(meetingDetails?.date)}
            </p>
          </div>
          <div className="flex gap-3 m-2 text-gray-500">
            <img src={mapPointIcon} className="w-6 h-6" alt="Map Point" />
            <p>{meetingDetails?.address}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
