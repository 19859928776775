import React, { useState, useEffect } from "react";
import {
  Button,
  Chip,
  Tooltip,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  ChipProps,
} from "@nextui-org/react";
import { Check, Contact, Info, Loader, Upload, Eye } from "lucide-react";
import axios from "axios";
import ToolTip from "../../../assets/Icons/ToolTip.svg";


interface DocumentItem {
  id: string;
  status: "Verifying" | "Pending" | "Uploaded" | "Approved" | "Rejected";
  label: string;
  icon: React.ReactNode;
  tooltipText?: string;
  document_url?: string;
}

interface UserData {
  id: number;
  name: string;
  email: string;
  phone_number: string;
  phoneVerified: number;
  vehicle_onboarding: number;
  isAdmin: boolean;
}

interface FetchedDocument {
  document_type: string;
  document_url: string;
  document_name: string;
  status: "Verifying" | "Pending" | "Uploaded";
}

const UserDocs: React.FC = ({}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [selectedDocument, setSelectedDocument] = useState<DocumentItem | null>(
    null
  );
  const [file, setFile] = useState<File | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [, setMessage] = useState<string>("");
  const [documents, setDocuments] = useState<DocumentItem[]>([]);

  useEffect(() => {
    const storedUser = localStorage.getItem("onewheel_user");
    if (storedUser) {
      const userData: { user: UserData } = JSON.parse(storedUser);
      setUserId(userData.user.id);
      fetchDocuments(userData.user.id);
    }
  }, []);

  const fetchDocuments = async (id: number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/fetchdocuments/${id}`
      );
      const fetchedDocuments: FetchedDocument[] = response.data.documents;

      const updatedDocuments: DocumentItem[] = [
        {
          id: "user1",
          status: "Pending",
          label: "Driving License",
          icon: <Contact size={16} />,
          tooltipText: "Driving License is required to verify your identity",
        },
        {
          id: "user2",
          status: "Pending",
          label: "Government ID",
          icon: <Contact size={16} />,
          tooltipText: "Government ID is required to verify your identity",
        },
        {
          id: "user3",
          status: "Pending",
          label: "Vehicle Registration",
          icon: <Contact size={16} />,
        },
      ];

      fetchedDocuments.forEach((doc) => {
        const index = updatedDocuments.findIndex(
          (item) => item.label === doc.document_name
        );
        if (index !== -1) {
          updatedDocuments[index].status = doc.status;
          updatedDocuments[index].document_url = doc.document_url;
        }
      });

      setDocuments(updatedDocuments);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleUpload = (document: DocumentItem) => {
    setSelectedDocument(document);
    onOpen();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!file || !userId || !selectedDocument) {
      setMessage("All fields are required");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("userId", userId.toString());
    formData.append("documentType", selectedDocument.label);
    formData.append("documentName", selectedDocument.label);

    setIsUploading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/upload-document`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setMessage(response.data.message);
      fetchDocuments(userId);
    } catch (error) {
      setMessage("Failed to upload document");
      console.error("Error uploading document:", error);
    } finally {
      setIsUploading(false);
      onOpenChange();
      setFile(null);
    }
  };

  const handleView = (documentUrl: string) => {
    window.open(documentUrl, "_blank");
  };
  const getChipProps = (status: DocumentItem["status"]): ChipProps => {
    switch (status) {
      case "Verifying":
        return {
          color: "warning",
          children: (
            <>
              <Loader size={12} />
              Verifying
            </>
          ),
        };
      case "Pending":
        return {
          color: "danger",
          children: (
            <>
              <Info size={12} />
              Pending
            </>
          ),
        };
      case "Uploaded":
        return {
          color: "primary",
          children: (
            <>
              <Upload size={12} />
              Uploaded
            </>
          ),
        };
      case "Approved":
        return {
          color: "success",
          children: (
            <>
              <Check size={12} />
              Approved
            </>
          ),
        };
      case "Rejected":
        return {
          color: "danger",
          children: (
            <>
              <Check size={12} />
              Rejected
            </>
          ),
        };
      default:
        return {
          color: "default",
          children: "Unknown Status",
        };
    }
  };

  return (
    <div className="upload-items">
      <div className="upload-items-table">
        <table>
          <thead>
            <tr>
              <th>
                <p>Document</p>
              </th>
              <th>
                <p>Status</p>
              </th>
              <th style={{ textAlign: "right" }}>
                <p>Action</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {documents.map((item) => (
              <tr key={item.id}>
                <td className="name-td">
                  <div className="label-doc">
                    {item.icon}
                    <p>{item.label}</p>
                    {item.tooltipText && (
                      <Tooltip content={item.tooltipText} size="sm">
                        <img src={ToolTip} alt="" />
                      </Tooltip>
                    )}
                  </div>
                </td>
                <td>
                  <Chip
                    size="sm"
                    variant="flat"
                    className="doc-chip"
                    {...getChipProps(item.status)}
                  />
                </td>
                <td
                  style={{
                    textAlign: "right",
                    display: "flex",
                    marginLeft: "auto",
                    width: "fit-content",
                  }}
                >
                  {item.document_url && (
                    <div
                      onClick={() => handleView(item.document_url!)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                    >
                      <Eye size={14} />
                      View
                    </div>
                  )}
                  <Button
                    color="default"
                    size="sm"
                    style={{
                      border: "1.1px solid rgba(0, 0, 0, 0.4)",
                      transform: "scale(0.85)",
                      transformOrigin: "right",
                      minWidth: "110px",
                      marginRight: "5px",
                    }}
                    variant="bordered"
                    onClick={() => handleUpload(item)}
                  >
                    <Upload size={14} />
                    {item.status === "Pending" ? "Upload" : "Re-upload"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <form onSubmit={handleSubmit}>
              <ModalHeader>{selectedDocument?.label}</ModalHeader>
              <ModalBody>
                <input type="file" onChange={handleFileChange} required />
                {/* {message && <p>{message}</p>} */}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onClick={onClose}>
                  Cancel
                </Button>
                <Button color="primary" type="submit" isLoading={isUploading}>
                  {isUploading ? "Uploading..." : "Upload"}
                </Button>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default UserDocs;
