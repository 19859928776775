import { useEffect, useState } from "react";
import MainCalendar from "./MainCalendar";
import TimeSlot from "./TimeSlot";

interface CalendarProps {
  onSelectDate: (date: string) => void;
  isVisible: boolean;
  changeFormVisibility: any;
  availableDates: any;
  availableTimeSlots: any;
  setTheSlotDataForMobile: (data: any,formattedDate:any) => void;
}

const Calendar: React.FC<CalendarProps> = ({
  onSelectDate,
  isVisible,
  changeFormVisibility,
  availableDates,
  availableTimeSlots,
  setTheSlotDataForMobile,
}) => {
  const [showTimeSlot, setShowTimeSlot] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [slotData, setSlotData] = useState<any>([]);
  const [dateFormatted,setDateFormatted] = useState<any>(null);
  // Update the handleChildAction function to set the selected date and notify parent component
  const handleChildAction = (date: string) => {
    console.log("child action date",date);
    const dateParts = date.replace("Selected date: ", "").split(" ");

    const day = parseInt(dateParts[1], 10); // Extract day
    const monthName = dateParts[2]; // Extract month name
    const year = new Date().getFullYear(); // Assuming current year

    // Create a map to convert month names to month numbers
    const monthMap: { [key: string]: number } = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };

    const month = monthMap[monthName]; // Get month number from month name

    // Create a new Date object with the extracted day, month, and year
    const selectedDateWithSlotsFormat = new Date(year, month, day);

    // Format the date as yyyy-mm-dd
    const formattedDate = `${selectedDateWithSlotsFormat.getFullYear()}-${String(
      month + 1
    ).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
    console.log("selected date with slot format", formattedDate);
    setDateFormatted(formattedDate);
    // getAvailableTimeSlotsForSelectedDate(formattedDate);
    setSlotData(availableTimeSlots?.[formattedDate]);
    setTheSlotDataForMobile(availableTimeSlots?.[formattedDate],formattedDate);
    setSelectedDate(date); // Update the selected date locally
    setShowTimeSlot(true); // Show TimeSlot component
    onSelectDate(date); // Notify parent component (ScheduleMeeting) about the selected date
  };

  return (
    <div className="h-full flex flex-col md:flex-row p-3 md:py-16 md:px-5 justify-center overflow-y-scroll scrollbar-hide">
      <div className="flex w-full md:w-[500px] flex-col gap-5 justify-start items-center">
        <h2 className="text-black font-bold my-5 text-2xl">
          Select a Date & Time
        </h2>
        <MainCalendar
          triggerParentUpdate={handleChildAction}
          availableDates={availableDates}
        />
        {/* Passing the date selection function */}
      </div>

      {/* Conditionally render TimeSlot component based on date selection */}
      {showTimeSlot && (
        <TimeSlot
          date={selectedDate}
          isVisible={isVisible}
          changeFormVisibility={changeFormVisibility}
          slotData={slotData}
          formattedDate={dateFormatted}
        />
      )}
    </div>
  );
};

export default Calendar;
