import { SidebarItem } from "./sidebar";
// import TeamAvatar from "./team-avatar";

/**
 * Please check the https://nextui.org/docs/guide/routing to have a seamless router integration
 */

export const items: SidebarItem[] = [
  {
    key: "vehicles",
    href: "#",
    icon: "solar:home-2-linear",
    title: "Vehicles",
  },
  {
    key: "users",
    href: "#",
    icon: "solar:users-group-two-rounded-outline",
    title: "Users",
  },
];

export const sectionItems: SidebarItem[] = [
  {
    key: "overview",
    title: "Overview",
    items: [
      {
        key: "Listings",
        href: "/admin/listings",
        icon: "solar:list-outline",
        title: "Listings",
      },
      {
        key: "users",
        href: "/admin/users",
        icon: "solar:users-group-two-rounded-outline",
        title: "Users",
      },
      {
        key: "Vehicles",
        href: "/admin/vehicles",
        icon: "solar:home-2-linear",
        title: "Vehicles",
      }
    ],
  },
  {
    key: "organization",
    title: "Organization",
    items: [
      {
        key: "analytics",
        href: "#",
        icon: "solar:chart-outline",
        title: "Analytics",
      },
      {
        key: "expenses",
        href: "#",
        icon: "solar:bill-list-outline",
        title: "Expenses",
      },
      {
        key: "settings",
        href: "/settings",
        icon: "solar:settings-outline",
        title: "Settings",
      },
    ],
  },
];

export const sectionItemsWithTeams: SidebarItem[] = [...sectionItems];
