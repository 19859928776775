import React, { useState, useEffect } from "react";
import {
  Button,
  Chip,
  Tooltip,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import { Check, Info, Loader, Upload, Eye } from "lucide-react";
import axios from "axios";
import ToolTip from "../../../assets/Icons/ToolTip.svg";


interface DocumentItem {
  id: string;
  status: "Verifying" | "Pending" | "Uploaded" | "Approved" | "Rejected";
  label: string;
  tooltipText?: string;
  document_url?: string;
}

const BankingInfo: React.FC = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [selectedDocument, setSelectedDocument] = useState<DocumentItem | null>(
    null
  );
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [, setMessage] = useState<string>("");
  const [documents, setDocuments] = useState<DocumentItem[]>([
    {
      id: "bank-statement",
      status: "Pending",
      label: "Bank Statement",
      tooltipText: "Upload your latest bank statement",
    },
  ]);

  const handleUpload = (document: DocumentItem) => {
    setSelectedDocument(document);
    onOpen();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!file || !selectedDocument) {
      setMessage("All fields are required");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentType", selectedDocument.label);

    setIsUploading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/upload-document`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setMessage(response.data.message);
      // You can update the document status based on response
      fetchDocuments();
    } catch (error) {
      setMessage("Failed to upload document");
    } finally {
      setIsUploading(false);
      onOpenChange();
      setFile(null);
    }
  };

  const fetchDocuments = async () => {
    // Fetch updated document statuses here
  };

  const handleView = (documentUrl: string) => {
    window.open(documentUrl, "_blank");
  };

  return (
    <div className="upload-items">
      <div className="upload-items-table">
        <table>
          <thead>
          <tr>
              <th>
                <p>Document</p>
              </th>
              <th>
                <p>Status</p>
              </th>
              <th style={{ textAlign: "center", }}>
                <p>Action</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {documents.map((item) => (
              <tr key={item.id}>
                <td>
                  <div className="label-doc">
                    <p>{item.label}</p>
                    {item.tooltipText && (
                      <Tooltip content={item.tooltipText} size="sm">
                        <img src={ToolTip} alt="" />
                      </Tooltip>
                    )}
                  </div>
                </td>
                <td>
                  <Chip size="sm" color="warning" variant="flat">
                    <Loader size={12} />
                    {item.status}
                  </Chip>
                </td>
                <td>
                  {item.document_url && (
                    <div
                      onClick={() => handleView(item.document_url!)}
                      style={{ cursor: "pointer" }}
                    >
                      <Eye size={14} />
                      View
                    </div>
                  )}
                  <Button
                    color="default"
                    size="sm"
                    variant="bordered"
                    onClick={() => handleUpload(item)}
                  >
                    <Upload size={14} />
                    {item.status === "Pending" ? "Upload" : "Re-upload"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <form onSubmit={handleSubmit}>
              <ModalHeader>{selectedDocument?.label}</ModalHeader>
              <ModalBody>
                <input type="file" onChange={handleFileChange} required />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onClick={onClose}>
                  Cancel
                </Button>
                <Button color="primary" type="submit" isLoading={isUploading}>
                  {isUploading ? "Uploading..." : "Upload"}
                </Button>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default BankingInfo;
