import React, { useEffect, useState } from "react";
import { domAnimation, LazyMotion, m } from "framer-motion";
import "./new-form.css";
import Tick from "../../assets/Images/Tick.svg";
import ValueDetails from "./components/ValueDetails";
import BasicDetails from "./components/BasicDetails";
import TellUsMore from "./components/tellusmore";
import MultistepNavigationButtons from "./components/multistep-navigation-buttons";
import { useLocation } from "react-router-dom";
import { fetchFromLocalStorage } from "../../utils/localStorageUtil.ts";
import MultiStepSidebar from "./components/multistep-sideba";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Progress,
  useDisclosure,
} from "@nextui-org/react";
import { validateForm } from "../../utils/formValidation";
import AlreadyListed from "../../modals/AlreadyListed";
interface VehicleDetails {
  location: string;
  postcode_suburb: string;
  owner: string;
  vehicle_name: string;
  number_plate: string;
  year: string;
  km: string;
  dealership: string;
  fees: string;
  potential_saving: string;
  private_sale: string;
  state: string;
  avg_sell_time: string;
  vehicle_id: string,
}
interface User {
  vehicle_id?: string;
  // You can add other properties of the user object here if necessary
}

interface OnewheelUser {
  user: User;
  // You can add other properties of onewheel_user if necessary
}

const variants = {
  enter: (direction: number) => ({
    y: direction > 0 ? 30 : -30,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    zIndex: 0,
    y: direction < 0 ? 30 : -30,
    opacity: 0,
  }),
};

export default function VehicleOnboarding2() {
  const [listingData, setListingData] = useState(null);
  const [error, setError] = useState(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const [progressValue, setProgressValue] = React.useState(0);
  const fetchFromLocalStorage = (key: any) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  };

  const userId = fetchFromLocalStorage("onewheel_user");
  console.log("userId", userId?.user?.id); // Safe access with optional chaining
  const [isAlreadyListed, serIsAlreadyListed] = React.useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      if (!userId?.user?.id) {
        // setError();
        return;
      }

      try {
        const params = new URLSearchParams(window.location.search);
        const vehicleId: string | null = params.get("vehicleId");

        // 2. Retrieve onewheel_user from localStorage
        const storedUser: string | null = localStorage.getItem("onewheel_user");
        let onewheelUser: OnewheelUser | null = storedUser
          ? JSON.parse(storedUser)
          : null;
        
          console.log("vehicle id one wheel user",vehicleId,onewheelUser);
        // 3. Check if vehicleId is not null and onewheel_user and user object exist
        if (vehicleId && onewheelUser && onewheelUser.user) {
          // Store the vehicle_id inside the user object
          onewheelUser.user.vehicle_id = vehicleId as string; // Assert vehicleId as string

          // 4. Update the localStorage with the new object
          localStorage.setItem("onewheel_user", JSON.stringify(onewheelUser));
        } else {
          console.error("vehicle_id or onewheel_user is missing or invalid");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/vehicle/fetchlisting/${userId.user.id}`
        );
        console.log("response", response);
        if (response.data.length > 0) {
          console.log("vehicle already listed");
          serIsAlreadyListed(true);
        }
        setListingData(response.data);
      } catch (error) {
        console.log("Error fetching listing details:", error);
      }
    };

    fetchData();
  }, []);

  const [[page, direction], setPage] = React.useState([0, 0]);
  const [validationErrors, setValidationErrors] = useState<any>({});

  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [, setVehicleId] = useState<string | null>(null);
  const [vehicleDetails, setVehicleDetails] = useState<VehicleDetails | null>(
    null
  );

  const [formData, setFormData] = useState({
    location: vehicleDetails?.location || "",
    owner: vehicleDetails?.owner || "",
    vehicleName: "",
    postcode_suburb: "",
    numberPlate: vehicleDetails?.number_plate || "",
    year: vehicleDetails?.year || "",
    km: vehicleDetails?.km || "",
    ownership: "",
    drivingType: "",
    serviceHistory: "",
    keys: "",
    moneyOwed: "",
    damage: "",
    modifications: "",
    sellingReason: "",
    conditionRating: "",
    reservePrice: "",
    desiredOutcome: "",
    dealership: vehicleDetails?.dealership || "",
    privateSale: vehicleDetails?.private_sale || "",
    avgSellTime: "",
    agreeToTerms: false,
    agreeToPay: false,
    confirmOwnership: false,
    vehicle_id: "",
  });

  console.log("formData", formData);

  const fetchVehicleDetails = async (vehicleIdParam: string) => {
    try {
      // Extract user ID from local storage
      const storedUser = localStorage.getItem("onewheel_user");
      const userId = storedUser ? JSON.parse(storedUser)?.user?.id : null;

      if (!userId) {
        throw new Error("User ID not found in local storage.");
      }

      // Send user ID in the query params
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/fetchAndUpdatePreOnboarding/${vehicleIdParam}?user_id=${userId}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.data) {
        setVehicleDetails(data.data);
        paginate(1);
      } else {
        console.warn("No data found for the vehicle ID.");
      }
    } catch (error) {
      console.error("Error fetching vehicle details:", error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const vehicleIdParam = params.get("vehicleId");
    console.log("is value analyser use effect called", vehicleIdParam);
    if (vehicleIdParam) {
      setVehicleId(vehicleIdParam);
      console.log("is value analyser called");
      fetchVehicleDetails(vehicleIdParam);
    }
  }, [location.search]);

  // Effect for updating formData when vehicleDetails change
  useEffect(() => {
    if (vehicleDetails) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        location: vehicleDetails.location,
        km: vehicleDetails.km,
        numberPlate: vehicleDetails.number_plate,
        year: vehicleDetails.year,
        vehicleName: vehicleDetails.vehicle_name,
        dealership: vehicleDetails.dealership,
        privateSale: vehicleDetails.private_sale,
        avgSellTime: vehicleDetails.avg_sell_time,
      }));
    }
  }, [vehicleDetails]);

  const paginate = React.useCallback((newDirection: number) => {
    setPage((prev) => {
      const nextPage = prev[0] + newDirection;

      if (nextPage < 0 || nextPage > 3) return prev;

      return [nextPage, newDirection];
    });
  }, []);

  const onChangePage = React.useCallback((newPage: number) => {
    setPage((prev) => {
      if (newPage < 0 || newPage > 3) return prev;
      const currentPage = prev[0];

      return [newPage, newPage > currentPage ? 1 : -1];
    });
  }, []);

  const onBack = React.useCallback(() => {
    paginate(-1);
  }, [paginate]);

  const [, setIsModalOpen] = useState(false);

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  // const [modalPlacement, setModalPlacement] = React.useState("auto");

  const handleSubmit = async () => {
    console.log("called handleSubmit", validationErrors);

    const userDetailString = localStorage.getItem("onewheel_user");

    // Check if userDetailString is not null before parsing
    if (userDetailString !== null) {
        try {
            const userDetail: OnewheelUser = JSON.parse(userDetailString); // Parse the JSON string into an object
            // Check for vehicleId from both sources
            const vehicleIdFromLocalStorage = userDetail.user?.vehicle_id; // Access the vehicle_id property safely
            const vehicleIdFromFormData = formData.vehicle_id; // Get vehicle_id from formData
            const vehicleId = vehicleIdFromLocalStorage || vehicleIdFromFormData; // Use whichever is available

            console.log("vehicle id from local storage:", vehicleIdFromLocalStorage);
            console.log("vehicle id from form data:", vehicleIdFromFormData);
            console.log("final vehicle id used:", vehicleId);

            // Validate form before proceeding
            if (!validateForm(page, formData, setValidationErrors)) {
                return "Form is invalid";
            }

            setIsLoading(true);

            const payload = {
                vehicleId: vehicleId,
                userId: fetchFromLocalStorage("onewheel_user").user.id, 
                postcode_suburb: formData.postcode_suburb,
                vehicleName: formData.vehicleName,
                numberPlate: formData.numberPlate,
                state: vehicleDetails?.location || "",
                location: formData.location,
                year: formData.year,
                km: formData.km,
                ownership: formData.ownership,
                drivingType: formData.drivingType,
                serviceHistory: formData.serviceHistory,
                both_keys: formData.keys,
                moneyOwed: formData.moneyOwed,
                damage: formData.damage,
                modifications: formData.modifications,
                sellingReason: formData.sellingReason,
                conditionRating: formData.conditionRating,
                reservePrice: formData.reservePrice,
                desiredOutcome: formData.desiredOutcome,
                avgSellTime: formData.avgSellTime,
                dealership: vehicleDetails?.dealership || "",
                privateSale: vehicleDetails?.private_sale || "",
                fees: vehicleDetails?.fees || "",
                potentialSaving: vehicleDetails?.potential_saving || "",
                agreeToTerms: formData.agreeToTerms,
                confirmOwnership: formData.confirmOwnership,
                agreeToPay: formData.agreeToPay,
            };

            console.log("Payload:", payload);

            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/vehicle/saveVehicle`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(payload),
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    if (data.success === true) {
                        setIsLoading(false);
                        setIsModalOpen(true);
                        onOpen();
                        const interval = setInterval(() => {
                          setProgressValue((v) => {
                            if (v >= 100) {
                              clearInterval(interval);
                              window.location.href = window.location.origin; // Redirect after 100%
                              return 0;
                            }
                            return v + 25;
                          });
                        }, 500);
                      }
                } else {
                    console.error("Failed to save vehicle");
                }
            } catch (error) {
                console.error("Error saving vehicle:", error);
            }
        } catch (error) {
            console.error("Error parsing onewheel_user from localStorage:", error);
        }
    } else {
        console.error("onewheel_user not found in localStorage");
    }
};


  const navigate = useNavigate();
  const content = React.useMemo(() => {
    let component = (
      <ValueDetails
        formData={formData}
        setFormData={setFormData}
        // handleChange={handleChange}
        validationErrors={validationErrors}
        vehicleDetails={vehicleDetails}
        apiError={apiError}
      />
    );

    switch (page) {
      case 1:
        component = (
          <BasicDetails
            formData={formData}
            setFormData={setFormData}
            // handleChange={handleChange}
            validationErrors={validationErrors}
          />
        );
        break;

      case 2:
        component = (
          <TellUsMore
            formData={formData}
            setFormData={setFormData}
            // handleChange={handleChange}
            validationErrors={validationErrors}
            vehicleDetails={vehicleDetails}
          />
        );
        break;
    }

    return (
      <LazyMotion features={domAnimation}>
        <m.div
          key={page}
          animate="center"
          className="col-span-12"
          custom={direction}
          exit="exit"
          initial="exit"
          transition={{
            y: {
              ease: "backOut",
              duration: 0.35,
            },
            opacity: { duration: 0.4 },
          }}
          variants={variants}
        >
          {component}
        </m.div>
      </LazyMotion>
    );
  }, [direction, page, formData, validationErrors, vehicleDetails]);

  // console.log("Page:", page);
  const fetchFromAnalysers = async () => {
    // try {

    //   setApiError(null); // Clear any previous errors
    //   const response = await axios.get(
    //     `${process.env.REACT_APP_BASE_URL}/api/vehicle/detailsApp/${formData.numberPlate}/${formData.location}/${formData.km}`
    //   );
    //   const data = response.data;
    //   console.log("Vehicle details:", data[0]);

    //   if (data[0]) {
    //     setFormData({
    //       ...formData,
    //       location: data[0].Location,
    //       vehicleName: data[0].Vehicle_Name,
    //       avgSellTime: data[0].Avg_Sell_Time,
    //       numberPlate: formData.numberPlate,
    //       year: data[0].year,
    //       km: data[0].kms,
    //       dealership: data[0].Dealership,
    //       privateSale: data[0].Private_Sale,
    //     });
    //     paginate(1);
    //   } else {
    //     setApiError("No vehicle details found for the provided registration number.");
    //   }
    // } catch (error) {
    //   console.log(axios.isAxiosError(error) && error.response?.data.message);

    //   console.error("Error fetching vehicle details:", error);
    //   if (axios.isAxiosError(error) && error.response?.status === 500) {
    //     console.log("Invalid registration number");

    //     setApiError("Invalid registration number. Please check and try again.");
    //   } else {
    //     setApiError("An error occurred while fetching vehicle details. Please try again later.");
    //   }
    // }
    try {
      setApiError(null); // Clear any previous errors

      // Check if the local storage has the key 'onewheel_user'
      const onewheelUser = localStorage.getItem("onewheel_user");

      if (onewheelUser) {
        // Parse the user object from local storage
        const user = JSON.parse(onewheelUser);

        // Extract the user ID from the object
        console.log("local data", user);
        const userId = user?.user?.id; // Adjust according to your actual object structure

        // Make the API request with the user ID in the URL params
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/vehicle/detailsApp/${formData.numberPlate}/${formData.location}/${formData.km}?userId=${userId}`
        );

        const data = response.data;
        console.log("Vehicle details:", data[0]);

        if (data[0]) {
          setFormData({
            ...formData,
            location: data[0].Location,
            vehicleName: data[0].Vehicle_Name,
            avgSellTime: data[0].Avg_Sell_Time,
            numberPlate: formData.numberPlate,
            year: data[0].year,
            km: data[0].kms,
            dealership: data[0].Dealership,
            privateSale: data[0].Private_Sale,
            ...(data[0].vehicle_id ? { vehicle_id: data[0].vehicle_id } : {}),
          });
          paginate(1);
        } else {
          setApiError(
            "No vehicle details found for the provided registration number."
          );
        }
      } else {
        setApiError("User not found in local storage.");
      }
    } catch (error) {
      setApiError("An error occurred while fetching vehicle details.");
      console.error("Error fetching vehicle details:", error);
    }
  };

  // console.log("formData", formData);

  return (
    <MultiStepSidebar
      formData={formData}
      vehicleDetails={vehicleDetails}
      currentPage={page}
      onBack={onBack}
      onChangePage={onChangePage}
      onNext={() => {
        if (validateForm(page, formData, setValidationErrors)) {
          // console.log("inside validateForm");
          if (page === 2) {
            handleSubmit(); // Submit on last page
            // console.log("res", res);
          } else {
            if (page === 0) {
              if (!vehicleDetails) fetchFromAnalysers();
              else {
                paginate(1);
              }
              // onOpen();
            } else {
              paginate(1);
            }
          }
        } else {
          // console.log("validation errors");
        }
      }}
    >
      <AlreadyListed
        isOpen={isAlreadyListed}
        onClose={() => serIsAlreadyListed(false)}
      />
      {/* <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="model-content">
            <img src={Tick} alt="" />
            <h1>
              Thanks! We now have all of the details we <br /> need to list your
              car
            </h1>
            <p>
              Please remember to send all vehicle photos to: <br />
              damian.lee@1wheel.com.au or 0400 481 110
            </p>
            <button
              onClick={() => {
                navigate("/vehicle-onboarding");
                setIsModalOpen(false);
                window.location.reload();
              }}
            >
              Done
            </button>
          </div>
        </Box>
      </Modal> */}

      <Modal isOpen={isOpen} placement={"auto"} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose: any) => (
            <>
              <div className="modal-ui">
              {/* <Progress
                size="md"
                value={progressValue}
                color="success"
                className="max-w-md mb-2"
              /> */}
                {/* // img */}
                <div className="modal-tick">
                  <img src={Tick} alt="" />
                </div>
                <ModalHeader className="flex flex-col gap-1">
                  Thanks! We now have most of the details we need to list your
                  vehicle.
                </ModalHeader>
                <ModalBody>
                  <p className="mb-3">
                    Lets go to the next step and upload the photos of your
                    vehicle and other documents required to list your vehicle.
                  </p>
                </ModalBody>
              </div>
                <Progress
                size="md"
                value={progressValue}
                color="success"
                className="max-w-md"
              />
            </>
          )}
        </ModalContent>
      </Modal>
      <div className="relative flex h-fit w-full flex-col pt-6 text-center lg:h-full lg:justify-center lg:pt-0">
        {content}
        <MultistepNavigationButtons
          backButtonProps={{ isDisabled: page === 0 }}
          className="submit-btn hidden justify-start lg:flex text-white"
          nextButtonProps={{
            children: page === 0 ? "NEXT" : page === 1 ? "NEXT" : "SUBMIT",
          }}
          onBack={onBack}
          onNext={() => {
            if (validateForm(page, formData, setValidationErrors)) {
              // console.log("inside validateForm");

              if (page === 2) {
                handleSubmit(); // Submit on last page
                // console.log("res", res);
              } else {
                if (page === 0) {
                  if (!vehicleDetails) fetchFromAnalysers();
                  else {
                    paginate(1);
                  }
                  // onOpen();
                  // Move to the next page
                } else {
                  paginate(1);
                }
              }
            } else {
              // console.log("validation errors");
            }
          }}
          isLoading={isLoading}
        />
      </div>
    </MultiStepSidebar>
  );
}
