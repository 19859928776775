import React from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";

interface LowerPriceModalProps {
  isOpen: boolean;
  onClose: () => void;
  lowerPrice: string | number;
  setLowerPrice: React.Dispatch<React.SetStateAction<string | number>>;
  handleLowerReservePrice: () => void;
  isLoading: boolean;
}

const LowerReservePriceModal: React.FC<LowerPriceModalProps> = ({
  isOpen,
  onClose,
  lowerPrice,
  setLowerPrice,
  handleLowerReservePrice,
  isLoading,
}) => {
  // Convert newPrice to an absolute integer for display
  const displayValue = Math.abs(parseInt(lowerPrice.toString(), 10));
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      style={{
        position: "absolute",
        top: "20%",
        width: "90%",
      }}
    >
      <ModalContent>
        <ModalHeader>Lower Reserve Price</ModalHeader>
        <ModalBody>
          <div className="mb-4">
            <label
              htmlFor="newPrice"
              className="block text-sm font-medium text-gray-700"
            >
              New Reserve Price
            </label>
            <input
              type="number"
              value={displayValue}
              style={{
                borderColor: "#eee",
                borderWidth: "1.1px",
                borderRadius: "5px",
                padding: "0.5rem",
                width: "100%",
              }}
              onChange={(e) => {
                // Ensure the input value is a non-negative integer
                const value = parseInt(e.target.value, 10);
                setLowerPrice(isNaN(value) ? 0 : Math.abs(value));
              }}
              placeholder="Enter new reserve price"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          {/* <Button onClick={onClose} style={{ marginRight: "10px" }}>
            Cancel
          </Button> */}
          {isLoading ? (
            <Button fullWidth isLoading className="bg-[#ff9800] text-white">
              Loading
            </Button>
          ) : (
            <Button
              fullWidth
              onClick={() => {
                handleLowerReservePrice();
              }}
              className="bg-[#ff9800] text-white"
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LowerReservePriceModal;
