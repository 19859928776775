import React from "react";
import { Tabs, Tab } from "@nextui-org/react";
import UserDocs from "./UserDocs";
import BankingInfo from "./BankingInfo";
import ServiceHistory from "./ServiceHistory";
import TransferDocs from "./TransferDocs";

const DocumentUpload = () => {
  let tabs = [
    {
      id: "user",
      label: "User Docs.",
      content: "",
      component: UserDocs,
    },
    {
      id: "service",
      label: "Service History",
      content:
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      component: ServiceHistory,
    },
    {
      id: "banking",
      label: "Banking Info.",
      content:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      component: BankingInfo,
    },
    {
      id: "tranfer",
      label: "Others",
      content:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      component: TransferDocs,
    },
  ];
  return (
    <div className="document-upload">
      <Tabs
        aria-label="Dynamic tabs"
        items={tabs}
        size="sm"
        radius="sm"
        variant="light"
        className="tabs-documents"
      >
        {(item) => (
          <Tab key={item.id} title={item.label}>
            <item.component  />
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default DocumentUpload;
