import React, { useEffect, useState } from "react";
import CarInfo from "./ScheduleComponents/CarInfo";
import Calendar from "./ScheduleComponents/Calender";
import TimeSlot from "./ScheduleComponents/TimeSlot";
import { Button } from "@nextui-org/react";
import UserDetailForm from "./ScheduleComponents/UserDetailForm";
import { IoArrowBack } from "react-icons/io5";
import axios from "axios";
import { useParams } from "react-router-dom";

const ScheduleMeeting = () => {
  const { uniqueId } = useParams();
  const [selectedDate, setSelectedDate] = useState<string | null>(null); // Tracks the selected date
  const [showTimeSlots, setShowTimeSlots] = useState(false); // Manages visibility of time slots on mobile
  const [showForm, setShowForm] = useState<boolean>(false);
  const [vehicleData, setVehicleData] = useState<any>(null);
  const [availableDates, setAvailableDates] = useState<any>([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<any>({});
  const [slotDataForMobile, setSlotDataForMobile] = useState<any>([]);
  const [finalSelectedData, setFinalSelectedData] = useState<any>([]);
  const [location, setLocation] = useState<any>(null);
  const [formattedDate,setFormattedDate] = useState<any>(null);

  // Function to handle date selection from the calendar
  const handleDateSelect = (date: string) => {
    setSelectedDate(date);
    setShowTimeSlots(true); // Show time slots when a valid date is selected
  };

  // Function to handle back button click to show calendar and car info again
  const handleBackClick = () => {
    setShowTimeSlots(false);
    setSelectedDate(null); // Clear the selected date
  };

  // Fetch user availability
  // const getUserAvailability = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/api/availability/${uniqueId}`
  //     );
  //     const { data } = response;

  //     if (response.status === 200) {
  //       console.log(
  //         "Data retrieved successfully:",
  //         data?.data
  //       );
  //       setLocation(data?.data?.availability?.[0]?.location);
  //       setVehicleData(data?.data?.vehicles[0]);
  //       const availability = data?.data?.availability?.[0]?.time_slots;
  //       setAvailableDates(availability.map((item: any) => item.date)); // Directly get available dates
  //       const slotsByDate = availability.reduce((acc: any, item: any) => {
  //         console.log("Item:", item);
          
  //         // Check if the date already exists in the accumulator
  //         if (!acc[item.date]) {
  //           // If not, initialize it as an array
  //           acc[item.date] = [];
  //         }
          
  //         // Push the current item (time slot) into the array for that date
  //         acc[item.date].push({
  //           availability_id: item.availability_id,
  //           start_time: item.start_time,
  //           end_time: item.end_time,
  //           status: item.status,
  //         });
        
  //         return acc;
  //       }, {});
  //       console.log("Slots by date:", slotsByDate);
  //       setAvailableTimeSlots(slotsByDate); // Set the time slots keyed by date
  //     }
  //   } catch (error: any) {
  //     if (error.response) {
  //       console.error("Error response:", error.response.data);
  //     } else if (error.request) {
  //       console.error("Error request:", error.request);
  //     } else {
  //       console.error("Error:", error.message);
  //     }
  //   }
  // };
  const getUserAvailability = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/availability/${uniqueId}`
      );
      const { data } = response;
  
      if (response.status === 200) {
        console.log("Data retrieved successfully:", data?.data);
  
        // Combine all location data from each availability item
        const locations = data?.data?.availability?.map((avail: any) => avail.location);
        setLocation(locations[0]); // Set location as an array of all locations
  
        // Set vehicle data (assuming vehicles remain the same for all availabilities)
        setVehicleData(data?.data?.vehicles[0]);
  
        // Get all time slots from each availability
        const allTimeSlots = data?.data?.availability?.flatMap((avail: any) => avail.time_slots);
  
        // Extract available dates from all time slots
        const availableDates = allTimeSlots.map((item: any) => item.date);
        setAvailableDates(availableDates); // Set available dates from all time slots
  
        // Group time slots by date
        const slotsByDate = allTimeSlots.reduce((acc: any, item: any) => {
          // Check if the date already exists in the accumulator
          if (!acc[item.date]) {
            // If not, initialize it as an array
            acc[item.date] = [];
          }
  
          // Push the current item (time slot) into the array for that date
          acc[item.date].push({
            availability_id: item.availability_id,
            start_time: item.start_time,
            end_time: item.end_time,
            status: item.status,
          });
  
          return acc;
        }, {});
  
        console.log("Slots by date:", slotsByDate);
        setAvailableTimeSlots(slotsByDate); // Set the time slots keyed by date
      }
    } catch (error: any) {
      if (error.response) {
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        console.error("Error request:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };
  useEffect(() => {
    getUserAvailability();
  }, []);

  return (
    <div className="schedule-meeting w-full lg:max-h-screen lg:h-screen flex sm:items-center sm:justify-center bg-[#f9f9f9] lg:bg-white">
      <div className="modal-meeting w-full lg:w-auto lg:drop-shadow-2xl lg:border-[1px] lg:shadow-xl border-slate-400 lg:bg-[#f9f9f9] flex flex-col lg:flex-row justify-between">
        <div
          className={`${
            showTimeSlots && !showForm && "hidden lg:flex"
          }  flex flex-col lg:flex-row`}
        >
          <CarInfo
            changeFormVisibility={() => {
              setShowForm(false);
            }}
            isButtonVisible={showForm}
            vehicleData={vehicleData}
            location={location}
          />
          <div className="w-full h-[1px] sm:w-[1px] bg-[#E0CDCD] lg:h-auto"></div>
          {showForm ? (
            <div className="hidden lg:flex">
              <UserDetailForm
                finalSelectedData={finalSelectedData}
                vehicleData={vehicleData}
                location={location}
              />
            </div>
          ) : (
            <Calendar
              onSelectDate={handleDateSelect}
              isVisible={showTimeSlots}
              changeFormVisibility={(data: any) => {
                setShowForm(true);
                setFinalSelectedData(data);
              }}
              availableDates={availableDates}
              availableTimeSlots={availableTimeSlots}
              setTheSlotDataForMobile={(data: any,formattedDate:any) =>
              {
                console.log("data for mobile and date",data,formattedDate);
                setSlotDataForMobile(data);
                setFormattedDate(formattedDate);
              }
              }
            />
          )}
        </div>
        {showForm ? (
          <div className="flex lg:hidden">
            <UserDetailForm
              finalSelectedData={finalSelectedData}
              vehicleData={vehicleData}
              location={location}
            />
          </div>
        ) : showTimeSlots ? (
          <div className="flex flex-col lg:hidden">
            <div className="w-full flex items-center mb-5 p-4 relative">
              {/* Back button to go back to the calendar */}
              <Button
                isIconOnly
                className=" border-gray-400 border-1 rounded-full bg-white"
                onClick={handleBackClick}
              >
                <IoArrowBack
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#FF9800",
                    fontWeight: "600",
                  }}
                />
              </Button>
              <div className="absolute left-[50%] translate-x-[-50%] top-4 flex flex-col items-center">
                <h3 className="font-semibold text-large">
                  {selectedDate?.split(" ")[0]}
                </h3>
                <p className="font-light text-[#747171] text-small">
                  {selectedDate?.split(" ")[2]} {selectedDate?.split(" ")[1]},
                  2024
                </p>
              </div>
            </div>
            <div className="w-full h-[1px] bg-[#E0CDCD]"></div>
            <div className="mt-3 w-full flex items-center justify-center flex-col">
              <h3 className="font-semibold text-large">Select Date & Time</h3>
              <p className="font-light text-[#747171] text-small">
                Duration : 60 min
              </p>
            </div>

            <TimeSlot
              date={selectedDate}
              isVisible={showTimeSlots}
              changeFormVisibility={(data: any) => {
                setShowForm(true);
                setFinalSelectedData(data);
              }}
              slotData={slotDataForMobile}
              formattedDate={formattedDate}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default ScheduleMeeting;
