import { Intercom } from '@intercom/messenger-js-sdk';

interface UpdatedUser {
  id: string;
  name: string;
  email: string;
}

export const updateIntercomUser = (userData: UpdatedUser) => {
  Intercom({
    app_id: 'mlmcipaa', // Your Intercom App ID
    action: 'update',
    user_id: userData.id,
    name: userData.name,
    email: userData.email,
  });
};