import {
  Button,
  getKeyValue,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
} from "@nextui-org/react";
import ToolTip from "../../../assets/Icons/ToolTip.svg";

import { BarChart2 } from "lucide-react";
import React from "react";
import All from "../../../assets/Icons/all.svg";
import ListingLogos from "./ListingLogos";
interface Vehicle {
  id: number;
  dealership: number;
  private_sale: number;
  potential_saving: number;
}

interface ListingDetails {
  id: number;
  agree_market_your_car: number;
  vehicle: Vehicle[];
  valueAnalyser?: {
    dealership: number;
    private_sale: number;
    potential_saving: number;
  }[];
}

interface DashboardListingTableProps {
  listingDetails: ListingDetails | null;
}

const DashboardListingTable: React.FC<DashboardListingTableProps> = ({
  listingDetails,
}) => {
  const rows = [
    {
      key: "1",
      name: "All Channels",
      Icon: All,
      views: "9151",
      clicks: "9151",
      saves: "9151",
      enquires: "9151",
    },
    {
      key: "2",
      name: "All Channels",
      Icon: All,
      views: "9151",
      clicks: "9151",
      saves: "9151",
      enquires: "9151",
    },
    {
      key: "3",
      name: "All Channels",
      Icon: All,
      views: "9151",
      clicks: "9151",
      saves: "9151",
      enquires: "9151",
    },

    {
      key: "4",
      name: "All Channels",
      Icon: All,
      views: "9151",
      clicks: "9151",
      saves: "9151",
      enquires: "9151",
    },
  ];

  const columns = [
    {
      key: "channel",
      label: "Channel",
      tooltipText: "Channel",
    },
    {
      key: "views",
      label: "Views",
      tooltipText: "Views",
    },
    {
      key: "clicks",
      label: "Clicks",
      tooltipText: "Clicks",
    },
    {
      key: "saves",
      label: "Saves",
      tooltipText: "Saves",
    },
    {
      key: "enquires",
      label: "Enquires",
      tooltipText: "Enquires",
    },
  ];

  const renderName = (name: any, Icon: any) => (
    <div className="flex gap-2 items-center">
      <img src={All} alt="" />
      <p>{name}</p>
    </div>
  );
  return (
    <div className="">
      <ListingLogos listingDetails={listingDetails} />
    </div>
  );
};

export default DashboardListingTable;
