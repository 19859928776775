import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  RangeCalendar,
  DateValue,
  RangeValue,
  Button,
} from "@nextui-org/react";
import { today } from "@internationalized/date";
import { Tooltip } from "react-tooltip"; // Correct import

interface DateRange {
  from: string;
  to: string;
}

interface DateRangeComponentProps {
  dateRanges: DateRange[];
  setDateRanges: React.Dispatch<React.SetStateAction<DateRange[]>>;
}

const DateRangeComponent: React.FC<DateRangeComponentProps> = ({
  dateRanges,
  setDateRanges,
}) => {
  const [showCalendarIndex, setShowCalendarIndex] = useState<number | null>(
    null
  );
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  // Handle resize for responsiveness
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true); // Mobile view for widths <= 768px
    } else {
      setIsMobile(false); // Desktop view for widths > 768px
    }
  };

  // Close calendar when clicking outside
  const handleClickOutside = (event: MouseEvent) => {
    if (
      calendarRef.current &&
      !calendarRef.current.contains(event.target as Node)
    ) {
      setShowCalendarIndex(null); // Close the calendar when clicking outside
    }
  };

  useEffect(() => {
    handleResize(); // Set the initial value
    window.addEventListener("resize", handleResize);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (range: RangeValue<DateValue>, index: number) => {
    if (range.start && range.end) {
      const from = range.start.toDate("UTC").toLocaleDateString("en-GB");
      const to = range.end.toDate("UTC").toLocaleDateString("en-GB");

      const updatedRanges = [...dateRanges];
      updatedRanges[index] = { from, to };
      setDateRanges(updatedRanges);

      setShowCalendarIndex(null); // Close the calendar after selection
    }
  };

  const handleToggleCalendar = (index: number) => {
    setShowCalendarIndex((prev) => (prev === index ? null : index));
  };

  const addDateRange = () => {
    setDateRanges([...dateRanges, { from: "", to: "" }]);
  };

  const removeDateRange = (index: number) => {
    if (dateRanges.length > 1) {
      const updatedRanges = dateRanges.filter((_, i) => i !== index);
      setDateRanges(updatedRanges);
    }
  };
  const currentDate = today("UTC");
  return (
    <div>
      {dateRanges.map((range, index) => (
        <div
          key={index}
          className="mb-4 flex flex-row  items-center text-[12px] lg:text-[16px] md:justify-between"
        >
          <label className="text-gray-700 font-semibold ">Date:</label>
          <div className="flex items-center w-full sm:w-[80%] ml-[1.5rem] sm:ml-[4rem] md:ml-[6.3rem] lg:ml-[10.6rem]">
            <input
              className="w-[79%] text-[12px] lg:text-[14px] p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent "
              value={
                range.from && range.to ? `${range.from} - ${range.to}` : ""
              }
              placeholder="Select start and end date"
              onClick={() => handleToggleCalendar(index)}
              readOnly
              data-tooltip-id={`date-range-tooltip-${index}`} // Unique ID for each tooltip
              data-tooltip-content="Select start and end dates" // Tooltip content
            />
            {showCalendarIndex === index && (
              <div
                className="absolute z-10 mt-2"
                ref={calendarRef}
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                }} // Conditionally change layout
              >
                <RangeCalendar
                  aria-label={`Date Range Calendar ${index + 1}`}
                  visibleMonths={isMobile ? 1 : 2} // Show 1 month on mobile, 2 on larger screens
                  minValue={currentDate} // Disable past dates
                  onChange={(range) => handleSelect(range, index)}
                   
                />
              </div>
            )}
          </div>
          <div className="flex items-center sm: justify-end  sm:w-auto sm:mt-0">
            <Button
              className="p-0 bg-orange-100 text-[#ff9800] hover:bg-[#ff9800] hover:text-white transition duration-300 mr-3"
              onPress={addDateRange} // Replace onClick with onPress for Next UI Button
              data-tooltip-id={`add-button-tooltip-${index}`} // Unique ID for each tooltip
              data-tooltip-content="Add more date ranges" // Tooltip content
            >
              Add Date
            </Button>

            <button
              onClick={() => removeDateRange(index)}
              className="ml-0 text-gray-400 hover:text-gray-500 focus:outline-none text-2xl items-center"
            >
              &times;
            </button>
            {/* <button
              className="h-[30px] w-[30px] rounded-[2px] relative text-2xl bg-orange-100 text-orange-600 hover:bg-orange-400 transition duration-300"
              onClick={() => removeDateRange(index)}
              disabled={dateRanges.length <= 1}
              data-tooltip-id={`remove-button-tooltip-${index}`} // Unique ID for each tooltip
              data-tooltip-content="Remove date range" // Tooltip content
            >
              <p className="absolute -top-[5px] left-[9px]">x</p>
            </button> */}
          </div>
          {/* Tooltip components for each date range */}
          <Tooltip id={`date-range-tooltip-${index}`} />
          <Tooltip id={`add-button-tooltip-${index}`} />
          <Tooltip id={`remove-button-tooltip-${index}`} />
        </div>
      ))}
    </div>
  );
};

export default DateRangeComponent;
