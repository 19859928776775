import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContent,
  Button,
  Spinner,
} from "@nextui-org/react";

interface User {
  id: number;
  name: string;
  email: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  phoneVerified: number;
  vehicle_onboarding: number;
  agree_market_your_car: number;
}

const UserViewModal = ({
  isOpen,
  onClose,
  userId,
}: {
  isOpen: boolean;
  onClose: () => void;
  userId: string | number;
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;

      setLoading(true);
      setError(null);

      try {
        const token = JSON.parse(
          localStorage.getItem("onewheel_user") || "{}"
        )?.token;
        if (!token) throw new Error("No token found");

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error("Failed to fetch user data");

        const userData = await response.json();
        setUser(userData.user);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchUser();
    }
  }, [isOpen, userId]);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalContent>
        <ModalHeader>
          <h2 className="text-xl font-bold">User Details</h2>
        </ModalHeader>
        <ModalBody>
          {loading && <Spinner label="Loading user data..." />}
          {error && <p className="text-red-500">Error: {error}</p>}
          {user && (
            <div className="space-y-4">
              <p>
                <strong>Name:</strong> {user.name}
              </p>
              <p>
                <strong>Email:</strong> {user.email}
              </p>
              <p>
                <strong>Phone:</strong> {user.phone_number}
              </p>
              <p>
                <strong>User Type:</strong> {user.type}
              </p>
              <p>
                <strong>Created At:</strong> {formatDate(user.created_at)}
              </p>
              <p>
                <strong>Phone Verified:</strong>{" "}
                {user.phoneVerified ? "Yes" : "No"}
              </p>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onPress={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserViewModal;
