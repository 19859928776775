import "./index.css";
import Footer from "./pages/Home/HomeComponents/Footer";
import Navigation from "./routes/routes";
import IntercomProvider from "./contexts/IntercomProvider";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Toaster } from "sonner";

function App() {
  return (
    <BrowserRouter>
      <MainContent />
    </BrowserRouter>
  );
}

const MainContent = () => {
  const location = useLocation(); // Get the current location
  const noFooterAndIntercomPath = "/schedule-meeting/"; // Define the path prefix to hide Footer and Intercom

  // Conditional rendering based on the route
  const shouldHideFooterAndIntercom = location.pathname.startsWith(noFooterAndIntercomPath);

  if (shouldHideFooterAndIntercom) {
    return (
      <>
        <Navigation />
        {/* Content for routes without Footer and Intercom */}
      </>
    );
  }

  return (
    <>
      <IntercomProvider>
        <Toaster richColors />
        <Navigation />
        <Footer />
      </IntercomProvider>
    </>
  );
};

export default App;
