import { Button } from "@nextui-org/react";
import React, { useState, useEffect } from "react";

const Banner = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Check if the screen size is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Set initial value
    handleResize();

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLearnMore = () => {
    window.open("https://1wheel.com.au/1wheel-terms-of-service/", "_blank", "noopener,noreferrer");
  };

  return (
    <div className="banner shadow-lg p-2">
      <div className="banner-text">
        IMPORTANT: It is against our 
        {isMobile ? (
          <a
            href="https://1wheel.com.au/1wheel-terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline ml-1"
          >
            Terms Of Service&nbsp;
          </a>
        ) : (
          <> Terms Of Service </>
        )}
        to advertise your cars elsewhere while we are Marketing your Car.
      </div>
      
      {!isMobile && (
        <div className="banner-button">
          <Button
            className="bg-[#ff9800] text-white"
            style={{ width: "100%" }}
            onClick={handleLearnMore}
          >
            Learn More
          </Button>
        </div>
      )}
    </div>
  );
};

export default Banner;
