import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Pagination,
  Selection,
  SortDescriptor,
  useDisclosure,
  ModalFooter,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Switch,
} from "@nextui-org/react";

import { SearchIcon } from "lucide-react";
import { ChevronDownIcon } from "../adminComponents/ChevronDownIcon";
import { capitalize } from "../adminComponents/utils";
import { PlusIcon } from "../adminComponents/PlusIcon";
import { useNavigate } from "react-router-dom";

import UserViewModal from "../../../modals/UserViewModal";

// interface Vehicle {
//   id: number;
//   contact_number: string;
//   user_id: number;
//   vehicle_name: string;
//   number_plate: string;
// }

// interface Listing {
//   id: number;
//   vehicle_id: number;
//   user_id: number;
//   user_name: string;
//   contact_number: Vehicle[];
//   price: string;
//   isListingLive: number;
//   created_at: string;
//   vehicle: Vehicle[];
//   documents: any[];
//   profileCompletionPercentage: string;
//   vehicle_name: string;
// }
interface Vehicle {
  id: number;
  vehicle_id: number;
  user_id: number;
  listing_title: string | null;
  description: string | null;
  price: string | null;
  isListingLive: number;
  created_at: string;
  updated_at: string;
  current_price_time: string;
  contact_number: string;
  ownership: string;
  driving_type: string;
  service_history: string;
  money_owed: string;
  damage: string;
  modifications: string;
  selling_reason: string;
  condition_rating: string;
  reserve_price: string;
  desired_outcome: string;
  both_keys: string;
  state: string;
  postcode_suburb: string;
  agree_to_terms: number;
  confirm_ownership: number;
  location: string;
  vehicle_name: string;
  number_plate: string;
  year: number;
  km: number;
  avg_sell_time: string;
  dealership: string;
  fees: string;
  potential_saving: string;
  private_sale: string;
  vin: string;
  user_name: string;
}

interface Document {
  document_type: string;
  document_url: string;
  document_name: string;
  status: string;
}

interface Listing {
  id: number;
  vehicle_id: number;
  user_id: number;
  listing_title: string | null;
  description: string | null;
  price: string | null;
  isListingLive: number;
  created_at: string;
  updated_at: string;
  current_price_time: string;
  contact_number: string;
  ownership: string;
  driving_type: string;
  service_history: string;
  money_owed: string;
  damage: string;
  modifications: string;
  selling_reason: string;
  condition_rating: string;
  reserve_price: string;
  desired_outcome: string;
  both_keys: string;
  state: string;
  postcode_suburb: string;
  agree_to_terms: number;
  confirm_ownership: number;
  location: string;
  vehicle_name: string;
  number_plate: string;
  year: number;
  km: number;
  avg_sell_time: string;
  dealership: string;
  fees: string;
  potential_saving: string;
  private_sale: string;
  vin: string;
  user_name: string;
  unique_link:string;
  vehicle: Vehicle;
  documents: Document[];
  images: any[];
  profileCompletionPercentage: string;
}


interface ApiResponse {
  listings: Listing[];
}

const columns = [
  { name: "Vehicle Name", uid: "vehicle_name" },
  { name: "Rego Number", uid: "rego_number" },
  { name: "User Name", uid: "user_name" },
  { name: "Availability Link", uid: "availabilityLink" },
  { name: "Phone Number", uid: "contact_number" },
  { name: "Price", uid: "price" },
  { name: "Listing Duration", uid: "listingDuration" },
  { name: "Completion", uid: "profileCompletionPercentage" },
  // { name: "Documents Uploaded", uid: "documentsUploaded" },
  { name: "Signed Up / Created At", uid: "created_at" },
  { name: "Actions", uid: "actions" },
];

const INITIAL_VISIBLE_COLUMNS = [
  "vehicle_name",
  "rego_number",
  "user_name",
  "availabilityLink",
  "contact_number",
  "price",
  "listingDuration",
  "profileCompletionPercentage",
  "documentsUploaded",
  "created_at",
  "actions",
];



export default function VehicleListingTable() {
  const [listings, setListings] = useState<Listing[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [filterValue, setFilterValue] = useState("");
  const [visibleColumns, setVisibleColumns] = useState<Selection>(
    new Set(INITIAL_VISIBLE_COLUMNS)
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "vehicle_name",
    direction: "ascending",
  });

  const [page, setPage] = useState(1);
  // Function to format the timestamp
  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })} ${date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  };

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const token = JSON.parse(
          localStorage.getItem("onewheel_user") || "{}"
        )?.token;

        if (!token) {
          throw new Error("No token found");
        }

        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/vehicle/alllistedVehicles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch listings");
        }
        const data: ApiResponse = await response.json();
        console.log("view all listing data", data);
        // Format the 'created_at' field and sort by 'created_at' in descending order
        const updatedItems = data.listings
          .map((item) => ({
            ...item,
            created_at: formatDate(item.created_at),
          }))
          .sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          );

        console.log("sorted data", updatedItems);
        setListings(updatedItems);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchListings();
  }, []);

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return columns;

    return columns.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  // const filteredItems = React.useMemo(() => {
  //   let filteredListings = [...listings];

  //   if (hasSearchFilter) {
  //     filteredListings = filteredListings.filter((listing) =>
  //       listing.vehicle[0].vehicle_name
  //         .toLowerCase()
  //         .includes(filterValue.toLowerCase())
  //     );
  //   }

  //   return filteredListings;
  // }, [listings, filterValue]);
  const filteredItems = React.useMemo(() => {
    let filteredListings = [...listings];
  
    if (hasSearchFilter) {
      filteredListings = filteredListings.filter((listing) => {
        const vehicleName = listing.vehicle?.vehicle_name?.toLowerCase() || "";
        const regoNumber = listing.vehicle?.number_plate?.toLowerCase() || "";
        const userName = listing.user_name?.toLowerCase() || "";
        const contactNumber = String(listing.contact_number || "");
        const price = listing.price?.toLowerCase() || "";
        const profileCompletion = listing.profileCompletionPercentage?.toLowerCase() || "";
        const createdAt = listing.created_at?.toLowerCase() || "";
  
        // Check if the filter value is present in any of these fields
        return (
          vehicleName.includes(filterValue.toLowerCase()) ||
          regoNumber.includes(filterValue.toLowerCase()) ||
          userName.includes(filterValue.toLowerCase()) ||
          contactNumber.includes(filterValue.toLowerCase()) ||
          price.includes(filterValue.toLowerCase()) ||
          profileCompletion.includes(filterValue.toLowerCase()) ||
          createdAt.includes(filterValue.toLowerCase())
        );
      });
    }
  
    return filteredListings;
  }, [listings, filterValue]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    console.log("Sorting items:", items);
    return [...items].sort((a: Listing, b: Listing) => {
      const first = new Date(
        a[sortDescriptor.column as keyof Listing] as string
      );
      const second = new Date(
        b[sortDescriptor.column as keyof Listing] as string
      );
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const calculateListingDuration = (createdAt: string) => {
    const now = new Date();
    const created = new Date(createdAt);
    const diffTime = Math.abs(now.getTime() - created.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return `${diffDays} day${diffDays !== 1 ? "s" : ""}`;
  };

  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [updatedDocuments, setUpdatedDocuments] = useState<Document[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { onOpen: onOpenUserModal, onClose: onCloseUserModal } =
    useDisclosure();

  const fetchDocuments = useCallback(async (userId: number) => {
    try {
      const token = JSON.parse(
        localStorage.getItem("onewheel_user") || "{}"
      )?.token;

      if (!token) {
        throw new Error("No token found");
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/fetchdocuments/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch documents");
      }

      const data = await response.json();
      setDocuments(data.documents);
    } catch (error) {
      console.error("Error fetching documents:", error);
      // You might want to show an error message to the user here
    }
  }, []);

  const handleVerifyDocuments = (userId: number) => {
    setSelectedUserId(userId);
    fetchDocuments(userId);
    onOpen();
  };

  const handleUpdateDocumentStatus = async (
    documentIndex: number,
    newStatus: string
  ) => {
    if (!selectedUserId) {
      console.error("No user selected");
      return;
    }

    const document = documents[documentIndex];

    try {
      const token = JSON.parse(
        localStorage.getItem("onewheel_user") || "{}"
      )?.token;

      if (!token) {
        throw new Error("No token found");
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/documentStatusUpdate`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: selectedUserId,
            documentName: document.document_name,
            status: newStatus,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update document status");
      }

      const result = await response.json();
      console.log("Document status updated:", result);

      // Update the local state to reflect the change
      setDocuments((prevDocuments) =>
        prevDocuments.map((doc, index) =>
          index === documentIndex ? { ...doc, status: newStatus } : doc
        )
      );

      // You might want to show a success message to the user here
    } catch (error) {
      console.error("Error updating document status:", error);
      // You might want to show an error message to the user here
    }
  };
  const handleEdit = (id: number) => {
    navigate(`/admin/vehicles/${id}`);
  };

  const handleGoLive = async (id: number) => {
    try {
      const token = JSON.parse(
        localStorage.getItem("onewheel_user") || "{}"
      )?.token;

      if (!token) {
        throw new Error("No token found");
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/makeListingLive/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to make listing live");
      }

      const result = await response.json();
      console.log(`Listing ${id} is now live:`, result);

      // Update the local state to reflect the change
      setListings((prevListings) =>
        prevListings.map((listing) =>
          listing.id === id ? { ...listing, isListingLive: 1 } : listing
        )
      );

      // You might want to show a success message to the user here
    } catch (error) {
      console.error("Error making listing live:", error);
      // You might want to show an error message to the user here
    }
  };

  const [openUserModal, setOpenUserModal] = useState<boolean>(false);
  const renderCell = React.useCallback(
    (listing: Listing, columnKey: React.Key) => {
      switch (columnKey) {
        case "vehicle_name":
          return (
            <div
              onClick={() => {
                navigate(`/admin/vehicles/${listing?.vehicle?.id}`);
              }}
            >
              {listing?.vehicle?.vehicle_name}
            </div>
          );
        case "user_id":
          return listing.user_id;
        case "rego_number":
          return listing?.vehicle?.number_plate
            ? listing?.vehicle?.number_plate.replace(/\s/g, "").toUpperCase()
            : "N/A";
        case "user_name":
          return (
            <div
              onClick={() => {
                console.log("Open user modal");

                setOpenUserModal(true);
                setOpenUserId(listing?.user_id);
              }}
            >
              {listing?.user_name}
            </div>
          );
          case "availabilityLink":
            const baseUrl = window.location.origin; // Replace with your actual base URL
            const fullLink = listing.unique_link ? `${baseUrl}/schedule-meeting/${listing?.unique_link}` : null;
          
            return (
              <div className="flex flex-col">
                {fullLink ? (
                  <a
                    href={fullLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-bold text-md items-center no-underline text-blue-500 underline"
                  >
                    View Availability
                  </a>
                ) : (
                  <p className="text-bold text-small">N/A</p>
                )}
              </div>
            );
        case "contact_number":
          return listing?.contact_number || "N/A";
        case "price":
          return `$${parseFloat(listing?.reserve_price ?? '0').toLocaleString()}`;

        case "listingDuration":
          return calculateListingDuration(listing?.created_at);
        case "profileCompletionPercentage":
          const profileCompletion = parseInt(
            listing?.profileCompletionPercentage ?? "0",
            10
          );
          return `${Math.abs(profileCompletion)} %`;
        // case "documentsUploaded":
        //   return listing.documents.length;
        case "actions":
          return (
            <div className="relative flex justify-end items-center gap-2">
              <Button
                size="sm"
                variant="flat"
                color="primary"
                onClick={() => handleEdit(listing?.vehicle?.id)}
              >
                Verify Listing
              </Button>
              {/* <Button
                size="sm"
                variant="flat"
                color="success"
                onClick={() => handleGoLive(listing.id)}
                disabled={listing.isListingLive === 1}
              >
                {listing.isListingLive === 1 ? "Live" : "Go Live"}
              </Button> */}
              <div
                style={{
                  transform: "scale(0.85)",
                }}
              >
                <Switch
                  size="sm"
                  isSelected={listing.isListingLive === 1}
                  onValueChange={(isSelected) => handleGoLive(listing.id)}
                >
                  {listing.isListingLive === 1 ? "Live" : "Offline"}
                </Switch>
              </div>
              <Button
                size="sm"
                variant="flat"
                color="secondary"
                onClick={() => handleVerifyDocuments(listing.user_id)}
              >
                Verify Documents
              </Button>
            </div>
          );
        default:
          return `${listing[columnKey as keyof Listing]}`;
      }
    },
    [handleGoLive, handleEdit, handleVerifyDocuments]
  );

  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onRowsPerPageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
    },
    []
  );
  const [openUserId, setOpenUserId] = useState<number | string>("");

  const onSearchChange = React.useCallback((value?: string) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);
  console.log(openUserModal, "openUserModal");

  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            className="w-full sm:max-w-[44%]"
            placeholder="Search..."
            startContent={<SearchIcon />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          />
          <div className="flex gap-3">
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button
                  endContent={<ChevronDownIcon className="text-small" />}
                  variant="flat"
                >
                  Columns
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={visibleColumns}
                selectionMode="multiple"
                onSelectionChange={setVisibleColumns}
              >
                {columns.map((column) => (
                  <DropdownItem key={column.uid} className="capitalize">
                    {capitalize(column.name)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Button color="primary" endContent={<PlusIcon />}>
              Add New
            </Button>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-default-400 text-small">
            Total listings: {listings.length}
          </span>
          <label className="flex items-center text-default-400 text-small">
            Rows per page:
            <select
              className="bg-transparent outline-none text-default-400 text-small"
              onChange={onRowsPerPageChange}
            >
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [
    filterValue,
    visibleColumns,
    onSearchChange,
    onRowsPerPageChange,
    listings.length,
    hasSearchFilter,
  ]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-between items-center">
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={pages}
          onChange={setPage}
        />
        <div className="hidden sm:flex w-[30%] justify-end gap-2">
          <Button
            isDisabled={pages === 1}
            size="sm"
            variant="flat"
            onPress={onPreviousPage}
          >
            Previous
          </Button>
          <Button
            isDisabled={pages === 1}
            size="sm"
            variant="flat"
            onPress={onNextPage}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }, [page, pages]);

  const navigate = useNavigate();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Table
        isStriped
        aria-label="Vehicle Listing Table"
        isHeaderSticky
        bottomContent={bottomContent}
        bottomContentPlacement="outside"
        classNames={{
          wrapper: "max-h-[582px] max-w-[75vw]",
        }}
        sortDescriptor={sortDescriptor}
        topContent={topContent}
        topContentPlacement="outside"
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={headerColumns}>
          {(column) => (
            <TableColumn
              key={column.uid}
              align={column.uid === "actions" ? "center" : "start"}
              allowsSorting={column.uid !== "actions"}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody emptyContent={"No vehicles found"} items={sortedItems}>
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <UserViewModal
        isOpen={openUserModal}
        onClose={() => setOpenUserModal(false)}
        userId={openUserId}
      />
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalHeader>Verify Documents</ModalHeader>
          <ModalBody>
            <Table aria-label="Document verification table">
              <TableHeader>
                <TableColumn>Document Name</TableColumn>
                <TableColumn>View</TableColumn>
                <TableColumn>Status</TableColumn>
              </TableHeader>
              <TableBody>
                {documents.map((doc, index) => (
                  <TableRow key={index}>
                    <TableCell>{doc.document_name}</TableCell>
                    <TableCell>
                      <Button
                        size="sm"
                        as="a"
                        href={doc.document_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell>
                      <select
                        value={doc.status}
                        onChange={(e) =>
                          handleUpdateDocumentStatus(index, e.target.value)
                        }
                        className="bg-transparent border rounded p-1"
                      >
                        <option value="Verifying">Verifying</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                      </select>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onPress={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
