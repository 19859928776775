import React from "react";
import verifiedCheckedIcon from "../../../assets/Icons/Linear/Money/Verified Check.png";
import { FaCarAlt } from "react-icons/fa";
import mapPointIcon from "../../../assets/Images/Map Point Wave.png";
import calendar from "../../../assets/Images/Calendar Date.png";
import { Button } from "@nextui-org/react";

interface ModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  sellerName: string;
  vehicleName: string;
  vehicleLocation: string;
  finalSelectedData: any;
  location:any;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  toggleModal,
  sellerName,
  vehicleName,
  vehicleLocation,
  finalSelectedData,
  location,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded px-4 pt-1 pb-4 lg:px-16 lg:py-10">
        <div className="flex justify-center py-2 font-bold text-[18px] md:text-2xl">
          <img
            src={verifiedCheckedIcon}
            className="w-16 h-16"
            alt="verify icon"
          />
        </div>
        <div className="flex justify-center  px-1 lg:px-8 font-bold text-[16px] md:text-2xl">
          <p> Car Showcase Meet with {sellerName}</p>
        </div>
        <div className="flex justify-center md:mb-3 p-2 md:p-2 text-[12px] md:text-sm">
          <p>A invitation has been sent to your email address.</p>
        </div>

        <div className="border-1 border-[#B0B7C3a] lg:px-10 lg:pt-4 L:pb-6 px-4 py-4 rounded  ">
          <div className="lg:text-[18px] font-bold m-1 flex justify-center  text-[14px] md:text-xl items-center">
            Your appointment has been scheduled.
          </div>
          <div className="flex gap-3 m-2  items-center">
            <FaCarAlt className="w-6 h-6 lg:w-6 lg:h-6 text-[#b6b6b6]" />
            <p className="text-[12px] lg:text-[16px]">{vehicleName}</p>
          </div>
          <div className="flex gap-3 m-2 items-center">
            <img src={calendar} className="w-6 h-6" alt="calendar" />

            <p className="text-[12px] lg:text-[16px]">
              {finalSelectedData?.start_time} - {finalSelectedData?.end_time}, {finalSelectedData?.day_of_week}
            </p>
          </div>
          <div className="flex gap-3 m-2  items-center">
            <img src={mapPointIcon} className="w-6 h-6" alt="map" />

            <p className="text-[12px] lg:text-[16px]"> {location} </p>
          </div>
        </div>
        <Button className="w-full mt-3 bg-[#ff9800] text-white"
        onClick={() => {
            window.open("https://preowned.1wheel.com.au/", "_blank");
        }}
        >View Other Cars!</Button>
      </div>
    </div>
  );
};

export default Modal;
