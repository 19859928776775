interface FormData {
    numberPlate: string;
    km: string;
    location: string;
    ownership: string;
    damage: string;
    postcode_suburb: string;
    drivingType: string;
    serviceHistory: string;
    keys: string;
    moneyOwed: string;
    modifications: string;
    sellingReason: string;
    conditionRating: string;
    reservePrice: string;
    agreeToTerms: boolean;
    confirmOwnership: boolean;
    desiredOutcome: string;
    agreeToPay:boolean;
  }
  
  export const validateForm = (page: number, formData: FormData, setValidationErrors: any): boolean => {
    const errors: any = {};
    let isValid = true;
  
    // Validate based on the current page
    if (page === 0) {
      console.log(formData.location, "location");
      
      if (!formData.numberPlate) {
        errors.numberPlate = "Number plate is required.";
      }
      if (!formData.km) {
        errors.km = "Km is required.";
      }
      // check whether km is a number
      if (isNaN(Number(formData.km))) {
        errors.km = "Km should be a number.";
      }
      if (!formData.location || formData.location === "") {
        errors.location = "Location is required.";
      }
    } else if (page === 1) {
      // BasicDetails
      if (!formData.ownership) {
        errors.ownership = "Ownership is required.";
        isValid = false;
      }
      if (!formData.postcode_suburb) {
        errors.postcode_suburb = "Postcode suburb is required.";
        isValid = false;
      }
      if (!formData.drivingType) {
        errors.drivingType = "Driving type information is required.";
        isValid = false;
      }
      if (!formData.serviceHistory) {
        errors.serviceHistory = "Service history is required.";
        isValid = false;
      }
      if(!formData.keys){
        errors.keys = "Keys information is required.";
        isValid = false;
      }
      if(!formData.moneyOwed){
        errors.moneyOwed = "Money owed information is required.";
        isValid = false;
      }
      // Add any other mandatory fields here
    } else if (page === 2) {
      // TellUsMore
      if(!formData.damage){
        errors.damage = "Damage information is required.";
        isValid = false;
      }
    
      if (!formData.modifications) {
        errors.modifications = "Modifications details are required.";
        isValid = false;
      }
      if (!formData.sellingReason) {
        errors.sellingReason = "Selling reason is required.";
        isValid = false;
      }
      if (!formData.conditionRating) {
        errors.conditionRating = "Condition rating is required.";
        isValid = false;
      }
      if (!formData.reservePrice) {
        errors.reservePrice = "Reserve price is required.";
        isValid = false;
      }
      if (!formData.desiredOutcome) {
        errors.desiredOutcome = "Desired outcome is required.";
        isValid = false;
      }
      // Add validation for the new checkbox fields
      if (!formData.agreeToTerms) {
        errors.agreeToTerms = "You must agree to the Terms of Service.";
        isValid = false;
      }
      if (!formData.confirmOwnership) {
        errors.confirmOwnership = "You must confirm ownership of the car.";
        isValid = false;
      }
      if (!formData.agreeToPay) {
        errors.agreeToPay = "You must agree to the Terms of Service.";
        isValid = false;
      }
    }
  
    setValidationErrors(errors);
    return isValid;
  };

// export const validateForm = (page: number, formData: any, setValidationErrors: (errors: any) => void) => {
//     const errors: any = {};
  
//     // Example validation logic based on the current page
//     if (page === 0 && !formData.location) {
//       errors.location = "Location is required";
//     }
//     if (page === 1 && !formData.vehicleName) {
//       errors.vehicleName = "Vehicle Name is required";
//     }
//     if (page === 2 && !formData.numberPlate) {
//       errors.numberPlate = "Number Plate is required";
//     }
  
//     setValidationErrors(errors);
//     return Object.keys(errors).length === 0;
//   };
  