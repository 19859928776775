import axios from "axios";
import { saveToLocalStorage } from "../../utils/localStorageUtil.ts";
import apiCall from "../apiCall";

interface LoginResponse {
  token: string;
  user: {
    id: number;
    email: string;
    name: string;
    // Add other user properties as needed
  };
}

interface SignupResponse {
  token: string;
  user: {
    id: number;
    email: string;
  };
}

export async function login(
  email: string,
  password: string
): Promise<LoginResponse> {
  const response = await apiCall<LoginResponse>("/auth/signin", "POST", {
    email,
    password,
  });
  saveToLocalStorage("onewheel_user", JSON.stringify(response));
  return response;
}

export async function signup(
  name: string,
  email: string,
  password: string
): Promise<SignupResponse> {
  const response = await apiCall<SignupResponse>("/auth/signup", "POST", {
    name,
    email,
    password,
  });
  return response;
}

export const requestOTP = async (
  phoneNumber: string,
  countryCode: string,
  name?: string,
  email?: any
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/auth/signup`,
    { phoneNumber, countryCode, name, email }
  );
  return response.data;
};

export const requestOTPSignin = async (
  phoneNumber: string,
  countryCode: string,
  name?: string,
  email?: any
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/auth/signin`,
    { phoneNumber, countryCode, name, email }
  );
  return response.data;
};

export const verifyOTP = async (phoneNumber: string, otpCode: string,navigate:any

) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/auth/verifyotp`,
    { phoneNumber, otpCode }
  );
  if(response.data.user.isAdmin){
    navigate("/admin/users");
  }
  return response.data;
};
