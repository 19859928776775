import React, { useEffect, useState } from "react";
import "./styles.css";
import { Button, Calendar, Tooltip } from "@nextui-org/react";
import ToolTip from "../../assets/Icons/ToolTip.svg";
import type { DateValue } from "@react-types/calendar";
import DocumentUpload from "./HomeComponents/DocumentUpload";
import ProfileComplete from "./HomeComponents/ProfileComplete";
import BottomBar from "./HomeComponents/BottomBar";
import { today, getLocalTimeZone } from "@internationalized/date";
import Navbar from "../../components/Navbar/Navbar";
import Banner from "../../components/Navbar/Banner";
import CarImage3 from "./HomeComponents/CarImage3";
import ScheduledEvent from "./HomeComponents/ScheduleEvent";
import DashboardListingTable from "./HomeComponents/DashboardListingTable";
import ShowCalendar from "./HomeComponents/ShowCalender";
import axios from "axios";
interface ListingDetails {
  id: number;
  agree_market_your_car: number;
  vehicle: {
    id: number;
    dealership: number;
    private_sale: number;
    potential_saving: number;
  }[];
  valueAnalyser?: {
    dealership: number;
    private_sale: number;
    potential_saving: number;
  }[]; // Assuming it's an array of objects similar to the vehicle
  // Other properties...
}

const Home = () => {
  const [listingDetails, setListingDetails] =
    React.useState<ListingDetails | null>(null);
  const [profilecompleteUpload15, setProfilecompleteUpload15] =
    React.useState(false);
  const [vehicleRegistrationOpen, setVehicleRegistrationOpen] =
    React.useState(false);
  const [scheduledEventsData, setScheduledEventsData] = React.useState([]);
  const [drivingLicenseOpen, setDrivingLicenseOpen] = React.useState(false);
  const [bankStatementOpen, setBankStatementOpen] = React.useState(false);
  let defaultDate = today(getLocalTimeZone());
  let [focusedDate, setFocusedDate] = React.useState<DateValue>(defaultDate);
  const [availableDates, setAvailableDates] = useState([]);

  const [loading, setLoading] = React.useState(true);
  const [error] = React.useState<string | null>(null);

  const carImageRef = React.useRef(null);
  const docsRef = React.useRef(null);
  const calendarRef = React.useRef(null);
  const scheduleRef = React.useRef(null);
  // State to track screen width
  const [isMobileView, setIsMobileView] = useState(false);

  // Function to handle screen resize
  const handleResize = () => {
    setIsMobileView(window.innerWidth < 750); // Check if screen width is less than 750px
  };

  useEffect(() => {
    // Add event listener on mount
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the initial state
    handleResize();

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchListingDetails = async () => {
    const storedUser = JSON.parse(
      localStorage.getItem("onewheel_user") || "{}"
    );
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/vehicle/fetchlisting/${storedUser.user.id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch listing details");
      }
      const data = await response.json();
      getUserAvailability(data?.[0]?.unique_link);
      console.log("listing data", data?.[0]);
      setListingDetails(data[0]);
    } catch (err) {
      console.log(err);

      // setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const getUserAvailability = async (uniqueId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/availability/${uniqueId}`
      );
      const { data } = response;

      if (response.status === 200) {
        console.log("Data retrieved successfully:", data?.data);

        // Get all time slots from each availability
        const allTimeSlots = data?.data?.availability?.flatMap(
          (avail: any) => avail.time_slots
        );

        // Extract available dates from all time slots
        const availableDates = allTimeSlots.map((item: any) => item.date);
        console.log("Available dates:", availableDates);
        setAvailableDates(availableDates); // Set available dates from all time slots
      }
    } catch (error: any) {
      if (error.response) {
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        console.error("Error request:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const fetchScehduledEventsData = async () => {
    try {
      const storedUser = JSON.parse(
        localStorage.getItem("onewheel_user") || "{}"
      );
      const user_id = storedUser.user.id;

      // use axios to make the call to the API with body as user_id
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/availability/getScheduledEvents/${user_id}`
      );

      // Axios automatically handles non-2xx status codes as errors, so you can omit the check
      const data = response.data;
      console.log("data", data);
      setScheduledEventsData(data?.data);
    } catch (err) {
      console.error("Error fetching scheduled events:", err);
    }
  };

  React.useEffect(() => {
    fetchListingDetails();
    fetchScehduledEventsData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const scrollToSection = (sectionRef: any) => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  console.log(listingDetails);
  const formatNumberWithCommas = (number: number | string): string => {
    return new Intl.NumberFormat("en-IN").format(Number(number));
  };

  return (
    <div style={{ fontFamily: '"Manrope", sans-serif' }}>
      <Navbar />
      {/* {listingDetails?.agree_market_your_car === 0 && <Banner />} */}
      <Banner />
      <div
        className="seller-dashboard"
        style={{
          paddingTop:
            listingDetails?.agree_market_your_car === 0 ? "155px" : "155px",
        }}
      >
        <div className="seller-dashboard-top" ref={carImageRef}>
          <CarImage3
            listingDetails={listingDetails}
            setListingDetails={setListingDetails}
            profilecompleteUpload15={profilecompleteUpload15}
          />
          <div className="seller-dashboard-top-right-cards">
            <ProfileComplete
              listingDetails={listingDetails}
              setProfilecompleteUpload15={setProfilecompleteUpload15}
              setVehicleRegistrationOpen={setVehicleRegistrationOpen}
              setDrivingLicenseOpen={setDrivingLicenseOpen}
              setBankStatementOpen={setBankStatementOpen}
            />
            <div className="seller-value-my-car seller-card-p0">
              <div className="card-header flex w-full justify-between items-center">
                <h2> My Car Value</h2>
              </div>
              <div className="value-range-container">
                <div className="value-range">
                  <div className="value-range-left-value">
                    <p>
                      Dealership
                      {!isMobileView && ( // Render tooltip only if not in mobile view
                        <Tooltip
                          content={
                            <div className="px-1 py-1 w-52 flex flex-wrap">
                              <div className="text-tiny">
                                The amount you can expect to be offered for your
                                car by a dealer based on the last 90 days of
                                sales data.
                              </div>
                            </div>
                          }
                        >
                          <img src={ToolTip} alt="Tooltip" />
                        </Tooltip>
                      )}
                    </p>
                    <h2>
                      $
                      {listingDetails?.valueAnalyser?.[0]?.dealership
                        ? formatNumberWithCommas(
                            listingDetails.valueAnalyser[0].dealership
                          )
                        : "No dealership available"}
                    </h2>
                  </div>
                  <div className="value-range-right-value">
                    <p>
                      Private Sale
                      {/* <img src={ToolTip} alt="Tooltip" /> */}
                      {!isMobileView && ( // Render tooltip only if not in mobile view
                        <Tooltip
                          content={
                            <div className="px-1 py-1 w-56 flex flex-wrap">
                              <div className="text-tiny">
                                A guide as to what you could expect to get for
                                your car based on the last 90 days of sales
                                data.
                              </div>
                            </div>
                          }
                        >
                          <img src={ToolTip} alt="Tooltip" />
                        </Tooltip>
                      )}
                    </p>
                    <h2>
                      $
                      {listingDetails?.valueAnalyser?.[0]?.private_sale
                        ? formatNumberWithCommas(
                            listingDetails.valueAnalyser[0].private_sale
                          )
                        : "No private sale available"}
                    </h2>
                  </div>
                  <div className="value-range-left"></div>
                  <div className="value-range-right"></div>
                </div>
                <div className="value-gap">
                  <div className="value-gap-arrow"></div>

                  <div className="value-gap-value">
                    <h2>
                      $
                      {listingDetails?.valueAnalyser?.[0]?.potential_saving
                        ? formatNumberWithCommas(
                            listingDetails.valueAnalyser[0].potential_saving
                          )
                        : "No savings available"}
                    </h2>
                    <p>Potential Saving</p>
                  </div>
                  <div className="value-gap-arrow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="seller-dashboard-bottom">
          <DashboardListingTable listingDetails={listingDetails} />
          <div className="seller-dashboard-top-right-cards">
            <div
              ref={docsRef}
              className="seller-dashboard-top-right-cards-bottom seller-card-p0 gap"
            >
              <div className="card-header flex w-full justify-between items-center">
                <h2>Documents</h2>
                {/* <Button variant="bordered" size="sm">
                  View All
                </Button> */}
              </div>
              <DocumentUpload />
            </div>
            <div className="seller-dashboard-bottom-right">
              {/* <div className="seller-dashboard-bottom-right-card seller-card-p0">
                <div ref={calendarRef} className="calender">
                  <Calendar
                    focusedValue={focusedDate}
                    className="calender-listing shadow-none items-center text-center"
                    color="warning"
                    value={defaultDate}
                    onFocusChange={setFocusedDate}
                    // calendarWidth="500px"

                    showHelper
                    showShadow={false}
                  />
                </div>
                <div className="calender-button">
                  <Button
                    className="bg-[#ff9800] text-white"
                    style={{ width: "100%" }}
                  >
                    Schedule an Viewing
                  </Button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="relative flex flex-col md:flex-row w-full gap-4 ">
          <div className="bg-white rounded-lg  w-full md:w-[65%]">
            <ScheduledEvent scheduledEventsData={scheduledEventsData} />
          </div>

          <div className="bg-white rounded-lg w-full md:w-[35%]">
            <ShowCalendar 
            availableDates={availableDates}
            />
            {/* <div ref={calendarRef} className="calender">
              <Calendar
                focusedValue={focusedDate}
                className="calender-listing shadow-none items-center text-center"
                color="warning"
                value={defaultDate}
                onFocusChange={setFocusedDate}
                // calendarWidth="500px"

                showHelper
                showShadow={false}
              />
            </div> */}
            {/* <div className="calender-button">
              <Button
                className="bg-[#ff9800] text-white"
                style={{ width: "100%" }}
              >
                Schedule an Viewing
              </Button>
            </div> */}
          </div>
          <div></div>
          {/* overlay design */}
          {/* <div className="absolute left-0 top-0 right-0 bottom-0 flex justify-center items-center bg-gradient-to-br from-white/70 to-white/70  overflow-hidden">
            <span className="bg-[#FF9800] h-28 w-28 md:h-52 md:w-52  rounded-br-[40px] md:rounded-br-[70px]  absolute top-0 left-0 overflow-hidden">
              <span className=" block shadow-xl relative top-12 -left-[175px] md:top-12 md:-left-[80px] w-96 h-10 -rotate-45"></span>
            </span>

            <h1 className="text-[#FF9800]  text-5xl md:text-8xl font-bold">
              Coming soon
            </h1>
          </div> */}
        </div>
      </div>
      <BottomBar
        scrollToSection={(ref: any) => {
          switch (ref) {
            case "carImage":
              scrollToSection(carImageRef);
              break;
            case "docs":
              scrollToSection(docsRef);
              break;
            case "calendar":
              scrollToSection(calendarRef);
              break;
            case "schedule":
              scrollToSection(scheduleRef);
              break;
          }
        }}
      />
    </div>
  );
};

export default Home;
