import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import Home from "../pages/Home";
import { useNavigate } from "react-router-dom";
import Signup from "../pages/Auth/Signup";
import { useLocation } from "react-router-dom";
// import VehicleOnboarding from "../pages/Vehicle/VehicleOnboarding";
// import Users from "../pages/Admin/Users";
import VehicleOnboarding2 from "../pages/Vehicle/VehicleOnboarding2";
import Demo from "../pages/Demo";
import Admin from "../pages/Admin/Admin";
import AdminVehicles from "../pages/Admin/AdminVehicles";
import AdminSingleVehicle from "../pages/Admin/AdminSingleVehicle";
import AuthAdmin from "../pages/Auth/AuthAdmin";
import Home from "../pages/Home";
import AdminListings from "../pages/Admin/AdminListings";
import AdminSingleUser from "../pages/Admin/AdminSingleUser";
import ScheduleMeeting from "../pages/ScheduleMeeting";

const routes = [
  {
    path: "/demo",
    exact: true,
    element: <Demo />,
    private: true,
  },
  {
    path: "/",
    exact: true,
    element: <Home />,
    private: true,
  },
  {
    path: "/admin/users",
    exact: true,
    element: <Admin />,
    private: true,
    isAdmin: true,
  },
  {
    path: "/admin/vehicles",
    exact: true,
    element: <AdminVehicles />,
    private: true,
    isAdmin: true,
  },
  {
    path: "/admin/listings",
    exact: true,
    element: <AdminListings />,
    private: true,
    isAdmin: true,
  },
  {
    path: "/admin/vehicles/:id",
    exact: true,
    element: <AdminSingleVehicle />,
    private: true,
    isAdmin: true,
  },
  // {
  //   path: "/admin/user/:id",
  //   exact: true,
  //   element: <AdminSingleUser />,
  //   private: true,
  //   isAdmin: true,
  // },
  {
    path: "/auth",
    exact: true,
    element: <Signup />,
    private: false,
  },
  {
    path: "/auth/admin",
    exact: true,
    element: <AuthAdmin />,
    private: false,
  },
  {
    path: "/vehicle-onboarding",
    exact: true,
    element: <VehicleOnboarding2 />,
    private: false,
  },
  {
    path: "/schedule-meeting/:uniqueId",
    exact: true,
    element: <ScheduleMeeting />,
    private: false,
  },
];

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = localStorage.getItem("onewheel_user");
  // console.log(isAuthenticated, "isAuthenticated");
  const user = isAuthenticated ? JSON.parse(isAuthenticated) : null;

  useEffect(() => {
    if (user) {
      if (user.user.isAdmin === true) {
        if (location.pathname.startsWith("/admin/")) {
        } else if (location.pathname.startsWith("/auth")) {
          navigate("/admin/users");
        }
      }
      if (
        location.pathname.startsWith("/admin/") &&
        user.user.isAdmin === false
      ) {
        navigate("/vehicle-onboarding");
      }
      if (location.pathname.startsWith("/auth")) {
        navigate("/vehicle-onboarding");
      }
      // if (location.pathname === "/" && user.user.isAdmin === true) {
      //   navigate("/admin/users");
      // }
      else if (location.pathname === "/cd " && user.user.isAdmin === false) {
        navigate("/vehicle-onboarding");
      }
    } else {
      if (location.pathname.startsWith("/vehicle-onboarding")) {
      } else if (location.pathname.startsWith("/auth")) {
      } else {
        // User is not authenticated
        if (
          !location.pathname.startsWith("/auth") &&
          !location.pathname.startsWith("/schedule-meeting")
        ) {
          navigate("/auth"); // Redirect to auth if trying to access a private route
        }
      }
    }
  }, [user, navigate, location.pathname]);
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            route.private && !isAuthenticated ? (
              <Navigate to="/auth" />
            ) : (
              route.element
            )
          }
        />
      ))}
    </Routes>
  );
};

export default Navigation;
